import { cloneElement, createElement, isValidElement } from 'react';
import styled, { css } from 'styled-components';
import { isArray } from 'lodash-es';
import cn from 'classnames';

import { Button } from '../Button';
import { Typography } from '../Typography';
import { Avatar } from '../Avatar';
import { Menu } from '../Menu';

import { em, rem } from 'styles';

const headerHeight = 56;

export const ViewBody = styled.div`
  ${({ theme }) => css`
    flex: 1;
    max-height: ${`calc(100vh - ${rem(headerHeight + 2)})`};
    display: flex;
    flex-direction: column;
    padding: ${rem([theme.spacing.ml])};
  `}
`;

export const ViewHeaderMenu = styled(({ children, ...props }) => {
  if (isValidElement(children)) {
    const className = cn(children.props.className, props.className);
    return cloneElement(children, { ...props, className });
  }

  if (isArray(children)) {
    return createElement(Menu, { ...props, items: children });
  }

  return createElement(Menu, { ...props, children });
})`
  ${({ theme }) => css`
    color: ${theme.colors.primary.hue};
    margin-left: auto;
  `}
`;

export const ViewActions = styled(({ children, ...props }) => {
  const className = cn(children.props.className, props.className);
  return cloneElement(children, { ...props, className });
})`
  ${({ theme }) => css`
    margin-left: auto;
    margin-right: ${rem(theme.spacing.sx)};

    + ${ViewHeaderMenu} {
      margin-left: initial;
    }
  `}
`;

export const ViewHeaderProfileAvatar = styled(Avatar)`
  margin-right: ${em(10)};
`;

export const ViewHeaderProfileHeading = styled(Typography).attrs({ element: 'h2', size: 'sx' })``;

export const ViewHeaderProfile = styled.div`
  ${({ onClick }) => [
    css`
      display: flex;
      align-items: center;
    `,
    onClick && 'cursor: pointer;'
  ]}
`;

export const ViewHeaderAction = styled(Button)``;

export const ViewHeaderActionBack = styled(ViewHeaderAction)`
  ${({ theme }) => css`
    color: ${theme.colors.primary.hue};
    margin-left: -${rem(theme.spacing.x)};
    margin-right: ${rem(theme.spacing.sx)};
  `}
`;

export const ViewHeader = styled.header`
  ${({ theme }) => css`
    flex: 0 0 ${rem(headerHeight)};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: ${theme.borders.default()};
    background: ${theme.colors.light.hue};
    padding: ${rem([theme.spacing.sx, theme.spacing.ml])};
  `}
`;

export const StyledView = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: 100vh;
    background: ${theme.colors.view.background};
    /* overflow-x: hidden; */
    /* overflow-y: auto; */
  `}
`;
