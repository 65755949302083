import styled, { css } from 'styled-components';

import { Overlay } from 'components/_shared/Overlay';
import { Button } from 'components/_shared/Button';
import { Typography } from 'components/_shared/Typography';

import { shouldForwardProp } from 'utils';
import { eventsStyle, rem } from 'styles';

export const FileViewerBody = styled.div`
  flex: 1;
  border-radius: inherit;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const FileViewerHeaderHeading = styled(Typography).attrs({ element: 'h4', size: 'sx' })``;

export const FileViewerHeader = styled.header`
  ${({ theme }) => css`
    flex-shrink: 0;
    display: flex;
    align-items: center;

    + ${FileViewerBody} {
      margin-top: ${rem(theme.spacing.ml)};
    }
  `}
`;

export const FileViewerToggle = styled(Button).attrs({ iconSize: 'm' })`
  ${({ theme }) => css`
    position: absolute;
    top: ${rem(theme.spacing.sm)};
    right: ${rem(theme.spacing.sm)};
    color: ${theme.colors.light.hue};
    z-index: ${theme.zIndexes.sx + 2};

    ${eventsStyle({ variant: 'secondary' })};
  `}
`;

export const FileViewerContainer = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: ${`calc(100% - ${rem(theme.spacing.xl)})`};
    max-height: ${`calc(100% - ${rem(theme.spacing.xl)})`};
    border-radius: ${rem(theme.radius.xs)};
    background: ${theme.colors.light.hue};
    box-shadow: ${theme.shadows.default()};
    padding: ${rem(theme.spacing.ml)};
    z-index: ${theme.zIndexes.sx};
  `}
`;

export const FileViewerOverlay = styled(Overlay)``;

const fileViewerFileTypeStyles = {
  image: css`
    ${FileViewerContainer} {
      padding: 0;
    }
  `,
  application: css`
    ${FileViewerContainer} {
      padding: 0;
      overflow: hidden;
    }
  `
};

export const StyledFileViewer = styled.div.withConfig({ shouldForwardProp })`
  ${({ theme, fileType }) => [
    css`
      position: fixed;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      z-index: ${theme.zIndexes.xs + 1};
    `,
    fileViewerFileTypeStyles[fileType]
  ]}
`;
