import { isArray, pick } from 'lodash-es';

import { CHAT_KEYS_MAP, CHAT_TEMPLATE } from '_constants';

import { mergeObjects, normalizeKeysByMap, withMergeObjects } from './helpers';
import { normalizeMessage } from './message';

export const createChat = (chat = {}) => {
  return pick(mergeObjects(CHAT_TEMPLATE, chat), Object.keys(CHAT_TEMPLATE));
};

export const normalizeChat = (chat = {}) => {
  const [getChat, setChat] = withMergeObjects();
  setChat(normalizeKeysByMap(chat, CHAT_KEYS_MAP));
  setChat(createChat(getChat()), { hard: true });

  setChat({
    messages: getChat().messages.reduce((messages, message) => {
      message.chatId = getChat().id;
      const normalizedMessage = normalizeMessage(message);
      return { ...messages, [normalizedMessage.id]: normalizedMessage };
    }, {})
  });

  const normalizedChat = getChat();
  if (isArray(normalizedChat.messages) && !normalizedChat.messages?.length) {
    normalizedChat.messages = {};
  }

  return normalizedChat;
};
