import styled, { css } from 'styled-components';
import { rgba } from 'polished';

import { Button } from 'components/_shared/Button';
import { Icon } from 'components/_shared/Icon';
import { Image } from 'components/_shared/Image';
import { Typography } from 'components/_shared/Typography';

import { em } from 'styles';

export const InputImageUploaderError = styled(Typography).attrs({ element: 'p', size: 'xs' })`
  ${({ theme, fontSize }) => css`
    font-weight: ${theme.fontWeights.medium};
    color: ${theme.colors.danger.hue};
    margin-bottom: ${em(theme.spacing.sx, theme.fontSizes[fontSize])};
  `}
`;

export const InputImageUploaderInput = styled.input`
  opacity: 0;
  visibility: hidden;
`;

export const InputImageUploaderActionRemove = styled(Button).attrs({ size: 'xs' })`
  ${({ theme, size }) => {
    const fontSize = theme.fontSizes[size];

    return css`
      font-weight: ${theme.fontWeights.medium};
      color: ${theme.colors.primary.hue};
      width: 100%;
      text-align: center;
      justify-content: center;
      padding: ${em(theme.spacing.xs, fontSize)};
      margin-top: ${em(theme.spacing.xs)};

      &:hover,
      &:focus {
        background: ${rgba(theme.colors.primary.hue, 0.05)};
      }
    `;
  }}
`;

export const InputImageUploaderActionUpload = styled(Button).attrs({
  variant: 'secondary',
  size: 'xs'
})`
  ${({ theme, size }) => {
    const fontSize = theme.fontSizes[size];

    return css`
      width: 100%;
      text-align: center;
      justify-content: center;
      padding: ${em(theme.spacing.xs, fontSize)};
    `;
  }}
`;

export const InputImageUploader = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin-top: ${em(theme.spacing.sm)};
  `}
`;

export const InputImagePreviewIcon = styled(Icon)`
  ${({ theme }) => css`
    color: ${theme.colors.dark.tint};
    width: ${em(32)};
  `}
`;

export const InputImagePreviewImage = styled(Image)`
  width: 100%;
  height: 100%;
`;

export const InputImagePreview = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${em(180)};
    height: ${em(180)};
    border: ${theme.borders.secondary()};
    border-radius: ${em(theme.radius.sx)};
    background: ${theme.colors.light.tint};
    overflow: hidden;
  `}
`;

export const StyledInputImage = styled.div`
  width: ${em(180)};
  max-width: ${em(180)};
`;
