import { useCallback, useEffect, useState } from 'react';

export const useControllableState = (props = {}) => {
  const { defaultValue, initialValue, onChange } = props;

  const HAS_INITIAL_STATE = initialValue !== undefined;

  const [state, setState] = useState(() => {
    return HAS_INITIAL_STATE ? initialValue : defaultValue;
  });

  useEffect(() => {
    if (HAS_INITIAL_STATE) setState(initialValue);
  }, [HAS_INITIAL_STATE, initialValue]);

  const handleStateSet = useCallback(
    (...args) => {
      const [state] = args;
      if (!HAS_INITIAL_STATE) setState(state);
      onChange?.(...args);
    },
    [HAS_INITIAL_STATE, onChange]
  );

  return [HAS_INITIAL_STATE ? initialValue : state, handleStateSet];
};
