import React, { forwardRef } from 'react';

import { StyledMessageEditor } from './MessageEditor.styles';

import { MessagePreview } from '../Preview';

export const MessageEditor = forwardRef(({ children, preview, ...props }, ref) => {
  return (
    <StyledMessageEditor
      {...props}
      ref={ref}
      actions={[
        'bold',
        'italic',
        'code',
        'link',
        'list-ordered',
        'list-bullet',
        'indent-plus',
        'indent-minus',
        'code-block',
        'emoji',
        'file',
        'submit'
      ]}
    >
      {preview && <MessagePreview {...preview} />}
      {children}
    </StyledMessageEditor>
  );
});
