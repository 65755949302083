import { cloneElement } from 'react';
import styled, { css } from 'styled-components';

import { shouldForwardProp } from 'utils';
import { em } from 'styles';

export const DropdownChild = styled(({ children: child, ...props }) =>
  cloneElement(child, props)
)``;

export const dropdownPositionStyles = {
  top: ({ theme }) => css`
    bottom: ${`calc(100% + ${em(theme.spacing.x)})`};
  `,
  bottom: ({ theme }) => css`
    top: ${`calc(100% + ${em(theme.spacing.x)})`};
  `
};

const positionOffsetStyles = offset => {
  return Object.entries(offset).map(([key, value]) => `${key}: ${em(value)};`);
};

export const StyledDropdown = styled.div.withConfig({ shouldForwardProp })`
  ${({ theme, position, positionOffset }) => [
    css`
      position: absolute;
      border-radius: ${em(theme.radius.sx)};
      background: ${theme.colors.light.hue};
      box-shadow: ${theme.shadows.default()};
      padding: ${em(theme.spacing.sm)};
      z-index: ${theme.zIndexes.xs};

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: ${em(theme.spacing.x)};
        background: none;
        transform: translateY(-100%);
      }
    `,
    position && dropdownPositionStyles[position],
    positionOffset && positionOffsetStyles(positionOffset)
  ]}
`;
