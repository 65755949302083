import { createSelector, createSlice } from '@reduxjs/toolkit';

import { MODAL_KEY } from '_constants';

const MODAL_INITIAL_STATE = {
  opened: []
};

const modalSlice = createSlice({
  name: MODAL_KEY,
  initialState: MODAL_INITIAL_STATE,
  reducers: {
    openModal: (state, action) => {
      state.opened.push(action.payload);
    },
    closeModal: (state, action) => {
      state.opened = state.opened.filter(({ name }) => name !== action.payload);
    }
  }
});

export const { reducer: modalReducer, actions: modalActions } = modalSlice;

export const selectModal = state => state.modal;

export const selectModalOpened = createSelector(selectModal, modal => modal.opened);
