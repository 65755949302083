import React from 'react';
import { renderToString } from 'react-dom/server';
import { Quill } from 'react-quill';
import * as FiIcons from 'react-icons/fi';
import * as BsIcons from 'react-icons/bs';
import * as BiIcons from 'react-icons/bi';

import { StyledEditorActionQuill } from './EditorActionQuill.styles';

Quill.debug('error');

const Link = Quill.import('formats/link');
const Icons = Quill.import('ui/icons');

class CustomLink extends Link {
  static sanitize(url) {
    let value = super.sanitize(url);

    if (!value?.match(/^http[s]?:\/\//)) {
      value = `http://${value}`;
    }

    return value;
  }
}

Quill.register(CustomLink);

Icons['bold'] = renderToString(<FiIcons.FiBold />);
Icons['italic'] = renderToString(<FiIcons.FiItalic />);
Icons['link'] = renderToString(<FiIcons.FiLink />);
Icons['code'] = renderToString(<FiIcons.FiCode />);
Icons['code-block'] = renderToString(<BiIcons.BiCodeBlock />);
Icons['list']['ordered'] = renderToString(<BsIcons.BsListOl />);
Icons['list']['bullet'] = renderToString(<BsIcons.BsListUl />);
Icons['indent']['+1'] = renderToString(<BsIcons.BsTextIndentLeft />);
Icons['indent']['-1'] = renderToString(<BsIcons.BsTextIndentRight />);

export const EditorActionQuill = ({ ...props }) => {
  return <StyledEditorActionQuill {...props} />;
};
