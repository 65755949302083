import styled, { css } from 'styled-components';

import { em } from 'styles';

export const StyledIndicatorUpdates = styled.span`
  ${({ theme }) => css`
    width: ${em(6)};
    height: ${em(6)};
    border-radius: 100%;
    background: ${theme.colors.secondary.hue};
  `}
`;
