import React from 'react';

import { SidebarBody, StyledSidebar } from './Sidebar.styles';

import { SidebarProvider } from './Sidebar.context';

import { SidebarHeader } from './Header';
import { SidebarContactTabs } from './Contact';

export const Sidebar = ({ ...props }) => {
  return (
    <SidebarProvider>
      <StyledSidebar {...props}>
        <SidebarHeader />
        <SidebarBody>
          <SidebarContactTabs />
        </SidebarBody>
      </StyledSidebar>
    </SidebarProvider>
  );
};
