import { rgba } from 'polished';
import { css } from 'styled-components';
import { mergeProps } from 'utils';

import { em, rem } from './helpers';

export const upperFirst = css`
  &::first-letter {
    text-transform: uppercase;
  }
`;

export const headings = style => css`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${style};
  }
`;

export const hideScrollbar = css`
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const overlapContainer = (position = 'relative') => css`
  position: ${position};
  left: 50%;
  width: 100vw;
  max-width: 100vw;
  transform: translateX(-50%);
`;

export const spacing = ({ spacing = 'xs', side, unit = 'em', fontSize } = {}) => css`
  ${({ theme }) => {
    const unitMethods = { rem, em, px: value => value };
    const _spacing = unitMethods[unit](theme.spacing[spacing], fontSize);

    if (!side) return `margin: ${_spacing}`;

    const childCount = side.match(/left|top/) ? 'first' : 'last';

    return css`
      &:not(:${childCount}-of-type) {
        margin-${side}: ${_spacing};
      }
    `;
  }}
`;

export const eventsStyle = ({
  variant = 'primary',
  fontSize,
  withHover = true,
  withFocus = true,
  withActive = true,
  ...props
} = {}) => css`
  ${({ theme }) => {
    const variantProps = {
      primary: {
        color: theme.colors.primary.hue,
        effectStrength: 0.1
      },
      secondary: {
        color: theme.colors.light.hue,
        effectStrength: 0.1
      }
    };

    const mergedProps = mergeProps(variantProps[variant], props);

    const hoverStyle = css`
      &:hover {
        color: ${mergedProps.color};
        background: ${rgba(mergedProps.color, mergedProps.effectStrength)};
      }
    `;

    const focusStyle = css`
      &:focus {
        outline: none;
        box-shadow: ${theme.shadows.focus({ fontSize, color: mergedProps.color })};
      }
    `;

    const activeStyle = css`
      &:active {
        background: ${rgba(mergedProps.color, mergedProps.effectStrength + 0.05)};
      }
    `;

    return [
      css`
        transition: all ${theme.transitions.fast}, font-size 0s, font-weight 0s;
      `,
      withHover && hoverStyle,
      withFocus && focusStyle,
      withActive && activeStyle
    ];
  }}
`;

export const disabledStyle = () => css`
  ${({ theme }) => css`
    &:disabled {
      &,
      &:hover,
      &:focus,
      &:active {
        cursor: not-allowed;
        color: ${theme.colors.dark.tint};
        border-color: ${theme.colors.light.shade};
        background-color: ${theme.colors.light.tint};

        &::placeholder {
          color: ${theme.colors.dark.tint};
        }
      }
    }
  `}
`;

export const setAspectRatio = ratio => {
  const ratioPadding = {
    '1:1': '100%',
    '3:2': '66.66%',
    '4:3': '75%',
    '8:5': '62.5%',
    '16:9': '56.25%'
  };

  return css`
    position: relative;
    width: 100%;

    &::after {
      content: '';
      display: block;
      padding-bottom: ${ratioPadding[ratio] || ratio};
      pointer-events: none;
    }
  `;
};
