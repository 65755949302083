import styled, { css } from 'styled-components';

import { View } from 'components/_shared/View';
import { Button } from 'components/_shared/Button';

import { em } from 'styles';

export const GroupProfileViewActionEdit = styled(Button).attrs({
  variant: 'secondary'
})`
  ${({ theme, size }) => css`
    && {
      margin-top: ${em(theme.spacing.lm, theme.fontSizes[size])};
    }
  `}
`;

export const StylesGroupProfileView = styled(View)``;
