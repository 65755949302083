import React from 'react';

import { ModalConfirmMessage, StyledModalConfirm } from './ModalConfirm.styles';

import { mergeProps, withFunction } from 'utils';

import { Button } from 'components/_shared/Button';

const DEFAULT_ACTIONS_PROPS = {
  cancel: {
    type: 'button',
    children: 'Cancel',
    variant: 'secondary'
  },
  submit: {
    type: 'button',
    children: 'Submit',
    variant: 'primary'
  }
};

export const ModalConfirm = ({ message, actions = {}, ...props }) => {
  const getActions = () => {
    if (actions === false) return undefined;

    return Object.entries(DEFAULT_ACTIONS_PROPS).map(([key, props]) => modal => {
      const actionProps = withFunction(actions[key], modal);
      if (actionProps === false) return null;
      const mergedProps = mergeProps({ ...props, onClick: () => modal.closeModal() }, actionProps);

      return <Button key={key} {...mergedProps} />;
    });
  };

  return (
    <StyledModalConfirm {...props} actions={getActions()}>
      {message && <ModalConfirmMessage>{message}</ModalConfirmMessage>}
    </StyledModalConfirm>
  );
};
