import React, { useCallback } from 'react';

import { EditorActionFileButton, StyledEditorActionFile } from './EditorActionFile.styles';

export const EditorActionFile = ({ editorRef, ...props }) => {
  const handleChange = useCallback(files => editorRef.setFiles?.(files), [editorRef]);

  return (
    <StyledEditorActionFile
      value={editorRef.files}
      button={<EditorActionFileButton {...props} />}
      max={1}
      onChange={handleChange}
    />
  );
};
