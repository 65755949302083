import React from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import { THEMES } from '_constants';

import { GlobalStyle } from './GlobalStyle';
import { selectActiveThemeName } from 'state';

export const ThemeProvider = ({ children }) => {
  const activeThemeName = useSelector(selectActiveThemeName);
  const activeTheme = THEMES[activeThemeName];

  return (
    <StyledThemeProvider theme={activeTheme}>
      <GlobalStyle />
      {children}
    </StyledThemeProvider>
  );
};
