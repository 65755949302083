import { cloneElement } from 'react';
import styled, { css } from 'styled-components';
import cn from 'classnames';

import { Overlay } from '../Overlay';
import { Button } from '../Button';
import { Typography } from '../Typography';

import { shouldForwardProp } from 'utils';
import { rem } from 'styles';

export const ModalAction = styled(({ children: child, ...props }) => {
  const className = cn(child.props.className, props.className);
  return cloneElement(child, { ...props, className });
})``;

export const ModalActions = styled.div`
  ${({ theme }) => {
    const childSpacing = theme.spacing.xs;

    return css`
      display: flex;
      align-items: center;
      margin: -${rem(childSpacing)};

      ${ModalAction} {
        margin: ${rem(childSpacing)};
      }
    `;
  }}
`;

export const ModalFooter = styled.footer`
  ${({ theme }) => css`
    margin-top: ${rem(theme.spacing.ml)};
  `}
`;

export const ModalBody = styled.div`
  flex: 1;
`;

export const ModalHeaderHeading = styled(Typography).attrs({ element: 'h4', size: 'sx' })``;

export const ModalHeader = styled.header`
  ${({ theme }) => css`
    flex-shrink: 0;
    display: flex;
    align-items: center;

    + ${ModalBody} {
      margin-top: ${rem(theme.spacing.ml)};
    }
  `}
`;

export const ModalToggle = styled(Button).attrs({ iconSize: 'sm' })`
  ${({ theme }) => css`
    position: absolute;
    top: ${rem(theme.spacing.xs)};
    right: ${rem(theme.spacing.xs)};
    color: ${theme.colors.dark.tone};
  `}
`;

export const ModalContainer = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: ${rem(320)};
    min-height: ${rem(128)};
    max-width: ${`calc(100% - ${rem(theme.spacing.xl)})`};
    max-height: ${`calc(100% - ${rem(theme.spacing.xl)})`};
    border-radius: ${rem(theme.radius.xs)};
    background: ${theme.colors.light.hue};
    box-shadow: ${theme.shadows.default()};
    padding: ${rem(theme.spacing.ml)};
    overflow-x: hidden;
    overflow-y: auto;
    z-index: ${theme.zIndexes.sx};
  `}
`;

export const ModalOverlay = styled(Overlay)``;

export const StyledModal = styled.div.withConfig({ shouldForwardProp })`
  ${({ theme }) => css`
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: ${theme.zIndexes.sx};
  `}
`;
