import styled, { css } from 'styled-components';

import { Dropdown } from 'components/_shared/Dropdown';
import { ContactCardActions, ContactCardStatusIndicator, ContactList } from 'components/Contact';

import { em, rem } from 'styles';
import { darken } from 'polished';

export const TagContactList = styled(ContactList)`
  ${ContactCardStatusIndicator} {
    display: none;
  }

  ${ContactCardActions} {
    display: none;
  }
`;

export const StyledTagContact = styled(Dropdown)`
  ${({ theme }) => css`
    font-size: ${em(theme.fontSizes.xs)};
    max-height: ${rem(256)};
    overflow-x: hidden;
    overflow-y: auto;
    padding: ${rem([theme.spacing.xs, 0])};
  `}
`;

export const tagContactStyle = css`
  ${({ theme }) => css`
    .tag {
      &-user {
        &-mention {
          color: ${theme.colors.primary.hue};

          &-current {
            color: ${darken(0.16, theme.colors.warning.hue)};
          }
        }
      }
    }
  `}
`;
