export const bytesToSize = bytes => {
  if (bytes === 0) return 'n/a';

  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const index = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);

  return index === 0
    ? `${bytes} ${sizes[index]})`
    : `${(bytes / 1024 ** index).toFixed(1)} ${sizes[index]}`;
};

export const getFileExtension = fileType => fileType?.replace(/\w+\//, '');
