import React, { forwardRef } from 'react';
import cn from 'classnames';

import { StyledTypography } from './Typography.styles';

export const Typography = forwardRef(({ className, children, value, onClick, ...props }, ref) => {
  return (
    <StyledTypography
      ref={ref}
      element='span'
      {...props}
      className={cn(className, { onClick })}
      onClick={onClick}
    >
      {value || children}
    </StyledTypography>
  );
});
