import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';

import { MessageDateContainer, StyledMessageDate } from './MessageDate.styles';

import { MESSAGE_ACTION_TYPE_LABLES } from '_constants';

export const MessageDate = ({ date: initialDate, action }) => {
  const rootRef = useRef(null);
  const containerRef = useRef(null);
  const [containerOffsetRight, setContainerOffsetRight] = useState(0);

  useEffect(() => {
    if (!containerRef.current || !rootRef.current) return;
    const rootRect = rootRef.current.getBoundingClientRect();
    const containerRect = containerRef.current.getBoundingClientRect();
    const offsetRight = rootRect.right - containerRect.right;
    if (offsetRight < 0) setContainerOffsetRight(offsetRight);
  }, []);

  if (!initialDate) return null;

  const date = moment(initialDate).format('hh:mm a');

  const renderTimeWithAction = () => {
    const actionTime = moment(action.date).format('hh:mm a');
    const actionDate = moment(action.date).format('DD MMM YYYY');
    const actionTypeLabel = MESSAGE_ACTION_TYPE_LABLES[action.type];

    if (!actionTypeLabel) return date;

    if (!action.authorUserName) return `${actionTypeLabel} ${actionTime}`;

    return `${date} (${actionTypeLabel} ${actionTime}, ${actionDate} by ${action.authorUserName})`;
  };

  return (
    <StyledMessageDate ref={rootRef} containerOffsetRight={containerOffsetRight}>
      <MessageDateContainer ref={containerRef}>
        {action ? renderTimeWithAction() : date}
      </MessageDateContainer>
    </StyledMessageDate>
  );
};
