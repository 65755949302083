import { omit } from 'lodash-es';

export const API_ENDPOINTS = {
  SIGN_IN: '/SignIn',
  SIGN_UP: '/SignUp',

  PASSWORD_RESET: '/RecoveryUserPassword',
  PASSWORD_CHANGE: '/ChangeUserPassword',

  CONTACT_LIST: '/ContactList',
  CONTACT_REMOVE: '/ContactListRemove',
  CONTACT_REQUEST_SEND: '/ContactListAdd',
  CONTACT_REQUEST_UPDATE: '/ContactListAccept',

  USER_LIST: '/UserList',
  USER_PROFILE: '/GetUserProfile',
  USER_PROFILE_EDIT: '/EditUserProfile',

  GROUP_LIST: '/ChatList',
  GROUP_ADD: '/ChatCreate',
  GROUP_PROFILE: '/GetChatProfile',
  GROUP_PROFILE_EDIT: '/EditChatProfile',
  GROUP_USER_LIST: '/ChatMembers',
  GROUP_USER_ADD: '/ChatAddUser',
  GROUP_USER_REMOVE: '/ChatRemoveUser',
  GROUP_MESSAGES: '/ChatHistory',

  CHAT_MESSAGES_DIRECT: '/DirectMessages',

  MESSAGE_SEND: '/SendMessage',
  MESSAGE_EDIT: '/EditMessage',
  MESSAGE_REMOVE: '/DeleteMessage',

  ATTACHMENT_GET: '/GetAttachment',

  HISTORY_REMOVE: '/DeleteHistory'
};

export const API_ENDPOINTS_AUTHORIZED = Object.values(omit(API_ENDPOINTS, ['SIGN_IN', 'SIGN_UP']));
