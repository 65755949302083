import React from 'react';
import { toast } from 'react-toastify';
import { isBoolean } from 'lodash-es';
import Cookie from 'js-cookie';
import UIfx from 'uifx';

import notificationSound from 'assets/sounds/notification-1.wav';

import { SETTINGS_KEY } from '_constants';

import { StyledNotification } from './Notification.styles';

const notificationSFX = {
  popup: new UIfx(notificationSound)
};

export const notify = (content, params = {}) => {
  const storedSettings = Cookie.getJSON(SETTINGS_KEY)?.notification;
  let { withSound = true } = params;

  if (isBoolean(storedSettings?.withSound)) {
    withSound = storedSettings.withSound;
  }

  toast(content, {
    ...params,
    onOpen: () => {
      withSound && notificationSFX.popup.play();
      params?.onOpen?.();
    }
  });
};

export const Notification = props => {
  return (
    <StyledNotification
      limit={3}
      position='bottom-right'
      hideProgressBar
      pauseOnFocusLoss
      {...props}
    />
  );
};
