import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import { AUTH_KEY, CHAT_KEY, GROUP_KEY, MODAL_KEY, SETTINGS_KEY, USER_KEY } from '_constants';

import { authReducer } from './auth';
import { chatReducer } from './chat';
import { groupReducer } from './group';
import { modalReducer } from './modal';
import { settingsReducer } from './settings';
import { userReducer } from './user';

export const store = configureStore({
  reducer: {
    [AUTH_KEY]: authReducer,
    [CHAT_KEY]: chatReducer,
    [GROUP_KEY]: groupReducer,
    [MODAL_KEY]: modalReducer,
    [SETTINGS_KEY]: settingsReducer,
    [USER_KEY]: userReducer
  },
  middleware: getDefaultMiddleware({ serializableCheck: false })
});
