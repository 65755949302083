import React from 'react';

import { StyledPage } from './Page.styles';

import { Layout } from 'components/Layout';
import { Sidebar } from 'components/Sidebar';

export const Page = ({ children, sidebar = Sidebar, ...props }) => {
  return (
    <StyledPage {...props}>
      <Layout sidebar={sidebar}>{children}</Layout>
    </StyledPage>
  );
};
