import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { modalActions } from './modal.slice';

export const useModal = () => {
  const dispatch = useDispatch();

  const openModal = useCallback(
    (name, props) => {
      dispatch(modalActions.openModal({ name, props }));
    },
    [dispatch]
  );

  const closeModal = useCallback(
    name => {
      dispatch(modalActions.closeModal(name));
    },
    [dispatch]
  );

  return { openModal, closeModal };
};
