import { cloneTheme } from 'styles/utils';
import { themeDefault } from './theme.default';

export const themeBlue = cloneTheme(themeDefault, {
  colors: {
    primary: {
      hue: '#1564D1',
      tint: '#DBE5F4',
      tone: '#135CC2',
      shade: '#1254AE'
    },

    sidebar: {
      contactList: {
        active: '#1254AE'
      }
    },

    view: {
      background: '#DBE5F4',
      aside: '#e9eff8'
    },

    message: {
      card: {
        active: '#e8f1fd'
      }
    },

    input: {
      secondary: {
        background: '#1254AE'
      }
    }
  }
});
