import React, { forwardRef } from 'react';

import { StyledEditorAction } from './EditorAction.styles';

export const EditorAction = forwardRef(({ children, ...props }, ref) => {
  return (
    <StyledEditorAction ref={ref} type='button' iconSize='sm' {...props}>
      {children}
    </StyledEditorAction>
  );
});
