import styled, { css } from 'styled-components';

import { Modal } from 'components/_shared/Modal';
import { Button } from 'components/_shared/Button';

import { em, eventsStyle } from 'styles';

export const PasswordChangeModalFormFooterAction = styled(Button)`
  ${({ theme }) => {
    const fontSize = theme.fontSizes.x;

    return css`
      font-size: ${em(fontSize)};
      text-align: center;
      text-decoration: none;

      &:hover,
      &:focus,
      &:active {
        text-decoration: underline;
        background: none;
      }

      &:focus {
        box-shadow: ${eventsStyle({
          withHover: false,
          withActive: false,
          fontSize,
          color: theme.colors.light.hue
        })};
      }

      &:not(:last-of-type) {
        margin-right: ${em(theme.spacing.xs, fontSize)};
      }
    `;
  }}
`;

export const PasswordChangeModalFormFooterActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledPasswordChangeModal = styled(Modal)``;
