const ROOT = '/';
const AUTH = `${ROOT}auth`;

const PASSWORD_RESET = `${ROOT}password-reset`;
const PASSWORD_CHANGE = `${ROOT}password-change`;

const USER = `${ROOT}user`;
const USER_BY_ID = id => `${USER}/${id || ':id'}`;
const USER_BY_USERNAME = username => `${USER}/${username || ':username'}`;

const GROUP = `${ROOT}group`;
const GROUP_ADD = `${GROUP}/add`;
const GROUP_BY_ID = id => `${GROUP}/${id || ':id'}`;

const CHAT = `${ROOT}chat`;
const CHAT_BY_CONTACT_ID = id => `${CHAT}/${id || ':contactId'}`;

const SETTINGS = `${ROOT}settings`;

export const ROUTES = {
  ROOT,
  AUTH,

  PASSWORD_RESET,
  PASSWORD_CHANGE,

  USER,
  USER_BY_ID,
  USER_BY_USERNAME,

  GROUP,
  GROUP_ADD,
  GROUP_BY_ID,

  CHAT,
  CHAT_BY_CONTACT_ID,

  SETTINGS
};
