import React from 'react';
import { useField } from 'formik';

import { StyledFormField } from './FormField.styles';

import { mergeProps, withFunction } from 'utils';

export const FormField = ({ name, control, ...props }) => {
  const [field, meta, helpers] = useField(name);

  const mergedProps = mergeProps({ control: field, error: meta.touched && meta.error }, [
    props,
    { control: withFunction(control, { field, meta, helpers }) }
  ]);

  return <StyledFormField {...mergedProps} />;
};
