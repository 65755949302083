import { lighten } from 'polished';

import { cloneTheme } from 'styles/utils';
import { themeDefault } from './theme.default';

export const themePurple = cloneTheme(themeDefault, {
  colors: {
    primary: {
      hue: '#360C37',
      tone: '#2E0A2F'
    },

    secondary: {
      hue: '#FD3368',
      tint: lighten(0.05, '#FD3368')
    },

    message: {
      card: {
        active: lighten(0.8, '#360C37')
      }
    },

    indicator: {
      count: {
        foreground: '#ffffff',
        background: '#FD3368'
      }
    }
  }
});
