import React, { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { map } from 'lodash-es';

import { StyledGroupUsersModalAdd } from './GroupUsersModalAdd.styles';

import { GROUP_KEYS, USER_KEYS } from '_constants';

import { ContactList } from 'components/Contact';
import { Button } from 'components/_shared/Button';
import { Preloader } from 'components/_shared/Preloader';

import { makeSelectGroupByPredicate, selectAcceptedUsers, useGroupActions } from 'state';

export const GroupUsersModalAdd = ({ groupId, ...props }) => {
  const modalRef = useRef(null);
  const contactsListRef = useRef(null);
  const { isLoading, addGroupUsers } = useGroupActions();

  const selectGroupByPredicate = useMemo(makeSelectGroupByPredicate, []);
  const group = useSelector(state => selectGroupByPredicate(state, [GROUP_KEYS.ID, groupId]));

  const users = useSelector(selectAcceptedUsers);
  const availableUsers = users.filter(user => {
    return !group[GROUP_KEYS.USERS].some(groupUser => {
      return groupUser[USER_KEYS.ID] === user[USER_KEYS.ID];
    });
  });

  const handleActionAddClick = async () => {
    try {
      const { closeModal } = modalRef.current;
      const { selectedContacts } = contactsListRef.current;

      if (!selectedContacts?.length) return;

      await addGroupUsers(group[GROUP_KEYS.ID], map(selectedContacts, USER_KEYS.ID));

      closeModal();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <StyledGroupUsersModalAdd
      {...props}
      ref={modalRef}
      heading={`Add members to the ${group[GROUP_KEYS.NAME]} group`}
      actions={[
        ({ closeModal }) => (
          <Button type='button' variant='secondary' onClick={closeModal}>
            Cancel
          </Button>
        ),
        ({ closeModal }) => (
          <Button type='button' variant='primary' onClick={() => handleActionAddClick(closeModal)}>
            Add
          </Button>
        )
      ]}
    >
      <ContactList
        ref={contactsListRef}
        contacts={availableUsers}
        actions={{ contactSelect: true }}
        withContactSearch
      />

      {isLoading && <Preloader isTransparent />}
    </StyledGroupUsersModalAdd>
  );
};
