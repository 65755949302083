import { createSelector, createSlice } from '@reduxjs/toolkit';
import { filter, find, isString, pick } from 'lodash-es';

import { USER_KEY, USER_KEYS, USER_STATUSES } from '_constants';

import {
  isUserAccepted,
  mergeObjects,
  normalizeUser,
  normalizeUserStatus,
  removeFalsyValues
} from 'utils';

export const USER_INITIAL_STATE = {
  entities: {}
};

const userSlice = createSlice({
  name: USER_KEY,
  initialState: USER_INITIAL_STATE,
  reducers: {
    setEntities: {
      reducer: (state, action) => {
        state.entities = action.payload;
      },
      prepare: entities => {
        return {
          payload: entities.reduce((entities, entity) => {
            const normalizedEntity = normalizeUser(entity);
            return { ...entities, [normalizedEntity[USER_KEYS.ID]]: normalizedEntity };
          }, {})
        };
      }
    },

    updateEntities: {
      reducer: (state, action) => {
        const entities = action.payload || [];
        entities.forEach(entity => {
          state.entities[entity.id] = mergeObjects(state.entities[entity.id], entity);
        });
      },
      prepare: entities => {
        return {
          payload: entities.map(entity => {
            return normalizeUser(entity, { normalizeKeysOnly: true });
          })
        };
      }
    },

    addEntity: {
      reducer: (state, action) => {
        state.entities[action.payload[USER_KEYS.ID]] = action.payload;
      },
      prepare: entity => {
        const normalizedEntity = normalizeUser(entity);
        return { payload: normalizedEntity };
      }
    },

    updateEntity: {
      reducer: (state, action) => {
        const entity = action.payload;
        const prevEntity = state.entities[entity[USER_KEYS.ID]];
        state.entities[entity[USER_KEYS.ID]] = mergeObjects(prevEntity, entity);
      },
      prepare: payload => {
        const { entity } = payload;
        return { payload: removeFalsyValues(normalizeUser(entity, { pickKeys: true }), ['']) };
      }
    },

    removeEntity: (state, action) => {
      const entityId = action.payload;
      state.entities[entityId][USER_KEYS.STATUS] = USER_STATUSES.AVAILABLE;
      delete state.entities[entityId][USER_KEYS.IS_ACCEPTED];
    },

    updateEntityStatus: (state, action) => {
      const { [USER_KEYS.ID]: id, [USER_KEYS.REQUESTOR_ID]: requestorId } = action.payload;
      const entity = state.entities[id];
      const normalizedStatus = normalizeUserStatus({ ...entity, ...action.payload });
      if (isString(requestorId)) state.entities[id][USER_KEYS.REQUESTOR_ID] = requestorId;
      state.entities[id] = { ...entity, ...normalizedStatus };
    }
  }
});

export const { reducer: userReducer, actions: userActions } = userSlice;

export const selectUsers = state => state.user.entities;

export const selectCurrentUser = createSelector(selectUsers, users => {
  return find(users, USER_KEYS.IS_CURRENT_USER);
});

export const selectUsersWithoutCurrentUser = createSelector(selectUsers, users => {
  return filter(users, [USER_KEYS.IS_CURRENT_USER, false]);
});

export const selectAcceptedUsers = createSelector(selectUsers, users => {
  return filter(users, isUserAccepted);
});

export const makeSelectUserByPredicate = () => {
  return createSelector(
    selectUsers,
    (_, predicate) => predicate,
    (users, predicate) => find(users, predicate)
  );
};

export const makeSelectUsersMeta = () => {
  return createSelector(selectUsers, users => {
    return Object.entries(users).reduce((users, [userId, user]) => {
      return { ...users, [userId]: pick(user, ['id', 'username']) };
    }, {});
  });
};
