import styled, { css } from 'styled-components';

import { Typography } from 'components/_shared/Typography';

import { em, hideScrollbar } from 'styles';

export const MessageDateContainer = styled.span`
  display: inline-block;
  transform: translateX(0);
  transition: 1s ease;
`;

export const StyledMessageDate = styled(Typography).attrs({ element: 'p', size: 'x' })`
  ${({ theme, size, containerOffsetRight }) => {
    const fontSize = theme.fontSizes[size];

    return [
      css`
        position: relative;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: ${theme.colors.dark.tone};
        display: inline-block;
        padding-top: ${em(2, fontSize)};
        margin-left: ${em(theme.spacing.xs, fontSize)};
        overflow: hidden;

        ${hideScrollbar};
      `,
      containerOffsetRight < 0 &&
        css`
          &:hover {
            text-overflow: unset;

            ${MessageDateContainer} {
              transition: 2s linear;
              transform: translateX(${`${containerOffsetRight}px`});
            }
          }
        `
    ];
  }}
`;
