import styled from 'styled-components';

import { View } from 'components/_shared/View';
import { StyledFormGroup } from 'components/_shared/Form';

import { rem } from 'styles';

export const StyledSettingsView = styled(View)`
  ${StyledFormGroup} {
    max-width: ${rem(320)};
  }
`;
