import React, { useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  StylesUserProfileView,
  UserProfileViewActionEdit,
  ActionTogglePasswordChangeModal
} from './UserProfileView.styles';

import { USER_KEYS } from '_constants';

import { UserFormEdit } from '../FormEdit';
import { UserInfo } from '../Info';
import { Preloader } from 'components/_shared/Preloader';

import { makeSelectUserByPredicate, useUserActions } from 'state';
import { pickUserProfileFields } from 'utils';
import { PasswordChangeModal } from 'components/Password';

export const UserProfileView = ({ match, ...props }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isPasswordChangeModalOpen, setIsPasswordChangeModalOpen] = useState(false);
  const { isLoading, getUserProfile, updateUser } = useUserActions();

  const requestedUserId = match.params[USER_KEYS.ID];

  const selectUserByPredicate = useMemo(makeSelectUserByPredicate, []);
  const user = useSelector(state => selectUserByPredicate(state, [USER_KEYS.ID, requestedUserId]));

  useEffect(() => {
    (async () => await getUserProfile(requestedUserId))();
  }, [requestedUserId, getUserProfile]);

  const handleFormSubmit = async values => {
    await updateUser(values);
    setIsEditing(false);
  };

  return (
    <StylesUserProfileView {...props} heading='User profile'>
      {isEditing ? (
        <UserFormEdit
          initialValues={pickUserProfileFields(user)}
          isLoading={isLoading}
          onSubmit={handleFormSubmit}
          onCancel={() => setIsEditing(false)}
        />
      ) : (
        <UserInfo user={user}>
          {isLoading && <Preloader isTransparent />}

          {user[USER_KEYS.IS_CURRENT_USER] && (
            <>
              <UserProfileViewActionEdit onClick={() => setIsEditing(true)}>
                Edit
              </UserProfileViewActionEdit>

              <ActionTogglePasswordChangeModal onClick={() => setIsPasswordChangeModalOpen(true)}>
                Change password
              </ActionTogglePasswordChangeModal>

              <PasswordChangeModal
                isOpen={isPasswordChangeModalOpen}
                onClose={() => setIsPasswordChangeModalOpen(false)}
                onSubmit={() => setIsPasswordChangeModalOpen(false)}
              />
            </>
          )}
        </UserInfo>
      )}
    </StylesUserProfileView>
  );
};
