import styled, { css } from 'styled-components';

import { Button } from '../Button';
import { Dropdown } from '../Dropdown';

import { shouldForwardProp } from 'utils';
import { em, eventsStyle } from 'styles';

export const MenuListItem = styled.li`
  ${({ theme }) => {
    const fontSize = theme.fontSizes.xs;

    return css`
      && > * {
        font-family: ${theme.fonts.primary};
        font-size: ${em(fontSize)};
        font-weight: ${theme.fontWeights.medium};
        line-height: ${theme.lineHeight.xs};
        text-decoration: none;
        white-space: nowrap;
        color: ${theme.colors.dark.hue};
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        border: none;
        border-radius: ${em(theme.radius.x, fontSize)};
        background: none;
        padding: ${em([theme.spacing.xs - 2, theme.spacing.sm], fontSize)};
        margin: 0;

        &:hover,
        &:focus,
        &:active {
          text-decoration: none;
        }

        ${eventsStyle({ fontSize })};
      }
    `;
  }}
`;

export const MenuList = styled.ul``;

export const MenuDropdown = styled(Dropdown)`
  ${({ theme }) => css`
    right: 0;
    max-width: ${em(320)};
    padding: ${em([theme.spacing.xs, 0])};
  `}
`;

export const MenuToggle = styled(Button)``;

export const StyledMenu = styled.div.withConfig({ shouldForwardProp })`
  position: relative;
  display: flex;
`;
