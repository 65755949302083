import React, { memo, useCallback } from 'react';

import { EditorToolbarAction, StyledEditorToolbar } from './EditorToolbar.styles';

import { EDITOR_ACTION_ELEMENTS } from '../Action';

import { mergeProps, withFunction } from 'utils';

export const EditorToolbar = memo(({ editorRef, actions = [], actionsProps = {}, ...props }) => {
  const renderActions = useCallback(() => {
    if (!actions.length) return null;

    return actions.map(actionKey => {
      const actionElement = EDITOR_ACTION_ELEMENTS[actionKey];
      const actionKeyProps = actionsProps[actionKey];
      const actionProps = withFunction(actionKeyProps, { editorRef });
      const mergedProps = mergeProps(actionElement.props, [{ editorRef }, actionProps]);

      return (
        <EditorToolbarAction key={actionKey} {...mergedProps}>
          {actionElement}
        </EditorToolbarAction>
      );
    });
  }, [actions, actionsProps, editorRef]);

  return <StyledEditorToolbar {...props}>{renderActions()}</StyledEditorToolbar>;
});
