import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ChatViewActionsButton, StyledChatViewActions } from './ChatViewActions.styles';

import { makeSelectChatSelectedMessages, useChatActions } from 'state';

export const ChatViewActions = ({ contactId, ...props }) => {
  const { removeMessages } = useChatActions();

  const selectChatSelectedMessages = useMemo(makeSelectChatSelectedMessages, []);
  const chatSelectedMessages = useSelector(state => selectChatSelectedMessages(state, contactId));

  const chatSelectedMessagesLength = chatSelectedMessages.length;

  return (
    <StyledChatViewActions {...props}>
      {!!chatSelectedMessagesLength && (
        <ChatViewActionsButton
          variant='secondary'
          size='xs'
          onClick={() => removeMessages(contactId, chatSelectedMessages)}
        >{`Remove ${chatSelectedMessagesLength}`}</ChatViewActionsButton>
      )}
    </StyledChatViewActions>
  );
};
