import React, { useRef, useState, useImperativeHandle, useEffect, forwardRef } from 'react';
import { FiX } from 'react-icons/fi';

import {
  FileViewerBody,
  FileViewerContainer,
  FileViewerHeader,
  FileViewerHeaderHeading,
  FileViewerOverlay,
  FileViewerToggle,
  StyledFileViewer
} from './FileViewer.styles';

import { FileViewerImage } from './Image';
import { FileViewerPDF } from './PDF';

import { UseClickOutsideConditional } from 'hooks';
import { getFileType } from 'utils';

const fileTypeViewers = {
  image: FileViewerImage,
  application: FileViewerPDF
};

export const FileViewer = forwardRef(
  (
    {
      children,
      file,
      heading,
      isOpen: initialIsOpen = true,
      clickOutsideParams = {},
      closeOnClickOutside = true,
      onClose,
      onClickOutside,
      ...props
    },
    ref
  ) => {
    const rootRef = useRef(null);

    const [isOpen, setIsOpen] = useState(initialIsOpen);
    useEffect(() => setIsOpen(initialIsOpen), [initialIsOpen]);

    useImperativeHandle(ref, () => rootRef.current);

    const fileType = getFileType(file);
    const canRenderHeader = heading;

    const handleClose = () => {
      setIsOpen(false);
      onClose?.();
    };

    const handleClickOutside = () => {
      closeOnClickOutside && handleClose();
      onClickOutside?.();
    };

    const renderTypeViewer = () => {
      const FileTypeViewer = fileTypeViewers[fileType];

      if (!FileTypeViewer) return null;

      return <FileTypeViewer file={file} />;
    };

    if (!isOpen) return null;

    return (
      <>
        {isOpen && (
          <UseClickOutsideConditional
            elementRef={rootRef}
            callback={handleClickOutside}
            params={clickOutsideParams}
          />
        )}

        <StyledFileViewer {...props} fileType={fileType}>
          <FileViewerOverlay />

          <FileViewerToggle icon={<FiX />} onClick={handleClose} />

          <FileViewerContainer ref={rootRef}>
            {canRenderHeader && (
              <FileViewerHeader>
                {heading && <FileViewerHeaderHeading>{heading}</FileViewerHeaderHeading>}
              </FileViewerHeader>
            )}

            <FileViewerBody>
              {renderTypeViewer()}
              {children}
            </FileViewerBody>
          </FileViewerContainer>
        </StyledFileViewer>
      </>
    );
  }
);
