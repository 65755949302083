export const TAG_KEYS = {
  MENTION: 'MENTION',
  ASSIGN: 'ASSIGN'
};

export const TAG_TRIGGERS = {
  [TAG_KEYS.MENTION]: '@',
  [TAG_KEYS.ASSIGN]: '#'
};

export const TAG_TRIGGERS_VALUES = Object.values(TAG_TRIGGERS);

export const TAG_TRIGGERS_JOINED = TAG_TRIGGERS_VALUES.join('|');

export const TAG_MATCH_REGEXP = new RegExp(`(^|\\W)(${TAG_TRIGGERS_JOINED})(\\w+)?`, 'gi');

export const TAG_VARIANTS = {};
