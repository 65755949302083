import React from 'react';
import { FiFile } from 'react-icons/fi';

import {
  FileCardBody,
  FileCardFooter,
  FileCardHeader,
  FileCardMeta,
  FileCardMetaWrapper,
  FileCardPreview,
  FileCardPreviewIcon,
  FileCardPreviewImage,
  FileCardTitle,
  StyledFileCard
} from './FileCard.styles';

import { bytesToSize, getFileExtension } from 'utils';

export const FileCard = ({ children, title, icon = <FiFile />, image, type, size, ...props }) => {
  const canRenderPreview = image || icon;
  const canRenderHeader = title;
  const canRenderMeta = type || size;
  const canRenderFooter = canRenderMeta;

  return (
    <StyledFileCard {...props}>
      {canRenderPreview && (
        <FileCardPreview withImage={!!image}>
          {image ? (
            <FileCardPreviewImage src={image} alt={title} />
          ) : (
            <FileCardPreviewIcon>{icon}</FileCardPreviewIcon>
          )}
        </FileCardPreview>
      )}

      <FileCardBody>
        {canRenderHeader && (
          <FileCardHeader>{title && <FileCardTitle>{title}</FileCardTitle>}</FileCardHeader>
        )}

        {children}

        {canRenderFooter && (
          <FileCardFooter>
            {canRenderMeta && (
              <FileCardMetaWrapper>
                {type && <FileCardMeta>{getFileExtension(type)}</FileCardMeta>}
                {size && <FileCardMeta>{bytesToSize(size)}</FileCardMeta>}
              </FileCardMetaWrapper>
            )}
          </FileCardFooter>
        )}
      </FileCardBody>
    </StyledFileCard>
  );
};
