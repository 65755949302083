import { createElement, forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { checkIsHeadingElement, getHeadingLevel, shouldForwardProp } from 'utils';

import { em } from 'styles';

export const typographyHeadingStyle = ({ theme }) => css`
  font-weight: ${theme.fontWeights.bold};
`;

export const typographyTextStyle = ({ theme }) => css`
  font-weight: ${theme.fontWeights.regular};
`;

export const typographyElemnetTypeStyle = ({ isHeading }) => {
  return isHeading ? typographyHeadingStyle : typographyTextStyle;
};

export const StyledTypography = styled(
  forwardRef(({ element, ...props }, ref) => {
    props.ref = ref;
    return createElement(props.dangerouslySetInnerHTML ? 'div' : element, props);
  })
)
  .withConfig({ shouldForwardProp })
  .attrs(({ theme, element: initialElement, variant, size }) => {
    const element = variant || initialElement;
    const isHeading = checkIsHeadingElement(element);
    let fontSize = size ? theme.fontSizes[size] : 'inherit';

    if (isHeading && !size) {
      fontSize = [...Object.values(theme.fontSizes)].reverse()[getHeadingLevel(element) - 1];
    }

    return { element, fontSize, isHeading };
  })`
  ${({ theme, fontSize }) => [
    css`
      font-family: ${theme.fonts.primary};
      font-size: ${em(fontSize)};
      line-height: ${theme.lineHeight.x};

      &.onClick {
        transition: ${theme.transitions.medium};
        cursor: pointer;

        &:hover,
        &:focus {
          color: ${theme.colors.primary.hue};
        }
      }
    `,
    typographyElemnetTypeStyle
  ]}
`;
