import { themes } from 'styles';

export const SETTINGS_KEY = 'settings';

export const THEME_NAMES = {
  BLACK: 'black',
  BLUE: 'blue',
  BLUE_DARK: 'blue dark',
  BROWN: 'brown',
  BROWN_DARK: 'brown dark',
  GRAY: 'gray',
  GREEN: 'green',
  ORANGE: 'orange',
  PURPLE: 'purple'
};

export const THEMES = {
  [THEME_NAMES.BLACK]: themes.themeBlack,
  [THEME_NAMES.BLUE]: themes.themeBlue,
  [THEME_NAMES.BLUE_DARK]: themes.themeBlueDark,
  [THEME_NAMES.BROWN]: themes.themeBrown,
  [THEME_NAMES.BROWN_DARK]: themes.themeBrownDark,
  [THEME_NAMES.GRAY]: themes.themeGray,
  [THEME_NAMES.GREEN]: themes.themeGreen,
  [THEME_NAMES.ORANGE]: themes.themeOrange,
  [THEME_NAMES.PURPLE]: themes.themePurple
};

export const DEFAULT_THEME = THEMES[THEME_NAMES.BLUE];
