import React from 'react';

import { StyledMessageCardFileView } from './MessageCardFileView.styles';

import { useMessageFile } from 'state';

export const MessageCardFileView = ({ id, file, ...props }) => {
  const { isLoading, getFile } = useMessageFile();

  const handleEmptyPreviewClick = () => getFile(id);

  return (
    <StyledMessageCardFileView
      {...props}
      fileId={id}
      file={file}
      isLoading={isLoading}
      onEmptyPreviewClick={handleEmptyPreviewClick}
    />
  );
};
