import React from 'react';
import { upperFirst } from 'lodash-es';

import { StyledSettingsView } from './SettingsView.styles';

import { useSettings } from 'state';

import { FormGroup } from 'components/_shared/Form';
import { Field } from 'components/_shared/Field';

export const SettingsView = ({ ...props }) => {
  const settings = useSettings();

  const themeSelectOptions = Object.keys(settings.themes).map(key => ({
    key: upperFirst(key),
    value: key
  }));

  return (
    <StyledSettingsView {...props} heading='Settings'>
      <FormGroup>
        <Field
          type='select'
          label='Theme'
          control={{
            defaultValue: settings.theme.name,
            options: themeSelectOptions,
            onChange: e => settings.setTheme(e.target.value)
          }}
        />

        <Field
          type='checkbox'
          control={{
            label: 'Notification with sound',
            value: settings.notification.withSound,
            onChange: e => settings.setNotificationSound(e.target.checked)
          }}
        />
      </FormGroup>
    </StyledSettingsView>
  );
};
