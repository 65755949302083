import styled, { css } from 'styled-components';

import { Search, SearchAction } from 'components/_shared/Search';

import { eventsStyle, rem } from 'styles';

export const StyledSidebarSearch = styled(Search)`
  ${({ theme }) => css`
    margin-top: ${rem(theme.spacing.sm)};

    ${SearchAction} {
      ${eventsStyle({ variant: 'secondary', withHover: false })};

      &:hover {
        color: inherit;
      }
    }
  `}
`;
