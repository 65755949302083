import { AXIOS_INSTANCE } from './instance';
import { API_ENDPOINTS } from './endpoints';
import { AUTH_KEYS, GROUP_KEYS, USER_KEYS } from '_constants';

// Auth

export const signIn = body => {
  return AXIOS_INSTANCE.post(API_ENDPOINTS.SIGN_IN, {
    userEmail: body[AUTH_KEYS.EMAIL],
    userPassword: body[AUTH_KEYS.PASSWORD]
  });
};

export const signUp = body => {
  return AXIOS_INSTANCE.post(API_ENDPOINTS.SIGN_UP, {
    userName: body[AUTH_KEYS.USERNAME],
    userEmail: body[AUTH_KEYS.EMAIL],
    userPassword: body[AUTH_KEYS.PASSWORD]
  });
};

// Password

export const resetPassword = ({ username, email } = {}) => {
  return AXIOS_INSTANCE.post(API_ENDPOINTS.PASSWORD_RESET, { Login: username, Email: email });
};

export const changePassword = ({ oldPassword, newPassword } = {}) => {
  return AXIOS_INSTANCE.post(API_ENDPOINTS.PASSWORD_CHANGE, {
    OldUserPassword: oldPassword,
    NewUserPassword: newPassword
  });
};

// Contact

export const getContactList = () => {
  return AXIOS_INSTANCE.post(API_ENDPOINTS.CONTACT_LIST);
};

export const requestContact = id => {
  return AXIOS_INSTANCE.post(API_ENDPOINTS.CONTACT_REQUEST_SEND, { ContactUserID: id });
};

export const updateContactRequest = (id, isAccepted) => {
  return AXIOS_INSTANCE.post(API_ENDPOINTS.CONTACT_REQUEST_UPDATE, {
    ContactUserID: id,
    Accept: isAccepted
  });
};

export const removeContact = id => {
  return AXIOS_INSTANCE.post(API_ENDPOINTS.CONTACT_REMOVE, { ContactUserID: id });
};

// User

export const getUserList = () => {
  return AXIOS_INSTANCE.post(API_ENDPOINTS.USER_LIST);
};

export const getUserProfile = id => {
  return AXIOS_INSTANCE.post(API_ENDPOINTS.USER_PROFILE, { RequestedUserID: id });
};

export const updateUserProfile = (user = {}) => {
  return AXIOS_INSTANCE.post(API_ENDPOINTS.USER_PROFILE_EDIT, {
    RequestedUserID: user[USER_KEYS.ID],
    Avatar: user[USER_KEYS.AVATAR],
    Name: user[USER_KEYS.NAME],
    LastName: user[USER_KEYS.LASTNAME],
    Email: user[USER_KEYS.EMAIL],
    Business: user[USER_KEYS.EMAIL_BUSINESS],
    Personal: user[USER_KEYS.EMAIL_PERSONAL],
    Organization: user[USER_KEYS.ORGANIZATION]
  });
};

export const getUserDirectMessages = id => {
  return AXIOS_INSTANCE.post(API_ENDPOINTS.CHAT_MESSAGES_DIRECT, { ContactUserID: id });
};

// Group

export const addGroup = (group = {}) => {
  return AXIOS_INSTANCE.post(API_ENDPOINTS.GROUP_ADD, {
    Avatar: group[GROUP_KEYS.AVATAR],
    Name: group[GROUP_KEYS.NAME],
    Email: group[GROUP_KEYS.EMAIL],
    Buisness: group[GROUP_KEYS.EMAIL_BUSINESS],
    Personal: group[GROUP_KEYS.EMAIL_PERSONAL]
  });
};

export const getGroupList = ({ withFullList = false } = {}) => {
  return AXIOS_INSTANCE.post(API_ENDPOINTS.GROUP_LIST, { FullList: withFullList });
};

export const getGroupProfile = id => {
  return AXIOS_INSTANCE.post(API_ENDPOINTS.GROUP_PROFILE, { ChatID: id });
};

export const updateGroupProfile = (group = {}) => {
  return AXIOS_INSTANCE.post(API_ENDPOINTS.GROUP_PROFILE_EDIT, {
    ChatID: group[GROUP_KEYS.ID],
    Avatar: group[GROUP_KEYS.AVATAR],
    Name: group[GROUP_KEYS.NAME],
    Email: group[GROUP_KEYS.EMAIL],
    Buisness: group[GROUP_KEYS.EMAIL_BUSINESS],
    Personal: group[GROUP_KEYS.EMAIL_PERSONAL]
  });
};

export const getGroupUsers = id => {
  return AXIOS_INSTANCE.post(API_ENDPOINTS.GROUP_USER_LIST, { ChatID: id });
};

export const addGroupUser = (body = {}) => {
  return AXIOS_INSTANCE.post(API_ENDPOINTS.GROUP_USER_ADD, {
    ChatID: body[GROUP_KEYS.ID],
    NewUserID: body.userId
  });
};

export const removeGroupUser = (body = {}) => {
  return AXIOS_INSTANCE.post(API_ENDPOINTS.GROUP_USER_REMOVE, {
    ChatID: body[GROUP_KEYS.ID],
    UserToRemoveID: body.userId
  });
};

export const getGroupMessages = id => {
  return AXIOS_INSTANCE.post(API_ENDPOINTS.GROUP_MESSAGES, { ChatID: id });
};

// Message

export const sendMessage = ({ body, chatId, contactId, contactType }) => {
  return AXIOS_INSTANCE.post(API_ENDPOINTS.MESSAGE_SEND, {
    MessageBody: body,
    ChatID: chatId,
    ReceiverID: contactId,
    ReceiverType: contactType
  });
};

export const editMessage = ({ body, id, chatId, contactId }) => {
  return AXIOS_INSTANCE.post(API_ENDPOINTS.MESSAGE_EDIT, {
    Body: body,
    MessageID: id,
    ChatID: chatId,
    ReceiverID: contactId
  });
};

export const removeMessage = ({ id, chatId, contactId }) => {
  return AXIOS_INSTANCE.post(API_ENDPOINTS.MESSAGE_REMOVE, {
    MessageID: id,
    ChatID: chatId,
    ReceiverID: contactId
  });
};

export const removeHistory = ({ chatId, reason = 'Delete history on device' }) => {
  return AXIOS_INSTANCE.post(API_ENDPOINTS.HISTORY_REMOVE, {
    ChatID: chatId,
    Reason: reason
  });
};

// Attachment

export const getAttachment = id => {
  return AXIOS_INSTANCE.post(API_ENDPOINTS.ATTACHMENT_GET, { AttachmentID: id });
};
