import styled, { css } from 'styled-components';

import { FileCard } from 'components/_shared/File';

import { rem } from 'styles';

export const FileListItem = styled(FileCard)``;

export const StyledFileList = styled.div`
  ${({ theme }) => {
    const childSpacing = theme.spacing.x;

    return css`
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
      margin: -${rem(childSpacing)};
      transition: ${theme.transitions.fast};

      > * {
        margin: ${rem(childSpacing)};
      }
    `;
  }}
`;
