import styled, { css } from 'styled-components';
import { lighten, rgba } from 'polished';

import { Icon } from '../Icon';

import { shouldForwardProp } from 'utils';
import { em, eventsStyle } from 'styles';

export const ButtonBody = styled.span``;

export const ButtonIcon = styled(Icon)``;

export const buttonVariantStyles = {
  primary: ({ theme }) => css`
    color: ${theme.colors.light.hue};
    background: ${theme.colors.primary.hue};

    &:hover {
      color: ${theme.colors.light.hue};
      background: ${lighten(0.05, theme.colors.primary.hue)};
    }

    &:active {
      color: ${theme.colors.light.hue};
      background: ${theme.colors.primary.hue};
    }
  `,
  secondary: ({ theme }) => css`
    color: ${theme.colors.primary.hue};
    border-color: ${theme.colors.primary.hue};
    background: ${theme.colors.light.hue};

    &:hover {
      color: ${theme.colors.primary.hue};
      background: ${rgba(theme.colors.dark.hue, 0.025)};
    }

    &:active {
      color: ${theme.colors.primary.hue};
      background: ${theme.colors.light.hue};
    }
  `,
  tertiary: ({ theme, fontSize }) => css`
    color: ${theme.colors.light.hue};
    background: #00d95f;

    &:hover {
      color: ${theme.colors.light.hue};
      background: #00e865;
    }

    &:focus {
      box-shadow: ${theme.shadows.focus({ fontSize, color: '#00E865' })};
    }

    &:active {
      color: ${theme.colors.light.hue};
      background: #00d95f;
    }

    ${ButtonIcon} {
      svg {
        fill: currentColor;
      }
    }
  `
};

export const buttonStyle = ({
  theme,
  hasChildren,
  relativeFontSize,
  fontSize,
  variant,
  iconSize,
  isLoading
}) => [
  css`
    appearance: none;
    font-size: ${em(fontSize)};
    font-weight: ${theme.fontWeights.bold};
    color: inherit;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: ${theme.borders.default({ fontSize: relativeFontSize })};
    border-color: transparent;
    border-radius: ${em(theme.radius.x, relativeFontSize)};
    background: none;
    padding: 0;
    margin: 0;
    user-select: none;
    cursor: pointer;

    ${eventsStyle({ fontSize })};

    ${ButtonIcon} {
      font-size: ${em(theme.setFontSize(iconSize), relativeFontSize)};
    }
  `,
  isLoading && 'cursor: wait;',
  variant && buttonVariantStyles[variant],
  variant &&
    (hasChildren
      ? css`
          padding: ${em([theme.spacing.sx - 2, theme.spacing.ml], relativeFontSize)};
        `
      : css`
          padding: ${em([theme.spacing.sx, theme.spacing.sm], relativeFontSize)};
        `)
];

export const StyledButton = styled.button
  .withConfig({ shouldForwardProp })
  .attrs(({ theme, size }) => ({
    relativeFontSize: theme.fontSizes.sx,
    fontSize: theme.setFontSize(size)
  }))`
  ${buttonStyle};
`;
