import { pick } from 'lodash-es';

import {
  CONTACT_TYPES,
  MESSAGE_ACTIONS_KEYS_MAP,
  MESSAGE_ACTION_TEMPLATE,
  MESSAGE_ACTION_TYPES,
  MESSAGE_ACTION_TYPES_MODIFIED,
  MESSAGE_KEYS_MAP,
  MESSAGE_TEMPLATE
} from '_constants';
import { getCookieAuth } from './auth';

import {
  mergeObjects,
  normalizeKeysByMap,
  objectToURI,
  URItoObject,
  withMergeObjects
} from './helpers';

export const chatIsMessageModified = actionType => {
  return MESSAGE_ACTION_TYPES_MODIFIED.includes(actionType);
};

export const createMessageAction = (action = {}) => {
  return pick(mergeObjects(MESSAGE_ACTION_TEMPLATE, action), Object.keys(MESSAGE_ACTION_TEMPLATE));
};

export const normalizeMessageAction = (action = {}) => {
  const [getAction, setAction] = withMergeObjects();
  setAction(normalizeKeysByMap(action, MESSAGE_ACTIONS_KEYS_MAP));
  setAction(createMessageAction(getAction()), { hard: true });
  return getAction();
};

export const createMessage = (message = {}) => {
  return pick(mergeObjects(MESSAGE_TEMPLATE, message), Object.keys(MESSAGE_TEMPLATE));
};

export const normalizeMessage = (message = {}) => {
  const [getMessage, setMessage] = withMergeObjects();
  setMessage(normalizeKeysByMap(message, MESSAGE_KEYS_MAP));
  setMessage(URItoObject(getMessage().body));
  setMessage({ action: normalizeMessageAction(getMessage()) });
  setMessage(createMessage(getMessage()), { hard: true });
  setMessage({ isModified: chatIsMessageModified(getMessage().action.type) });

  return getMessage();
};

export const normalizeMessageForUI = (message, { users = {}, messages = {} }) => {
  const [getMessage, setMessage] = withMergeObjects(message);
  const user = users[message.userId];
  const actionUser = users[message.action.authorId];

  if (user) setMessage({ user });
  if (actionUser) setMessage({ action: { authorUserName: actionUser.username } });

  if (message.action.prevMessageId && Object.keys(messages)?.length) {
    const prevMessage = messages[message.action.prevMessageId];
    if (prevMessage) setMessage({ prevMessage: normalizeMessageForUI(prevMessage, { users }) });
  }

  return getMessage();
};

const requestMessageKeys = ['id', 'body', 'chatId', 'contactId', 'contactType'];

export const createRequestMessage = (message = {}) => {
  const auth = getCookieAuth();

  const [getMessage, setMessage] = withMergeObjects({
    text: '',
    body: '',
    contactId: '',
    actionType: MESSAGE_ACTION_TYPES.SEND,
    actionDate: new Date().toJSON(),
    actionAuthorId: auth.userId,
    prevMessageId: '',
    prevMessageAuthorId: '',
    ...message
  });

  if (getMessage().actionType === MESSAGE_ACTION_TYPES.SEND) {
    setMessage({ contactType: message.contactType });
  }

  if (getMessage().actionType === MESSAGE_ACTION_TYPES.EDIT) {
    setMessage({ id: message.id });
  }

  setMessage({
    body: objectToURI(
      pick(getMessage(), [
        'actionType',
        'actionDate',
        'actionAuthorId',
        'text',
        'prevMessageId',
        'prevMessageAuthorId'
      ])
    )
  });

  if (message.contactType === CONTACT_TYPES.GROUP) {
    setMessage({ chatId: message.chatId, contactId: '' });
  }

  return [pick(getMessage(), requestMessageKeys), getMessage()];
};

export const createResponseMessage = (response, message = {}) => {
  return {
    ...response,
    ChatID: '' || message.chatId,
    AttachmentGUID: response.AttachmentGUID || '',
    Body: response.Body || response.MessageBody || message.body || '',
    DateTime: response.DateTime || '',
    Deleted: response.Deleted || false,
    Edited: response.Edited || false,
    MessageGUID: response.MessageID || response.MessageGUID || message.id || '',
    Status: response.Status || '',
    UserGUID: response.UserGUID || response.UserID || message.userId || ''
  };
};
