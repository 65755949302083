import styled, { css } from 'styled-components';

import { Modal, ModalBody, ModalContainer, ModalFooter } from 'components/_shared/Modal';
import {
  ContactCardStatusIndicator,
  ContactListBody,
  ContactListItem,
  ContactListSearch
} from 'components/Contact';

import { overlapContainer, rem } from 'styles';

export const StyledGroupUsersModalAdd = styled(Modal)`
  ${({ theme }) => css`
    ${ModalContainer} {
      width: ${rem(458)};
    }

    ${ModalBody} {
      margin-left: -${rem(theme.spacing.ml)};
      margin-right: -${rem(theme.spacing.ml)};
    }

    ${ModalFooter} {
      position: relative;

      &::before {
        content: '';
        ${overlapContainer('absolute')};
        top: -${rem(theme.spacing.ml)};
        max-width: ${`calc(100% + ${rem(theme.spacing.ml * 2)})`};
        height: ${rem(1)};
        background: ${theme.colors.primary.tint};
      }
    }

    ${ContactListSearch} {
      width: ${`calc(100% - ${rem(theme.spacing.ml / 2)})`};
      margin: 0 auto;
    }

    ${ContactListBody} {
      height: ${rem(376)};
      overflow-x: hidden;
      overflow-y: auto;
    }

    ${ContactListItem} {
      padding-left: ${rem(theme.spacing.ml)};
      padding-right: ${rem(theme.spacing.ml)};
    }

    ${ContactCardStatusIndicator} {
      display: none;
    }
  `}
`;
