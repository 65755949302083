import styled, { css } from 'styled-components';

import { Button } from 'components/_shared/Button';
import { Typography } from 'components/_shared/Typography';

import { rem } from 'styles';
import { rgba } from 'polished';

export const FileViewerPDFNavCount = styled(Typography).attrs({ element: 'p', size: 'xs' })`
  flex: 0 0 auto;
`;

export const FileViewerPDFNavButton = styled(Button).attrs({ size: 'xs' })`
  ${({ theme }) => css`
    font-weight: ${theme.fontWeights.semiBold};
    flex: 1;

    &:hover {
      background: none;
    }

    &:disabled {
      color: ${theme.colors.dark.tint};
    }
  `}
`;

export const FileViewerPDFNavButtonNext = styled(FileViewerPDFNavButton)``;

export const FileViewerPDFNavButtonPrevious = styled(FileViewerPDFNavButton)``;

export const FileViewerPDFNavButtonDownload = styled(FileViewerPDFNavButton)`
  transform: translateY(${rem(-1)});
`;

export const FileViewerPDFNav = styled.nav`
  ${({ theme }) => {
    const childSpacing = theme.spacing.x;

    return css`
      position: absolute;
      left: 50%;
      bottom: -25%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: ${rem(theme.spacing.x)};
      background: ${rgba(theme.colors.light.hue, 1)};
      padding: ${rem([theme.spacing.xs, theme.spacing.sm])};
      margin: -${rem(childSpacing)};
      transform: translateX(-50%);
      box-shadow: ${theme.shadows.default()};
      transition: ${theme.transitions.medium};
      opacity: 0;

      > * {
        margin: ${rem(childSpacing)};
      }
    `;
  }}
`;

export const StyledFileViewerPDF = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      ${FileViewerPDFNav} {
        bottom: ${rem(theme.spacing.ml)};
        opacity: 0.8;

        &:hover {
          opacity: 1;
        }
      }
    }
  `}
`;
