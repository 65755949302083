import React from 'react';
import { useHistory } from 'react-router';
import { FiChevronLeft } from 'react-icons/fi';

import {
  StyledView,
  ViewBody,
  ViewHeader,
  ViewHeaderActionBack,
  ViewHeaderProfileAvatar,
  ViewHeaderProfileHeading,
  ViewHeaderMenu,
  ViewActions
} from './View.styles';

import { goBackWithHistory } from 'utils';

export const View = ({
  children,
  heading,
  avatar = false,
  menu,
  actions,
  onBackClick,
  onProfileClick,
  ...props
}) => {
  const history = useHistory();

  const handleActionBackClick = () => {
    if (!onBackClick) return goBackWithHistory(history);
    onBackClick();
  };

  return (
    <StyledView {...props}>
      <ViewHeader>
        {onBackClick !== false && (
          <ViewHeaderActionBack icon={<FiChevronLeft />} onClick={handleActionBackClick} />
        )}

        {avatar !== false && (
          <ViewHeaderProfileAvatar
            src={avatar}
            alt={`${heading} avatar`}
            onClick={onProfileClick}
          />
        )}

        {heading && (
          <ViewHeaderProfileHeading onClick={onProfileClick}>{heading}</ViewHeaderProfileHeading>
        )}

        {actions && <ViewActions>{actions}</ViewActions>}

        {menu && <ViewHeaderMenu>{menu}</ViewHeaderMenu>}
      </ViewHeader>

      <ViewBody>{children}</ViewBody>
    </StyledView>
  );
};
