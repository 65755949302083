import styled, { css } from 'styled-components';
import { darken } from 'polished';

import { FileList, StyledFileCard } from 'components/_shared/File';
import { Button } from 'components/_shared/Button';

import { em, rem } from 'styles';

export const EditorFilesActionRemove = styled(Button)`
  ${({ theme, size }) => {
    const fontSize = theme.fontSizes[size];

    return css`
      position: absolute;
      top: 25%;
      right: 0;
      opacity: 0;
      pointer-events: none;
      border-radius: 100%;
      background: ${darken(0.05, theme.colors.light.shade)};
      padding: ${em(theme.spacing.x / 2, fontSize)};
      transform: translate(50%, -50%);
    `;
  }}
`;

export const StyledEditorFiles = styled(FileList)`
  ${({ theme }) => {
    const focusStyle = css`
      top: 0;
      opacity: 1;
      pointer-events: all;
      transform: translate(50%, -50%);
    `;

    return css`
      padding: ${rem(theme.spacing.sx)};

      ${StyledFileCard} {
        &:hover {
          ${EditorFilesActionRemove} {
            ${focusStyle};
          }
        }

        ${EditorFilesActionRemove}:focus {
          ${focusStyle};
        }
      }
    `;
  }}
`;
