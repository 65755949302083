import styled, { css } from 'styled-components';
import cn from 'classnames';
import { lighten, rgba } from 'polished';

import { Avatar } from 'components/_shared/Avatar';
import { Typography } from 'components/_shared/Typography';
import { StyledMessageDate } from './Date';
import { StyledMessageReadMark } from './ReadMark';
import { tagContactStyle } from 'components/Tag';

import { em } from 'styles';
import { Button } from 'components/_shared/Button';

export const MessageCardTooltip = styled(Typography).attrs({ element: 'p', size: 'x' })`
  ${({ theme, size }) => css`
    color: ${theme.colors.light.hue};
    position: absolute;
    top: 100%;
    left: 0;
    white-space: nowrap;
    border-radius: ${em(theme.radius.x, theme.fontSizes[size])};
    background: ${rgba(theme.colors.dark.hue, 0.4)};
    padding: ${em([theme.spacing.x, theme.spacing.xs], theme.fontSizes[size])};
    z-index: ${theme.zIndexes.xs};
    opacity: 0;
  `}
`;

export const MessageCardText = styled.div.attrs(({ className }) => ({
  className: cn(className, 'ql-editor')
}))`
  ${({ theme }) => css`
    ${tagContactStyle};

    &.ql-editor {
      font-size: ${em(theme.fontSizes.xs)};
      padding: 0;
    }
  `}
`;

export const MessageCardBody = styled.div`
  padding-left: ${em(40)};
  margin-top: ${em(-10)};
`;

export const MessageCardHeaderAction = styled(Button).attrs({ size: 'x' })`
  ${({ theme }) => css`
    font-weight: ${theme.fontWeights.semiBold};

    &:hover,
    &:active {
      color: ${theme.colors.primary.hue};
      background: none;
    }

    &:focus {
      box-shadow: none;
    }
  `}
`;

export const MessageCardHeaderActionCancel = styled(MessageCardHeaderAction)`
  ${({ theme }) => css`
    color: ${theme.colors.danger.hue};

    &:hover {
      color: ${lighten(0.18, theme.colors.danger.hue)};
    }

    &:active {
      color: ${theme.colors.danger.hue};
    }
  `}
`;

export const MessageCardHeaderActionResend = styled(MessageCardHeaderAction)`
  ${({ theme }) => css`
    color: ${theme.colors.primary.hue};

    &:hover {
      color: ${lighten(0.18, theme.colors.primary.hue)};
    }

    &:active {
      color: ${theme.colors.primary.hue};
    }
  `}
`;

export const MessageCardHeaderActions = styled.div`
  ${({ theme }) => {
    const childSpacing = theme.spacing.x / 2;

    return css`
      display: flex;
      align-items: center;
      align-self: center;
      padding-top: ${em(2)};
      margin: -${em(childSpacing)};
      margin-left: ${em(theme.spacing.xs - childSpacing)};

      > * {
        margin: ${em(childSpacing)};
      }
    `;
  }}
`;

export const MessageCardAuthorName = styled(Typography).attrs({ element: 'h4', size: 'xs' })`
  ${({ theme }) => css`
    color: ${theme.colors.dark.hue};
  `}
`;

export const MessageCardMeta = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: ${em(-2)};
  overflow: hidden;
`;

export const MessageCardAuthorAvatar = styled(Avatar)`
  ${({ theme }) => [
    css`
      flex: 0 0 auto;
      margin-right: ${em(theme.spacing.xs)};
    `
  ]}
`;

export const MessageCardHeader = styled.header`
  display: flex;
  align-items: flex-start;
`;

export const StyledMessageCard = styled.div`
  ${({ theme, isModified, isFocused, isSelected, isNavigated, isPrevMessage, onClick }) => {
    const backgroundColorActive = theme.colors.message.card.active;

    const modifiedStyle = css`
      ${MessageCardText} {
        text-decoration: line-through;
      }
    `;

    const focusStyle = css`
      background: ${backgroundColorActive};
      outline: none;
    `;

    const navigatedStyle = css`
      border-color: ${theme.colors.primary.hue};
      background: ${backgroundColorActive};
    `;

    const prevMessageStyle = [
      css`
        height: ${em(44)};
        border-radius: 0;
        background: none;
        padding: 0;
        padding-left: ${em(theme.spacing.xs)};
        margin: ${em([theme.spacing.xs, 0])};
        overflow: hidden;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: ${em(1.6)};
          height: 100%;
          background: ${theme.colors.dark.tint};
        }

        ${MessageCardAuthorAvatar} {
          display: none;
        }

        ${MessageCardAuthorName} {
          color: ${theme.colors.dark.tone};

          &.onClick:hover {
            color: ${theme.colors.dark.tone};
          }
        }

        ${MessageCardMeta} {
          margin-top: 0;
        }

        ${StyledMessageDate} {
          display: none;
        }

        ${StyledMessageReadMark} {
          display: none;
        }

        ${MessageCardBody} {
          padding: 0;
          margin-top: ${em(theme.spacing.x)};
        }

        ${MessageCardText} {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      `,
      onClick &&
        css`
          &,
          * {
            cursor: pointer;
          }
        `
    ];

    return [
      css`
        position: relative;
        border: ${theme.borders.default()};
        border-color: transparent;
        border-radius: ${em(theme.radius.sx)};
        background: ${theme.colors.light.hue};
        padding: ${em(theme.spacing.sm)};
        scroll-margin: ${em([theme.spacing.lx, 0])};
        transition: ${theme.transitions.medium};

        &:focus {
          outline: none;
        }
      `,
      isModified && modifiedStyle,
      isNavigated && navigatedStyle,
      !isPrevMessage && (isFocused || isSelected) && focusStyle,
      isPrevMessage && prevMessageStyle,
      !isPrevMessage &&
        isFocused &&
        css`
          ${MessageCardTooltip} {
            ${theme.animations.fadeInSlideDown};
            animation-delay: 2s;
          }
        `
    ];
  }}
`;
