import { cloneElement } from 'react';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import cn from 'classnames';

import { USER_STATUSES } from '_constants';

import { Typography } from 'components/_shared/Typography';
import { Icon } from 'components/_shared/Icon';
import { Avatar } from 'components/_shared/Avatar';

import { em } from 'styles';

export const ContactCardAction = styled(({ children: child, ...props }) => {
  const className = cn(child.props.className, props.className);
  return cloneElement(child, { ...props, className });
})``;

export const ContactCardActions = styled.div`
  ${({ theme }) => {
    const childSpacing = theme.spacing.x;

    return css`
      display: flex;
      align-items: center;
      justify-content: center;
      margin: -${em(childSpacing)};
      margin-left: auto;

      ${ContactCardAction} {
        margin: ${em(childSpacing)};
      }
    `;
  }}
`;

export const ContactCardName = styled(Typography).attrs({ element: 'h6', size: 'sx' })`
  ${({ theme }) => css`
    font-weight: ${theme.fontWeights.medium};
  `}
`;

export const ContactCardStatusIndicator = styled(Icon)`
  ${({ theme, status }) => [
    css`
      color: ${rgba(theme.colors.light.hue, 0.8)};
      position: absolute;
      right: 0;
      bottom: 0;
      width: ${em(10)};
      transform: scaleX(-1) translate(-25%, 25%);
    `,
    status === USER_STATUSES.ONLINE && `color: ${theme.colors.secondary.hue};`,
    (status === USER_STATUSES.REQUESTED || status === USER_STATUSES.WAITING) &&
      `color: ${theme.colors.warning.hue};`,
    status === USER_STATUSES.AVAILABLE && `display: none;`
  ]}
`;

export const ContactCardAvatar = styled(Avatar)`
  ${({ theme }) => css`
    position: relative;
    margin-right: ${em(theme.spacing.xs)};
    overflow: visible;
  `}
`;

export const StyledContactCard = styled.div`
  ${({ onClick }) => [
    css`
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    `,
    onClick &&
      css`
        cursor: pointer;
      `
  ]}
`;
