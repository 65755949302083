import styled, { css } from 'styled-components';

import { StyledLogo } from 'components/Logo';
import { Typography } from 'components/_shared/Typography';
import { Link, LinkBody } from 'components/_shared/Link';

import { eventsStyle, rem } from 'styles';

export const SidebarHeaderLink = styled(Link)`
  ${({ theme }) => css`
    border-radius: ${rem(theme.radius.x)};
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
      text-decoration: none;

      ${eventsStyle({ variant: 'secondary', withHover: false, withActive: false })}
    }

    ${LinkBody} {
      display: flex;
      align-items: center;
    }
  `}
`;

export const SidebarHeaderHeading = styled(Typography).attrs({ element: 'h1', size: 'sx' })``;

export const StyledSidebarHeader = styled.header`
  ${({ theme }) => css`
    flex-shrink: 0;
    background: ${theme.colors.primary.tone};
    padding: ${rem(theme.spacing.sm)};

    ${StyledLogo} {
      margin-right: ${rem(theme.spacing.xs)};
    }
  `}
`;
