import React from 'react';
import { FiUser } from 'react-icons/fi';

import { StyledAvatar, AvatarImage, AvatarIcon } from './Avatar.styles';

export const Avatar = ({ children, src, alt: initialAlt, ...props }) => {
  const hasImage = !!src;
  const alt = initialAlt || 'Avatar';

  return (
    <StyledAvatar {...props} hasImage={hasImage}>
      {src ? (
        <AvatarImage src={src} alt={alt} />
      ) : (
        <AvatarIcon title={alt} size={22}>
          <FiUser />
        </AvatarIcon>
      )}

      {children}
    </StyledAvatar>
  );
};
