import React, { useRef, useImperativeHandle, forwardRef, useMemo, memo, useCallback } from 'react';

import { StyledEditor, EditorField, EditorForm } from './Editor.styles';

import { EditorToolbar } from './Toolbar';

import { mergeProps, withFunction, submitForm } from 'utils';
import { useControllableState } from 'hooks';
import { EditorFiles } from './Files';

export const Editor = memo(
  forwardRef(
    (
      {
        children,
        defaultValue = '',
        value: initialValue,
        defaultFiles = [],
        files: initialFiles,
        placeholder = 'Enter message...',
        actions = [],
        actionsProps = {},
        customActions = [],
        onChange,
        onFilesChange,
        onSubmit,
        ...props
      },
      ref
    ) => {
      const rootRef = useRef({});
      const formRef = useRef(null);

      const [value, setValue] = useControllableState({
        defaultValue,
        initialValue,
        onChange
      });

      const [files, setFiles] = useControllableState({
        defaultValue: defaultFiles,
        initialValue: initialFiles,
        onChange: onFilesChange
      });

      useImperativeHandle(ref, () => {
        rootRef.current.value = value;
        rootRef.current.setValue = setValue;
        rootRef.current.files = files;
        rootRef.current.setFiles = setFiles;
        return rootRef.current;
      });

      const handleChange = useCallback(
        (...args) => {
          const [value] = args;
          setValue(value);
          onChange?.(...args);
        },
        [onChange, setValue]
      );

      const handleSubmit = useCallback(() => onSubmit?.(value), [value, onSubmit]);

      const handleFileRemove = useCallback(
        file => {
          setFiles(prevFiles => {
            const fileIndex = prevFiles.indexOf(file);
            return prevFiles.filter((_, i) => i !== fileIndex);
          });
        },
        [setFiles]
      );

      const getActionsProps = useCallback(
        (...args) => {
          const defaultProps = { submit: { onClick: handleSubmit } };
          const props = withFunction(actionsProps, ...args);
          return mergeProps(defaultProps, props);
        },
        [actionsProps, handleSubmit]
      );

      const memoizedModules = useMemo(
        () => ({
          toolbar: { container: '#toolbar' },
          keyboard: {
            bindings: {
              custom: {
                key: 'enter',
                handler: () => {
                  submitForm(formRef.current);
                }
              }
            }
          }
        }),
        []
      );

      return (
        <StyledEditor {...props}>
          <EditorField
            ref={rootRef}
            value={value}
            placeholder={placeholder}
            onChange={handleChange}
            modules={memoizedModules}
          />

          <EditorFiles files={files} onRemove={handleFileRemove} />

          <EditorToolbar
            id='toolbar'
            editorRef={rootRef.current}
            actions={actions}
            customActions={customActions}
            actionsProps={getActionsProps()}
          />

          {withFunction(children, rootRef.current)}

          <EditorForm ref={formRef} onSubmit={handleSubmit} />
        </StyledEditor>
      );
    }
  )
);
