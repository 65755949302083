import styled, { css } from 'styled-components';
import { darken } from 'polished';

import { Typography } from 'components/_shared/Typography';

import { shouldForwardProp } from 'utils';
import { em, rem } from 'styles';

export const StyledIndicatorCount = styled(Typography)
  .withConfig({ shouldForwardProp })
  .attrs({ element: 'p', size: 'x' })`
  ${({ theme, size, variant }) => {
    const fontSize = theme.fontSizes[size];

    const variantStyles = {
      warning: css`
        color: ${theme.colors.light.hue};
        background: ${darken(0.18, theme.colors.warning.hue)};
      `,
      danger: css`
        color: ${theme.colors.light.hue};
        background: ${theme.colors.danger.hue};
      `
    };

    return [
      css`
        font-size: ${rem(fontSize)};
        font-weight: ${theme.fontWeights.bold};
        text-align: center;
        color: ${theme.colors.indicator.count.foreground};
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: ${em(18, fontSize)};
        height: ${em(18, fontSize)};
        border-radius: ${em(100, fontSize)};
        background: ${theme.colors.indicator.count.background};
        padding: ${em([1, 5], fontSize)};
      `,
      variant && variantStyles[variant]
    ];
  }}
`;
