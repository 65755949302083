import styled, { css } from 'styled-components';

import { shouldForwardProp } from 'utils';
import { em } from 'styles';

export const StyledIcon = styled.span.withConfig({ shouldForwardProp })`
  ${({ theme, size: initialSize, position }) => {
    const size = theme.setFontSize(initialSize);
    const spacing = em(theme.spacing.x, size);

    const positionStyles = {
      left: css`
        + * {
          right: 0;
          order: 1;
          padding-right: ${spacing};
          margin-left: ${spacing};
        }
      `,
      right: css`
        + * {
          left: 0;
          order: -1;
          padding-left: ${spacing};
          margin-right: ${spacing};
        }
      `
    };

    return [
      css`
        font-size: ${em(size)};
        color: inherit;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1em;
        max-width: 100%;
        height: auto;

        > * {
          width: 100%;
          max-width: 100%;
          height: inherit;
        }

        > img {
          object-fit: cover;
        }
      `,
      position && positionStyles[position]
    ];
  }}
`;
