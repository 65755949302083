import React from 'react';

import { StyledSidebarSearch } from './SidebarSearch.styles';

import { useSidebarContext } from '../Sidebar.context';

export const SidebarSearch = ({ ...props }) => {
  const { searchQuery, handleSearchQueryChange } = useSidebarContext();

  return (
    <StyledSidebarSearch
      {...props}
      value={searchQuery}
      variant='secondary'
      onChange={handleSearchQueryChange}
    />
  );
};
