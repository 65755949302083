export const AUTH_KEY = 'auth';

export const AUTH_KEYS = {
  USER_ID: 'userId',
  USERNAME: 'username',
  PASSWORD: 'password',
  EMAIL: 'email',
  SESSION_ID: 'sessionId',
  IS_TOKEN_DEFAULT: 'isTokenDefault',
  IS_AUTHORIZED: 'isAuthorized',
  SHOULD_REPEAT_SIGN_IN: 'shouldRepeatSignIn'
};

export const AUTH_KEYS_MAP = {
  [AUTH_KEYS.USER_ID]: ['UserID'],
  [AUTH_KEYS.SESSION_ID]: ['SessionID'],
  [AUTH_KEYS.IS_TOKEN_DEFAULT]: ['IsTokenDefault']
};

export const AUTH_TEMPLATE = {
  [AUTH_KEYS.USER_ID]: '',
  [AUTH_KEYS.SESSION_ID]: '',
  [AUTH_KEYS.IS_TOKEN_DEFAULT]: false,
  [AUTH_KEYS.IS_AUTHORIZED]: false,
  [AUTH_KEYS.SHOULD_REPEAT_SIGN_IN]: false
};

export const AUTH_METHODS = {
  SIGN_IN: 'signIn',
  SIGN_UP: 'signUp',
  SIGN_OUT: 'signOut'
};
