import { createElement, forwardRef } from 'react';
import styled, { css } from 'styled-components';
import ReactQuill from 'react-quill';

import { rem } from 'styles';

export const EditorField = styled(
  forwardRef((props, ref) => createElement(ReactQuill, { ...props, ref }))
)`
  ${({ theme }) => css`
    flex: 1;
    font-size: ${rem(theme.fontSizes.xs)};
    border-top: ${theme.borders.default({ fontSize: theme.fontSizes.xs })};
    background: ${theme.colors.light.hue};
  `}
`;

export const EditorForm = styled.form`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 0;
  height: 0;
  opacity: none;
  z-index: -1;
`;

export const StyledEditor = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    background: ${theme.colors.light.hue};

    .ql {
      &-container,
      &-editor {
        height: 100%;
        max-height: 100%;
      }

      &-editor {
        max-height: 50vh;
        word-break: break-word;
        padding: ${rem(theme.spacing.sm)};
        /* overflow-x: hidden; */
        /* overflow-y: auto; */
      }
    }
  `}
`;
