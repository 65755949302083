import styled, { css } from 'styled-components';

import { Button } from 'components/_shared/Button';

import { shouldForwardProp } from 'utils';
import { em } from 'styles';

export const TabsItem = styled.div`
  ${({ isVisible }) => ['display: none;', isVisible && 'display: block;']}
`;

export const TabsBody = styled.div`
  position: relative;
`;

export const TabsLabel = styled(Button).attrs({ size: 'xs' })`
  ${({ theme, size, isActive }) => {
    const fontSize = theme.fontSizes[size];
    const underlineRadius = em(3, fontSize);

    return [
      css`
        font-weight: ${theme.fontWeights.medium};
        color: inherit;
        position: relative;
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
        padding-bottom: ${em(theme.spacing.xs + 3, fontSize)};
        opacity: 0.6;

        &:hover,
        &:focus,
        &:active {
          color: inherit;
          background: none;
          box-shadow: none;
        }
      `,
      isActive &&
        css`
          font-weight: ${theme.fontWeights.bold};
          opacity: 1;

          &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: -${em(2, fontSize)};
            width: 100%;
            height: ${em(3, fontSize)};
            border-radius: ${underlineRadius} ${underlineRadius} 0 0;
            background: currentColor;
          }
        `
    ];
  }}
`;

export const TabsHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    &::after {
      content: '';
      width: 100%;
      height: ${em(1)};
      border-radius: ${em(theme.radius.x)};
      background: currentColor;
      opacity: 0.2;
    }
  `}
`;

export const StyledTabs = styled.div.withConfig({ shouldForwardProp })`
  position: relative;
`;
