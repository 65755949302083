import styled from 'styled-components';

import { Editor, StyledEditorActionEmoji, StyledEditorToolbar } from 'components/_shared/Editor';
import { StyledMessagePreview } from '../Preview';

export const StyledMessageEditor = styled(Editor)`
  ${StyledMessagePreview} {
    position: absolute;
    bottom: 100%;
    left: 0;
  }

  ${StyledEditorToolbar} {
    padding-right: 0;
  }

  ${StyledEditorActionEmoji} {
    margin-left: auto;
  }
`;
