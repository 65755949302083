import { rgba } from 'polished';
import styled, { css } from 'styled-components';
import { em } from 'styles';

import { Button } from '../Button';
import { Form, FormActions } from '../Form';
import { Input } from '../Input';

export const SearchAction = styled(Button).attrs({ iconSize: 's' })`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.6;

  && {
    margin: 0;
  }

  &:hover,
  &:focus {
    opacity: 1;
  }
`;

export const SearchActionSubmit = styled(SearchAction)`
  ${({ theme, size }) => css`
    left: ${em(theme.spacing.xs, theme.fontSizes[size])};
  `}
`;

export const SearchActionClear = styled(SearchAction)`
  ${({ theme, size }) => css`
    right: ${em(theme.spacing.xs, theme.fontSizes[size])};
  `}
`;

export const SearchInput = styled(Input)`
  ${({ theme }) => {
    const fontSize = theme.fontSizes.xs;

    return css`
      font-size: ${em(fontSize)};
      padding: ${em([theme.spacing.x + 1, theme.spacing.xs + 24], fontSize)};
    `;
  }}
`;

export const StyledSearch = styled(Form)`
  ${({ theme, variant }) => [
    css`
      ${FormActions} {
        margin: 0;
      }
    `,
    variant === 'secondary' &&
      css`
        ${SearchInput} {
          background: ${rgba(theme.colors.light.hue, 0.17)};
        }
      `
  ]}
`;
