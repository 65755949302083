import styled, { css } from 'styled-components';

import { shouldForwardProp } from 'utils';
import { em } from 'styles';

export const FormGroupItem = styled.div`
  flex: 1;
  max-width: 100%;
`;

export const StyledFormGroup = styled.div.withConfig({ shouldForwardProp })`
  ${({ theme, direction }) => {
    const childSpacing = theme.spacing.sx;

    const directionStyles = {
      row: css`
        flex-direction: row;
      `,
      column: css`
        flex-direction: column;
      `
    };

    return [
      css`
        display: flex;
        flex-wrap: wrap;
        margin: -${em(childSpacing)};

        &:not(:first-of-type) {
          margin-top: ${em(theme.spacing.lm - childSpacing)};
        }

        ${FormGroupItem} {
          margin: ${em(childSpacing)};
        }
      `,
      direction && directionStyles[direction]
    ];
  }}
`;
