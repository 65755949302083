import React, { useRef } from 'react';
import { mergeProps } from 'utils';

import { MessageListItem, StyledMessageList } from './MessageList.styles';

export const MessageList = ({ messages = [], messageProps, ...props }) => {
  const lastMessageId = messages[messages.length - 1]?.id;

  const rootRef = useRef(null);
  const messageRefs = useRef(new Map());
  const prevLastMessageId = useRef(lastMessageId);

  const shouldScrollDown = prevLastMessageId.current !== lastMessageId;

  if (shouldScrollDown) {
    const wrapper = rootRef.current?.wrapperRef.current;

    if (wrapper) {
      wrapper.scrollTop = wrapper.scrollHeight;
      prevLastMessageId.current = lastMessageId;
    }
  }

  const handlePrevMessageClick = prevMessage => {
    const messageRef = messageRefs.current.get(prevMessage.id);
    messageRef.navigate();
  };

  const renderMessages = () => {
    return messages.map(message => {
      const props = messageProps?.(message) || {};

      const mergedProps = mergeProps(message, [
        message.prevMessage && {
          prevMessage: { onClick: () => handlePrevMessageClick(message.prevMessage) }
        },
        props
      ]);

      return (
        <MessageListItem
          {...mergedProps}
          ref={ref => messageRefs.current.set(message.id, ref)}
          key={message.id}
        />
      );
    });
  };

  return (
    <StyledMessageList {...props} ref={rootRef}>
      {renderMessages()}
    </StyledMessageList>
  );
};
