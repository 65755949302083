import styled from 'styled-components';

import { shouldForwardProp } from 'utils';
import { rem } from 'styles';

export const LayoutMain = styled.main`
  position: relative;
  flex: 1;
`;

export const LayoutSidebar = styled.aside`
  flex: 0 0 ${rem(300)};
`;

export const StyledLayout = styled.div.withConfig({ shouldForwardProp })`
  display: flex;
  min-width: ${rem(320)};
  min-height: 100vh;
  /* overflow-x: hidden; */
  /* overflow-y: auto; */
  overflow: hidden;
`;
