import { cloneElement, createElement, isValidElement, memo } from 'react';
import styled from 'styled-components';
import cn from 'classnames';

export const InputFileInput = styled.input`
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
`;

export const InputFileButton = styled(
  memo(({ children: child, ...props }) => {
    if (!isValidElement(child)) return createElement(child, props);
    const className = cn(child.props.className, props.className);
    return cloneElement(child, { ...props, className });
  })
)``;

export const StyledInputFile = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;
