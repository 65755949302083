import styled, { css } from 'styled-components';

import { Icon } from '../Icon';

import { em, eventsStyle } from 'styles';

export const SelectArrow = styled(Icon)`
  ${({ theme }) => css`
    position: absolute;
    right: ${em(theme.spacing.sm)};
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  `}
`;

export const StyledSelect = styled.select`
  ${({ theme }) => {
    const fontSize = theme.fontSizes.xs;

    return css`
      appearance: none;
      font-size: ${em(fontSize)};
      font-weight: ${theme.fontWeights.medium};
      color: inherit;
      width: 100%;
      max-width: 100%;
      border: ${theme.borders.default(fontSize)};
      border-radius: ${em(theme.radius.x, fontSize)};
      padding: ${em([theme.spacing.sx, theme.spacing.sm], fontSize)};
      transition: ${theme.transitions.default};

      &:hover,
      &:focus {
        + ${SelectArrow} {
          color: ${theme.colors.primary.hue};
        }
      }

      &:hover {
        border-color: ${theme.colors.primary.hue};
        cursor: pointer;
      }

      ${eventsStyle({ fontSize, withHover: false, withActive: false })}
    `;
  }}
`;

export const SelectWrapper = styled.div`
  position: relative;
  width: 100%;
`;
