import { pick } from 'lodash-es';

import {
  CONTACT_TYPES,
  GROUP_KEYS,
  GROUP_KEYS_MAP,
  GROUP_PROFILE_KEYS,
  GROUP_TEMPLATE
} from '_constants';

import { mergeObjects, normalizeKeysByMap, withMergeObjects } from './helpers';

export const createGroup = (group = {}) => {
  return pick(mergeObjects(GROUP_TEMPLATE, group), Object.keys(GROUP_TEMPLATE));
};

export const normalizeGroup = (group = {}, params = {}) => {
  const { pickKeys = false, normalizeKeysOnly = false } = params;
  const [getGroup, setGroup] = withMergeObjects();

  setGroup(normalizeKeysByMap(group, GROUP_KEYS_MAP));
  const normalizedKeys = Object.keys(getGroup());

  if (normalizeKeysOnly) return getGroup();

  setGroup(createGroup(getGroup()));

  if (pickKeys) {
    setGroup(pick(getGroup(), normalizedKeys), { hard: true });
  }

  return getGroup();
};

export const isGroup = contact => contact[GROUP_KEYS.CONTACT_TYPE] === CONTACT_TYPES.GROUP;

export const pickGroupProfileFields = group => {
  return pick(group, GROUP_PROFILE_KEYS);
};

export const formatGroupProfileLabel = key => {
  const keyFormats = {
    [GROUP_KEYS.EMAIL_PERSONAL]: 'Personal',
    [GROUP_KEYS.EMAIL_BUSINESS]: 'Business'
  };

  return keyFormats[key] || key;
};
