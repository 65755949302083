import React, { useEffect, useState } from 'react';
import { Switch } from 'react-router';
import { useSelector } from 'react-redux';

import { StyledPageMain } from './PageMain.styles';

import { AUTH_KEY, AUTH_KEYS, ROUTES } from '_constants';

import { Welcome } from 'components/Welcome';
import { ChatView } from 'components/Chat';
import { GroupAddView, GroupProfileView } from 'components/Group';
import { SettingsView } from 'components/Settings';
import { UserProfileView } from 'components/User';
import { PagePreloader } from 'components/_shared/Page';

import { useAuthActions, useGroupActions, useUserActions } from 'state';
import { SocketController } from 'socket';
import { Route } from 'routes';
import { isFunction } from 'formik';
import { PasswordChangeModal } from 'components/Password';

export const PageMain = () => {
  const [isLoading, setIsLoading] = useState(true);

  const { reAuthorize } = useAuthActions();
  const { getUsers } = useUserActions();
  const { getGroups } = useGroupActions();

  const shouldRepeatSignIn = useSelector(state => state[AUTH_KEY][AUTH_KEYS.SHOULD_REPEAT_SIGN_IN]);
  const isTokenDefault = useSelector(state => state.auth.isTokenDefault);

  useEffect(() => {
    shouldRepeatSignIn && reAuthorize();
  }, [shouldRepeatSignIn, reAuthorize]);

  useEffect(() => {
    if (shouldRepeatSignIn) return;

    Promise.all([getUsers(), getGroups()]).then(callbacks => {
      callbacks.forEach(callback => isFunction(callback) && callback());
      setIsLoading(false);
    });
  }, [shouldRepeatSignIn, getUsers, getGroups]);

  if (isLoading) return <PagePreloader delay={150} isTransparent />;

  return (
    <StyledPageMain>
      {!shouldRepeatSignIn && <SocketController />}

      {isTokenDefault ? (
        <PasswordChangeModal isSafe={false} />
      ) : (
        <Switch>
          <Route isPrivate exact path={ROUTES.ROOT} component={Welcome} />
          <Route isPrivate path={ROUTES.GROUP_ADD} component={GroupAddView} />
          <Route isPrivate path={ROUTES.GROUP_BY_ID()} component={GroupProfileView} />
          <Route isPrivate path={ROUTES.USER_BY_ID()} component={UserProfileView} />
          <Route isPrivate path={ROUTES.CHAT_BY_CONTACT_ID()} component={ChatView} />
          <Route isPrivate path={ROUTES.SETTINGS} component={SettingsView} />
        </Switch>
      )}
    </StyledPageMain>
  );
};
