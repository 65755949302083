import React from 'react';
import * as Yup from 'yup';

import {
  AuthFormActions,
  AuthFormLinkPasswordForgot,
  AuthFormRedirectMessage,
  AuthFormRedirectMessageText,
  StyledAuthForm
} from './AuthForm.styles';

import { AUTH_KEYS, AUTH_METHODS, ROUTES } from '_constants';

import { FormField, FormGroup } from 'components/_shared/Form';
import { Button } from 'components/_shared/Button';

const FORMS_CONFIG = {
  signIn: {
    title: 'Sign In',
    initialValues: { [AUTH_KEYS.EMAIL]: '', [AUTH_KEYS.PASSWORD]: '' },
    redirectMessage: 'Don’t have account ',
    redirectLinkTitle: 'Sign Up'
  },
  signUp: {
    title: 'Sign Up',
    initialValues: { [AUTH_KEYS.USERNAME]: '', [AUTH_KEYS.EMAIL]: '', [AUTH_KEYS.PASSWORD]: '' },
    redirectMessage: 'Already have account ',
    redirectLinkTitle: 'Sign In'
  }
};

const getValidationSchema = isSignUp => {
  return Yup.object().shape({
    [AUTH_KEYS.USERNAME]: isSignUp && Yup.string().required(),
    [AUTH_KEYS.PASSWORD]: Yup.string().required(),
    [AUTH_KEYS.EMAIL]: Yup.string().email().required()
  });
};

export const AuthForm = ({
  authMethod = AUTH_METHODS.SIGN_IN,
  onRedirectButtonClick,
  ...props
}) => {
  const isSignUp = authMethod.match(AUTH_METHODS.SIGN_UP);
  const formConfig = FORMS_CONFIG[authMethod];

  return (
    <StyledAuthForm
      {...props}
      heading={formConfig.title}
      initialValues={formConfig.initialValues}
      validationSchema={getValidationSchema(isSignUp)}
      defaultActions={{ submit: { children: formConfig.title, variant: 'tertiary' } }}
      footerChildren={
        <>
          <AuthFormRedirectMessage>
            <AuthFormRedirectMessageText>{formConfig.redirectMessage}</AuthFormRedirectMessageText>
            <Button type='button' onClick={onRedirectButtonClick}>
              {formConfig.redirectLinkTitle}
            </Button>
          </AuthFormRedirectMessage>

          {!isSignUp && (
            <AuthFormActions>
              <AuthFormLinkPasswordForgot to={ROUTES.PASSWORD_RESET}>
                Forgot password
              </AuthFormLinkPasswordForgot>
            </AuthFormActions>
          )}
        </>
      }
    >
      <FormGroup>
        {isSignUp && (
          <FormField
            name={AUTH_KEYS.USERNAME}
            type='text'
            control={{ placeholder: 'Username', variant: 'secondary' }}
          />
        )}

        <FormField
          name={AUTH_KEYS.EMAIL}
          type='email'
          control={{ placeholder: 'Email', variant: 'secondary' }}
        />

        <FormField
          name={AUTH_KEYS.PASSWORD}
          type='password'
          control={{ placeholder: 'Password', variant: 'secondary' }}
        />
      </FormGroup>
    </StyledAuthForm>
  );
};
