import styled, { css } from 'styled-components';

import { Typography } from 'components/_shared/Typography';
import { StyledButton } from 'components/_shared/Button';
import { Link } from 'components/_shared/Link';
import { Form, FormAction, FormHeading } from 'components/_shared/Form';

import { em, eventsStyle } from 'styles';

export const AuthFormLinkPasswordForgot = styled(Link)`
  ${({ theme }) => {
    const fontSize = theme.fontSizes.x;

    return css`
      font-size: ${em(fontSize)};
      text-align: center;
      text-decoration: none;
      margin-top: ${em(theme.spacing.ml, fontSize)};

      &:hover,
      &:focus,
      &:active {
        color: inherit;
        text-decoration: underline;
        background: none;
      }

      &:focus {
        box-shadow: ${eventsStyle({ fontSize, color: theme.colors.light.hue })};
      }
    `;
  }}
`;

export const AuthFormActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AuthFormRedirectMessage = styled(Typography).attrs({
  element: 'p',
  size: 'xs'
})`
  ${({ theme, size }) => css`
    text-align: center;
    margin-top: ${em(theme.spacing.ml, theme.fontSizes[size])};

    ${StyledButton} {
      font-size: ${em(theme.fontSizes.sx, theme.fontSizes[size])};

      &:hover,
      &:focus,
      &:active {
        color: inherit;
        text-decoration: underline;
        background: none;
      }

      &:focus {
        box-shadow: ${eventsStyle({
          fontSize: theme.fontSizes[size],
          color: theme.colors.light.hue
        })};
      }
    }
  `}
`;

export const AuthFormRedirectMessageText = styled(Typography)`
  opacity: 0.7;
`;

export const StyledAuthForm = styled(Form)`
  ${FormHeading} {
    text-align: center;
  }

  ${FormAction} {
    width: 100%;
  }
`;
