import React from 'react';
import { useSelector } from 'react-redux';

import {
  SidebarHeaderHeading,
  SidebarHeaderLink,
  StyledSidebarHeader
} from './SidebarHeader.styles';

import { ROUTES, USER_KEYS } from '_constants';

import { Grid } from 'components/_shared/Grid';
import { Logo } from 'components/Logo';
import { SidebarMenu } from '../Menu';
import { SidebarSearch } from '../Search';

import { selectCurrentUser } from 'state';

export const SidebarHeader = ({ ...props }) => {
  const currentUser = useSelector(selectCurrentUser);

  return (
    <StyledSidebarHeader {...props}>
      <Grid variant='row'>
        <SidebarHeaderLink to={ROUTES.ROOT}>
          <Logo size='sm' withTitle={false} />
          <SidebarHeaderHeading>{`${
            currentUser[USER_KEYS.USERNAME]
          } Hot-spot`}</SidebarHeaderHeading>
        </SidebarHeaderLink>

        <SidebarMenu />
      </Grid>

      <SidebarSearch />
    </StyledSidebarHeader>
  );
};
