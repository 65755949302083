import React from 'react';
import { startCase } from 'lodash-es';

import { StyledGroupInfo } from './GroupInfo.styles';

import { GROUP_KEYS } from '_constants';

import { Field } from 'components/_shared/Field';

import { pickGroupProfileFields, formatGroupProfileLabel } from 'utils';

export const GroupInfo = ({ children, group = {}, ...props }) => {
  const fields = Object.entries(pickGroupProfileFields(group));

  const renderFields = () => {
    return fields.map(([key, value]) => {
      const keyValue = `${key}${value}`;

      if (key === GROUP_KEYS.AVATAR) {
        return (
          <Field
            key={keyValue}
            type='image'
            label='Profile photo'
            control={{ value: { src: value } }}
          />
        );
      }

      if (!value || key === GROUP_KEYS.ID) return null;

      return (
        <Field
          key={keyValue}
          type='read'
          label={startCase(formatGroupProfileLabel(key))}
          control={{ value }}
        />
      );
    });
  };

  return (
    <StyledGroupInfo {...props}>
      {renderFields()}
      {children}
    </StyledGroupInfo>
  );
};
