import { memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { pick } from 'lodash-es';

import {
  USER_STATUSES,
  SOCKET_EVENT_TYPES,
  AUTH_KEY,
  USER_KEYS,
  GROUP_KEYS,
  CONTACT_TYPES
} from '_constants';

import { API } from 'api';

import {
  chatActions,
  groupActions,
  useChatActions,
  useGroupActions,
  userActions,
  useUserActions
} from 'state';

import { useNotification } from '../hooks/useNotification';
import { updateCookie, getCookieAuth, parseJSON, handleResponseError } from 'utils';

export let WS = {};

export const useSocket = () => {
  const dispatch = useDispatch();

  const auth = useMemo(() => getCookieAuth(), []);
  const { getUserData } = useUserActions();
  const { getGroupData } = useGroupActions();
  const { addChat } = useChatActions();
  const { notifySocketEvent } = useNotification();

  const checkIsCurrentUser = useCallback(id => id === auth.userId, [auth.userId]);

  const handleMessage = useCallback(
    async message => {
      try {
        const data = parseJSON(message.data);
        const body = parseJSON(data.Body) || data.Body;

        // console.log('Socket:', data, body);

        const eventHandlers = {
          [SOCKET_EVENT_TYPES.USER_NEW]: () => {
            dispatch(userActions.addEntity(body));
          },

          [SOCKET_EVENT_TYPES.USER_REMOVE]: () => {
            dispatch(userActions.removeEntity(body.UserID));
          },

          [SOCKET_EVENT_TYPES.USER_STATUS]: () => {
            dispatch(
              userActions.updateEntityStatus({
                [USER_KEYS.ID]: body.UserID,
                [USER_KEYS.STATUS]: body.Status
              })
            );
          },

          [SOCKET_EVENT_TYPES.USER_REQUEST_SEND]: () => {
            dispatch(
              userActions.updateEntityStatus({
                [USER_KEYS.ID]: body.UserID,
                [USER_KEYS.IS_ACCEPTED]: false,
                [USER_KEYS.REQUESTOR_ID]: body.UserID,
                [USER_KEYS.STATUS]:
                  body.ContactUserID === auth.userId
                    ? USER_STATUSES.WAITING
                    : USER_STATUSES.REQUESTED
              })
            );
          },

          [SOCKET_EVENT_TYPES.USER_REQUEST_RESPONSE]: async () => {
            if (body.Accept) {
              const user = await getUserData(body.UserID);

              addChat({
                id: user.UserID,
                messages: user.messages,
                users: [auth.userId, user.UserID],
                contact: {
                  id: user.UserID,
                  type: CONTACT_TYPES.USER,
                  chatId: user.ChatID
                }
              });
            }

            dispatch(
              userActions.updateEntityStatus({
                [USER_KEYS.ID]: body.UserID,
                [USER_KEYS.IS_ACCEPTED]: body.Accept || null,
                [USER_KEYS.STATUS]: body.Accept ? USER_STATUSES.ONLINE : USER_STATUSES.AVAILABLE
              })
            );
          },

          [SOCKET_EVENT_TYPES.GROUP_USER_NEW]: async () => {
            const isCurrentUser = checkIsCurrentUser(body.NewUserID);

            if (isCurrentUser) {
              const group = await getGroupData(body.ChatID, {
                [GROUP_KEYS.WITH_CURRENT_USER]: true
              });

              group.withCurrentUser = true;

              dispatch(groupActions.addEntity(group));

              return addChat({
                id: group.id,
                messages: group.messages,
                users: group.users,
                contact: {
                  id: group.id,
                  type: CONTACT_TYPES.GROUP,
                  chatId: group.id
                }
              });
            }

            dispatch(
              groupActions.addEntityUsers({
                [GROUP_KEYS.ID]: body.ChatID,
                [GROUP_KEYS.USERS]: [body.NewUserID]
              })
            );
          },

          [SOCKET_EVENT_TYPES.GROUP_USER_REMOVE]: () => {
            const isCurrentUser = checkIsCurrentUser(body.RemovedUserID);

            if (isCurrentUser) {
              return dispatch(groupActions.removeEntity(body.ChatID));
            }

            dispatch(
              groupActions.removeEntityUser({
                [GROUP_KEYS.ID]: body.ChatID,
                userId: body.RemovedUserID
              })
            );
          },

          [SOCKET_EVENT_TYPES.MESSAGE_NEW]: () => {
            const contactId =
              body.ReceiverType === CONTACT_TYPES.GROUP ? body.ChatID : body.SenderID;

            dispatch(chatActions.addMessage({ contactId, message: body }));
          },

          [SOCKET_EVENT_TYPES.MESSAGE_EDIT]: () => {
            const contactId = !body.ReceiverID ? body.ChatID : body.UserID;

            dispatch(
              chatActions.editMessage({ contactId, message: pick(body, ['Body', 'MessageID']) })
            );
          },

          [SOCKET_EVENT_TYPES.MESSAGE_DELETE]: () => {
            const contactId = !body.ReceiverID ? body.ChatID : body.UserID;
            dispatch(chatActions.removeMessage({ contactId }));
          },

          [SOCKET_EVENT_TYPES.ATTACHMENT_SEND_RESPONSE]: () => {
            API.getAttachment(body.AttachmentID);
          },

          [SOCKET_EVENT_TYPES.ATTACHMENT_RECEIVE]: () => {
            API.getAttachment(body.AttachmentID);
          },

          [SOCKET_EVENT_TYPES.ATTACHMENT_GET_RESPONSE]: () => {
            dispatch(
              chatActions.setMessageFile({
                messageId: body.MessageID,
                file: body.AttachmentBody
              })
            );
          },

          [SOCKET_EVENT_TYPES.USER_PROFILE_UPDATE]: async () => {
            try {
              const response = await API.getUserProfile(body);
              const userProfile = { ...response.data, [USER_KEYS.ID]: body };
              dispatch(userActions.updateEntity({ entity: userProfile }));
              return userProfile;
            } catch (error) {
              handleResponseError(error);
            }
          },

          [SOCKET_EVENT_TYPES.GROUP_PROFILE_UPDATE]: async () => {
            try {
              const response = await API.getGroupProfile(body);
              const groupProfile = { ...response.data, [GROUP_KEYS.ID]: body };
              dispatch(groupActions.updateEntity({ entity: groupProfile }));
              return groupProfile;
            } catch (error) {
              handleResponseError(error);
            }
          },

          [SOCKET_EVENT_TYPES.GROUP_HISTORY_DELETE]: async () => {
            dispatch(chatActions.removeHistory({ chatId: body.ChatID, userId: body.UserID }));
          }
        };

        eventHandlers[data.EventID]?.();
        notifySocketEvent({ data, body });
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch, auth, checkIsCurrentUser, notifySocketEvent, addChat, getUserData, getGroupData]
  );

  const open = useCallback(() => {
    WS = new WebSocket(process.env.REACT_APP_SOCKET_BASE_URL);

    WS.onopen = () => {
      WS.send(
        JSON.stringify({
          EventID: 1,
          UserID: auth.userId,
          SessionID: auth.sessionId
        })
      );

      updateCookie(AUTH_KEY, { shouldRepeatSignIn: true });
    };

    WS.onmessage = handleMessage;
  }, [auth, handleMessage]);

  useEffect(() => {
    open();
  }, [open]);

  return WS;
};

export const SocketController = memo(() => {
  useSocket();
  return null;
});
