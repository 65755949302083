import React from 'react';
import { useSelector } from 'react-redux';

import { StylesGroupAddView } from './GroupAddView.styles';

import { GROUP_KEYS, ROUTES } from '_constants';

import { selectGroups, useGroupActions } from 'state';
import { goBackWithHistory } from 'utils';

import { GroupForm } from '../Form';

export const GroupAddView = ({ history, ...props }) => {
  const { isLoading, addGroup } = useGroupActions();
  const groups = useSelector(selectGroups);

  const isExistedName = name => {
    return Object.values(groups).some(group =>
      group[GROUP_KEYS.NAME].match(new RegExp(`\\b${name}\\b`, 'gi'))
    );
  };

  const handleFormSubmit = async (values, helpers) => {
    if (isExistedName(values[GROUP_KEYS.NAME])) {
      return helpers.setFieldError(GROUP_KEYS.NAME, 'This group name already exists.');
    }

    try {
      const newGroup = await addGroup(values);
      history.replace(ROUTES.CHAT_BY_CONTACT_ID(newGroup.ChatID));
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <StylesGroupAddView {...props} heading='New group'>
      <GroupForm
        isLoading={isLoading}
        onSubmit={handleFormSubmit}
        onCancel={() => goBackWithHistory(history)}
      />
    </StylesGroupAddView>
  );
};
