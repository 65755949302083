import styled from 'styled-components';

import { Menu, MenuToggle } from 'components/_shared/Menu';
import { eventsStyle } from 'styles';

export const StyledSidebarMenu = styled(Menu)`
  margin-left: auto;

  ${MenuToggle} {
    ${eventsStyle({ variant: 'secondary' })};
  }
`;
