import React, { useCallback } from 'react';
import { mergeProps, withFunction } from 'utils';

import { FileListItem, StyledFileList } from './FileList.styles';

export const FileList = ({ files = [], fileProps = {}, ...props }) => {
  const renderFiles = useCallback(() => {
    return files.map((file, i) => {
      const props = withFunction(fileProps, file);

      const mergedProps = mergeProps(file, [
        file.type.match('image') && { image: file.base64 },
        props
      ]);

      return <FileListItem {...mergedProps} key={i} title={mergedProps.name} />;
    });
  }, [files, fileProps]);

  return <StyledFileList {...props}>{renderFiles()}</StyledFileList>;
};
