import { pick } from 'lodash-es';

import {
  CONTACT_TYPES,
  USER_KEYS,
  USER_KEYS_MAP,
  USER_PROFILE_KEYS,
  USER_STATUSES,
  USER_STATUSES_ACCEPTED_VALUES,
  USER_TEMPLATE
} from '_constants';

import { mergeObjects, normalizeKeysByMap, withMergeObjects } from './helpers';

export const createUser = (user = {}) => {
  return pick(mergeObjects(USER_TEMPLATE, user), Object.keys(USER_TEMPLATE));
};

export const normalizeUserStatus = (user = {}) => {
  let { [USER_KEYS.STATUS]: status, [USER_KEYS.IS_ACCEPTED]: isAccepted } = user;

  if (isAccepted === null) {
    return { status: USER_STATUSES.AVAILABLE, isAccepted };
  }

  if (!isAccepted) {
    if (user[USER_KEYS.REQUESTOR_ID] === user[USER_KEYS.ID]) {
      return { status: USER_STATUSES.WAITING, isAccepted };
    }

    return { status: USER_STATUSES.REQUESTED, isAccepted };
  }

  return { status, isAccepted };
};

export const normalizeUser = (user = {}, params = {}) => {
  const { pickKeys = false, normalizeKeysOnly = false } = params;
  const [getUser, setUser] = withMergeObjects();

  setUser(normalizeKeysByMap(user, USER_KEYS_MAP));
  const normalizedKeys = Object.keys(getUser());

  if (normalizeKeysOnly) return getUser();

  setUser(createUser(getUser()));
  setUser(normalizeUserStatus(getUser()));

  if (pickKeys) {
    setUser(pick(getUser(), normalizedKeys), { hard: true });
  }

  return getUser();
};

export const isUser = contact => contact[USER_KEYS.CONTACT_TYPE] === CONTACT_TYPES.USER;

export const isUserAccepted = user => {
  return USER_STATUSES_ACCEPTED_VALUES.includes(user[USER_KEYS.STATUS]);
};

export const pickUserProfileFields = user => {
  return pick(user, USER_PROFILE_KEYS);
};

export const sortUsersByStatus = (users = []) => {
  const statusKey = USER_KEYS.STATUS;
  return [...users].sort((a, b) => a[statusKey] - b[statusKey]);
};

export const formatUserProfileLabel = key => {
  const keyFormats = {
    [USER_KEYS.EMAIL_PERSONAL]: 'Personal',
    [USER_KEYS.EMAIL_BUSINESS]: 'Business'
  };

  return keyFormats[key] || key;
};
