import styled, { css } from 'styled-components';

import { Typography } from 'components/_shared/Typography';
import { ContactListLabel } from 'components/Contact';

import { em, rem } from 'styles';

export const SidebarSearchResults404 = styled(Typography).attrs({ element: 'p', size: 'sx' })`
  ${({ theme, size }) => css`
    padding-left: ${em(theme.spacing.sm, theme.fontSizes[size])};
  `}
`;

export const SidebarSearchResultsHeading = styled(ContactListLabel)`
  ${({ theme, size }) => css`
    margin-bottom: ${em(theme.spacing.sx, theme.fontSizes[size])};
  `}
`;

export const StyledSidebarSearchResults = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    background: ${theme.colors.primary.hue};
    padding: ${rem([theme.spacing.sm, 0])};
  `}
`;
