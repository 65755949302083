import { cloneElement } from 'react';
import styled, { css } from 'styled-components';
import cn from 'classnames';

import { Preloader } from '../Preloader';
import { Typography } from '../Typography';

import { shouldForwardProp } from 'utils';
import { em, upperFirst, spacing } from 'styles';

export const FormPreloader = styled(Preloader)``;

export const FormMessage = styled(Typography).attrs({ element: 'p', size: 'xs' })`
  ${({ theme, size }) => css`
    font-weight: ${theme.fontWeights.medium};
    ${upperFirst};
    ${spacing({ side: 'top', spacing: 'xs', fontSize: theme.fontSizes[size] })};
  `}
`;

export const FormMessageError = styled(FormMessage)`
  ${({ theme }) => css`
    color: ${theme.colors.danger.hue};
  `}
`;

export const FormMessages = styled.div`
  ${({ theme }) => css`
    margin-top: ${em(theme.spacing.ml)};

    + * {
      margin-top: ${em(theme.spacing.s)};
    }
  `}
`;

export const FormFooter = styled.footer`
  ${({ theme }) => css`
    margin-top: ${em(theme.spacing.ml)};
  `}
`;

export const FormAction = styled(({ children: child, ...props }) => {
  const className = cn(child.props.className, props.className);
  return cloneElement(child, { ...props, className });
})``;

export const FormActions = styled.div`
  ${({ theme }) => {
    const childSpacing = theme.spacing.xs;

    return css`
      display: flex;
      align-items: center;
      margin: -${em(childSpacing)};
      margin-top: ${em(theme.spacing.ml - childSpacing)};

      ${FormAction} {
        margin: ${em(childSpacing)};
      }
    `;
  }}
`;

export const FormBody = styled.div``;

export const FormHeading = styled(Typography).attrs({ element: 'h2', size: 'ms' })``;

export const FormHeader = styled.header`
  ${({ theme }) => css`
    margin-bottom: ${em(theme.spacing.lm)};
  `}
`;

export const StyledForm = styled.form.withConfig({ shouldForwardProp })`
  position: relative;
  width: 100%;
  max-width: 100%;
`;
