import { createSlice } from '@reduxjs/toolkit';
import Cookie from 'js-cookie';

import { SETTINGS_KEY, THEME_NAMES } from '_constants';

import { mergeObjects, updateCookie } from 'utils';

const SETTINGS_DEFAULT_STATE = {
  theme: {
    name: THEME_NAMES.BLUE
  },
  notification: {
    withSound: true
  }
};

const SETTINGS_INITIAL_STATE = mergeObjects(SETTINGS_DEFAULT_STATE, Cookie.getJSON(SETTINGS_KEY));

const settingsSlice = createSlice({
  name: SETTINGS_KEY,
  initialState: SETTINGS_INITIAL_STATE,
  reducers: {
    setTheme: (state, action) => {
      state.theme.name = action.payload;
      updateCookie(SETTINGS_KEY, { theme: { name: action.payload } });
    },
    setNotification: (state, action) => {
      state.notification.withSound = action.payload;
      updateCookie(SETTINGS_KEY, { notification: { withSound: action.payload } });
    }
  }
});

export const { reducer: settingsReducer, actions: settingsActions } = settingsSlice;

export const selectSettings = state => state.settings;

export const selectActiveThemeName = state => state.settings.theme.name;
