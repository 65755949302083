// TODO: Fix control ref

import React, { forwardRef, isValidElement, useImperativeHandle, useMemo, useRef } from 'react';

import { isFunction, upperFirst } from 'lodash-es';

import {
  FieldBody,
  FieldFooter,
  FieldHeader,
  FieldLabel,
  FieldMessageError,
  FieldMessages,
  FormFieldControl,
  StyledField
} from './Field.styles';

import { mergeProps, withFunction } from 'utils';

import { Input, InputImage } from '../Input';
import { Typography } from '../Typography';
import { Select } from '../Select';
import { Checkbox } from '../Checkbox';

const FIELD_TYPE_CONTROLS = {
  text: <Input type='text' />,
  email: <Input type='email' />,
  password: <Input type='password' />,
  image: <InputImage />,
  read: <Typography element='p' size='sx' />,
  select: <Select />,
  checkbox: <Checkbox />
};

let prevFieldId = 0;

export const Field = forwardRef(({ id: initialId, label, error, type, control, ...props }, ref) => {
  const rootRef = useRef(null);
  const id = useMemo(() => initialId || `field-${++prevFieldId}`, [initialId]);

  useImperativeHandle(ref, () => rootRef.current, []);

  const canRenderHeader = label;
  const canRenderMessages = error;
  const canRenderFooter = canRenderMessages;

  const renderControl = () => {
    let controlElement = null;
    const isCustomControl = control && (isFunction(control) || isValidElement(control));

    if (type) controlElement = FIELD_TYPE_CONTROLS[type];

    if (isCustomControl) controlElement = withFunction(control);

    if (!controlElement) return null;

    const mergedProps = mergeProps(controlElement.props, [{ id }, !isCustomControl && control]);

    return <FormFieldControl {...mergedProps}>{controlElement}</FormFieldControl>;
  };

  return (
    <StyledField {...props} ref={rootRef}>
      {canRenderHeader && (
        <FieldHeader>
          {label && <FieldLabel htmlFor={id}>{upperFirst(label)}</FieldLabel>}
        </FieldHeader>
      )}

      <FieldBody>{renderControl()}</FieldBody>

      {canRenderFooter && (
        <FieldFooter>
          {canRenderMessages && (
            <FieldMessages>{error && <FieldMessageError>{error}</FieldMessageError>}</FieldMessages>
          )}
        </FieldFooter>
      )}
    </StyledField>
  );
});
