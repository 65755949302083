import styled, { css } from 'styled-components';
import { rgba } from 'polished';

import { shouldForwardProp } from 'utils';
import { em, eventsStyle } from 'styles';

export const inputVariantStyles = {
  primary: ({ theme, fontSize }) => css`
    color: ${theme.colors.dark.hue};
    background: ${theme.colors.light.hue};

    &::placeholder {
      color: ${theme.colors.dark.tint};
    }

    ${eventsStyle({ fontSize, withHover: false, withActive: false })}

    &:hover {
      border-color: ${theme.colors.primary.hue};
    }
  `,
  secondary: ({ theme, fontSize }) => css`
    color: ${theme.colors.light.hue};
    border-color: transparent;
    background: ${theme.colors.input.secondary.background};

    &::placeholder {
      color: ${rgba(theme.colors.light.hue, 0.6)};
    }

    ${eventsStyle({ fontSize, variant: 'secondary', withHover: false, withActive: false })}

    &:hover {
      border-color: ${rgba(theme.colors.light.hue, 0.5)};
    }
  `
};

export const inputStyle = ({ theme, variant, fontSize }) => {
  return [
    css`
      appearance: none;
      font-size: ${em(fontSize)};
      font-weight: ${theme.fontWeights.medium};
      color: inherit;
      line-height: ${theme.lineHeight.x};
      width: 100%;
      max-width: 100%;
      border: ${theme.borders.default({ fontSize })};
      border-radius: ${em(theme.radius.x, fontSize)};
      background: none;
      padding: ${em([theme.spacing.xs, theme.spacing.sm], fontSize)};
    `,
    variant && inputVariantStyles[variant]
  ];
};

export const StyledInput = styled.input
  .withConfig({ shouldForwardProp })
  .attrs(({ theme }) => ({ fontSize: theme.fontSizes.sx }))`
  ${inputStyle};
`;
