import React from 'react';

import { StyledPagePasswordReset } from './PagePasswordReset.styles';

import { API } from 'api';
import { ROUTES } from '_constants';

import { Logo } from 'components/Logo';
import { PasswordResetForm } from 'components/Password';

import { useRequest } from 'hooks';

export const PagePasswordReset = ({ history }) => {
  const { error, isLoading, makeRequest, updateRequestState } = useRequest();

  const handleFormSubmit = async values => {
    try {
      await makeRequest(API.resetPassword(values));
      history.push(ROUTES.AUTH);
    } catch (error) {
      updateRequestState({ error: 'Request error' });
    }
  };

  return (
    <StyledPagePasswordReset sidebar={false}>
      <Logo />

      <PasswordResetForm
        error={error}
        isLoading={isLoading}
        onCancel={() => history.goBack()}
        onSubmit={handleFormSubmit}
      />
    </StyledPagePasswordReset>
  );
};
