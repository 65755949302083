import axios from 'axios';
import { isObject, pick } from 'lodash-es';

import { API_ENDPOINTS_AUTHORIZED } from './endpoints';
import { AUTH_KEYS } from '_constants';

import { getCookieAuth } from 'utils';

export const AXIOS_INSTANCE = axios.create({
  baseURL: `${process.env.REACT_APP_REQUEST_BASE_URL}/v1`
});

const handleError = error => {
  const parsedError = error.data?.ErrorMessage || 'Request error';
  return Promise.reject(parsedError);
};

const handleRequest = request => {
  if (API_ENDPOINTS_AUTHORIZED.includes(request.url)) {
    return handleAuthenticatedRequest(request);
  }

  return request;
};

const handleResponse = response => {
  if (response.data.ErrorCode > 0) {
    return handleError(response);
  }

  return response;
};

const withCurrentUser = data => {
  const auth = pick(getCookieAuth(), [
    AUTH_KEYS.USER_ID,
    AUTH_KEYS.SESSION_ID,
    AUTH_KEYS.IS_TOKEN_DEFAULT
  ]);

  const currentUser = {
    UserID: auth.userId,
    SessionID: auth[AUTH_KEYS.SESSION_ID],
    IsTokenDefault: auth[AUTH_KEYS.IS_TOKEN_DEFAULT]
  };

  if (isObject(data)) {
    if (data.hasOwnProperty('MessageBody')) {
      data.SenderID = currentUser.UserID;
      delete currentUser.UserID;
      delete currentUser.IsTokenDefault;
    }
  }

  try {
    return JSON.stringify({ ...currentUser, ...JSON.parse(data) });
  } catch (_error) {
    return !data ? currentUser : { ...currentUser, ...data };
  }
};

const handleAuthenticatedRequest = request => {
  const dataWithCurrentUser = withCurrentUser(request.data);

  if (dataWithCurrentUser) {
    request.data = dataWithCurrentUser;
    return request;
  }

  return request;
};

AXIOS_INSTANCE.interceptors.request.use(handleRequest, handleError);

AXIOS_INSTANCE.interceptors.response.use(handleResponse, handleError);
