import styled, { css } from 'styled-components';
import { rgba } from 'polished';

import {
  ContactCardAvatar,
  ContactCardStatusIndicator,
  ContactListGrouped,
  StyledContactCard
} from 'components/Contact';

import { AvatarIcon } from 'components/_shared/Avatar';
import { MenuToggle, StyledMenu } from 'components/_shared/Menu';
import { StyledIndicatorCount } from 'components/_shared/Indicator';

import { eventsStyle, rem } from 'styles';

export const StyledSidebarContactList = styled(ContactListGrouped)`
  ${({ theme }) => css`
    ${StyledContactCard} {
      font-size: ${rem(theme.fontSizes.sx)};
      transition: none;

      &:hover,
      &:focus {
        ${StyledMenu} {
          display: flex;
        }

        ${StyledIndicatorCount} {
          display: none;
        }
      }

      &:hover {
        background: ${theme.colors.primary.tone};
      }

      &.isActive {
        background: ${theme.colors.sidebar.contactList.active};
      }
    }

    ${ContactCardAvatar} {
      font-size: ${rem(theme.fontSizes.xs)};
      border: none;
      background: ${theme.colors.primary.tone};

      ${AvatarIcon} {
        color: ${rgba(theme.colors.light.hue, 0.4)};
      }
    }

    ${ContactCardStatusIndicator} {
      width: ${rem(10)};
    }

    ${StyledMenu} {
      display: none;
    }

    ${MenuToggle} {
      ${eventsStyle({ variant: 'secondary' })}
    }
  `}
`;
