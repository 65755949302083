import React, { memo, useCallback } from 'react';
import cn from 'classnames';
import { FiX } from 'react-icons/fi';

import {
  MessagePreviewHeading,
  MessagePreviewText,
  MessagePreviewToggle,
  StyledMessagePreview
} from './MessagePreview.styles';

import { useToggle } from 'hooks';

export const MessagePreview = memo(
  ({ className, heading, text, isOpen: initialIsOpen, onClose, onClick, ...props }) => {
    const [isOpen, setIsOpen] = useToggle(initialIsOpen);

    const handleClose = useCallback(() => {
      setIsOpen(false);
      onClose?.();
    }, [onClose, setIsOpen]);

    if (!isOpen) return null;

    return (
      <StyledMessagePreview {...props} className={cn(className, { onClick })}>
        <MessagePreviewToggle icon={<FiX />} onClick={handleClose} />
        {heading && <MessagePreviewHeading>{heading}</MessagePreviewHeading>}
        {text && <MessagePreviewText dangerouslySetInnerHTML={{ __html: text }} />}
      </StyledMessagePreview>
    );
  }
);
