import { createSelector, createSlice } from '@reduxjs/toolkit';
import { filter, find, map, pick } from 'lodash-es';

import { CHAT_KEY, CHAT_UPDATES_TEMPLATE } from '_constants';

import { selectUsers } from 'state/user';
import { selectGroups } from 'state/group';

import {
  mergeObjects,
  normalizeChat,
  normalizeMessage,
  normalizeMessageForUI,
  removeFalsyValues
} from 'utils';

export const CHAT_INITIAL_STATE = {
  entities: {},
  activeContactId: ''
};

const actionCustomizer = (value, sourceValue, key) => {
  if (key === 'message') return sourceValue ?? value;
};

const chatSlice = createSlice({
  name: CHAT_KEY,
  initialState: CHAT_INITIAL_STATE,
  reducers: {
    setEntities: {
      reducer: (state, action) => {
        state.entities = { ...state.entities, ...action.payload };
      },
      prepare: entities => {
        return {
          payload: entities.reduce((entities, entity) => {
            const normalizedEntity = normalizeChat(entity);
            return { ...entities, [normalizedEntity.id]: normalizedEntity };
          }, {})
        };
      }
    },

    addEntity: {
      reducer: (state, action) => {
        if (state.entities[action.payload.id]) return;
        state.entities[action.payload.id] = action.payload;
      },
      prepare: entity => {
        const normalizedEntity = normalizeChat(entity);
        return { payload: normalizedEntity };
      }
    },

    setActiveContactId: (state, action) => {
      state.activeContactId = action.payload;
    },

    switchActiveChat: (state, action) => {
      const { contactId, editor = {}, prevChat } = action.payload;
      const chat = state.entities[contactId];

      if (chat.updates.hasUpdates) {
        chat.updates = CHAT_UPDATES_TEMPLATE;
      }

      state.activeContactId = contactId;
      chat.editor = mergeObjects(chat.editor, editor);

      if (prevChat) {
        const prevChatEntity = state.entities[prevChat.contactId];

        if (prevChat.editor) {
          prevChatEntity.editor = mergeObjects(prevChatEntity.editor, prevChat.editor);
        }
      }
    },

    setAction: (state, action) => {
      const { contactId, action: _action } = action.payload;
      const prevActions = state.entities[contactId].action;

      state.entities[contactId].action = mergeObjects(prevActions, _action, actionCustomizer);
    },

    setEditor: (state, action) => {
      const { contactId, editor } = action.payload;
      const prevEditor = state.entities[contactId].editor;
      state.entities[contactId].editor = mergeObjects(prevEditor, editor);
    },

    setUpdates: (state, action) => {
      const { contactId, updates } = action.payload;
      const prevUpdates = state.entities[contactId].updates;
      state.entities[contactId].updates = mergeObjects(prevUpdates, updates);
    },

    setEditorAction: (state, action) => {
      const { contactId, editor = {}, action: _action = {} } = action.payload;

      const prevEditor = state.entities[contactId].editor;
      state.entities[contactId].editor = mergeObjects(prevEditor, editor);

      const prevAction = state.entities[contactId].action;
      state.entities[contactId].action = mergeObjects(prevAction, _action, actionCustomizer);
    },

    removeHistory: (state, action) => {
      const { chatId, userId } = action.payload;
      const chat = state.entities[chatId] || state.entities[userId];
      chat.messages = {};
      chat.updates = CHAT_UPDATES_TEMPLATE;
    },

    addMessage: {
      reducer: (state, action) => {
        const { contactId, message } = action.payload;

        if (message.chatId) {
          state.entities[contactId].contact.chatId = message.chatId;
        }

        state.entities[contactId].messages[message.id] = message;

        if (contactId !== state.activeContactId) {
          state.entities[contactId].updates.messages.new += 1;
          state.entities[contactId].updates.hasUpdates = true;
        }
      },
      prepare: ({ message, ...payload }) => {
        const normalizedMessage = normalizeMessage(message);
        return { payload: { ...payload, message: normalizedMessage } };
      }
    },

    editMessage: {
      reducer: (state, action) => {
        const { contactId, message } = action.payload;
        const prevMessage = state.entities[contactId].messages[message.id];

        state.entities[contactId].messages[message.id] = mergeObjects(prevMessage, message);

        if (contactId !== state.activeContactId) {
          state.entities[contactId].updates.messages.edited += 1;
          state.entities[contactId].updates.hasUpdates = true;
        }
      },
      prepare: payload => {
        const { message, allowEmpty = false, ...restPayload } = payload;

        const normalizedMessage = removeFalsyValues(
          normalizeMessage(message),
          allowEmpty ? [''] : undefined
        );

        return { payload: { ...restPayload, message: normalizedMessage } };
      }
    },

    deleteMessage: (state, action) => {
      const { messageId } = action.payload;
      const chat = find(state.entities, chat => chat.messages[messageId]);
      if (!chat || !chat.messages[messageId]) return;
      delete state.entities[chat.id].messages[messageId];
    },

    removeMessage: (state, action) => {
      const { contactId } = action.payload;

      if (contactId !== state.activeContactId) {
        state.entities[contactId].updates.messages.deleted += 1;
        state.entities[contactId].updates.hasUpdates = true;
      }
    },

    setMessageFile: (state, action) => {
      const { messageId, file = '' } = action.payload;

      const chat = find(state.entities, chat => chat.messages[messageId]);
      if (!chat || !chat.messages[messageId]) return;

      state.entities[chat.id].messages[messageId].file = file;
    },

    pinMessage: (state, action) => {
      const { id, contactId } = action.payload;
      state.entities[contactId].pinnedMessages.messages.push(id);
      state.entities[contactId].messages[id].isPinned = true;
    },

    unpinMessage: (state, action) => {
      const { id, contactId } = action.payload;
      const pinnedMessages = state.entities[contactId].pinnedMessages.messages;
      const messageIndex = pinnedMessages.indexOf(id);
      pinnedMessages.splice(messageIndex, 1);
      state.entities[contactId].messages[id].isPinned = false;
    },

    unpinMessages: (state, action) => {
      const pinnedMessages = state.entities[action.payload].pinnedMessages;
      pinnedMessages.isOpen = false;
      pinnedMessages.messages = [];

      Object.values(state.entities[action.payload].messages).forEach(message => {
        message.isPinned = false;
      });
    },

    togglePinnedMessages: (state, action) => {
      const { contactId, isOpen } = action.payload;
      const pinnedMessages = state.entities[contactId].pinnedMessages;
      const prevIsOpen = pinnedMessages.isOpen;
      pinnedMessages.isOpen = isOpen ?? !prevIsOpen;
    },

    setSelectedMessages: (state, action) => {
      const { contactId, messagesId } = action.payload;
      state.entities[contactId].selectedMessages = messagesId;
    }
  }
});

export const { reducer: chatReducer, actions: chatActions } = chatSlice;

export const selectChats = state => state.chat.entities;

export const makeSelectChatContact = () => {
  return createSelector(
    selectUsers,
    selectGroups,
    (_, contactId) => contactId,
    (users, groups, contactId) => users[contactId] || groups[contactId]
  );
};

export const makeSelectChatMessagesUI = () => {
  return createSelector(
    selectChats,
    selectUsers,
    (_, contactId) => contactId,
    (chats, users, contactId) => {
      return map(chats[contactId].messages, message => {
        return normalizeMessageForUI(message, { users, messages: chats[contactId].messages });
      });
    }
  );
};

export const makeSelectChatPinnedMessages = () => {
  return createSelector(
    selectChats,
    (_, contactId) => contactId,
    (chats, contactId) => chats[contactId].pinnedMessages
  );
};

export const makeSelectChatAction = () => {
  return createSelector(
    selectChats,
    (_, contactId) => contactId,
    (chats, contactId) => chats[contactId].action
  );
};

export const makeSelectChatEditor = () => {
  return createSelector(
    selectChats,
    (_, contactId) => contactId,
    (chats, contactId) => chats[contactId].editor
  );
};

export const makeSelectChatEditorAction = () => {
  return createSelector(
    selectChats,
    (_, contactId) => contactId,
    (chats, contactId) => pick(chats[contactId], ['editor', 'action'])
  );
};

export const makeSelectChatUpdates = () => {
  return createSelector(
    selectChats,
    (_, contactId) => contactId,
    (chats, contactId) => chats[contactId].updates
  );
};

export const makeSelectChatHasUpdates = () => {
  return createSelector(
    selectChats,
    (_, contactId) => contactId,
    (chats, contactId) => {
      return chats[contactId].updates.hasUpdates;
    }
  );
};

export const selectSomeChatHasUpdates = () => {
  return createSelector(
    selectChats,
    (_, contactType) => contactType,
    (chats, contactType) => {
      return Object.values(chats)
        .filter(chat => chat.contact.type === contactType)
        .some(chat => chat.updates.hasUpdates);
    }
  );
};

export const selectChatsMeta = createSelector(selectChats, chats => {
  return Object.values(chats).reduce((chats, chat) => {
    return { ...chats, [chat.contact.id]: pick(chat, ['id', 'contact']) };
  }, {});
});

export const makeSelectIsChatAvailable = () => {
  return createSelector(
    selectGroups,
    selectUsers,
    (_, contactId) => contactId,
    (groups, users, contactId) => {
      return !!(groups[contactId]?.withCurrentUser || users[contactId]?.isAccepted);
    }
  );
};

export const selectChatUsers = createSelector(
  selectGroups,
  selectUsers,
  (_, contactId) => contactId,
  (groups, users, contactId) => {
    return (groups[contactId]?.users || [contactId])
      .map(userId => users[userId])
      .filter(({ isCurrentUser }) => !isCurrentUser);
  }
);

export const makeSelectChatUsers = () => selectChatUsers;

export const makeSelectChatSelectedMessages = () => {
  return createSelector(
    selectChats,
    (_, contactId) => contactId,
    (chats, contactId) => {
      const selectedMessagesId = chats[contactId].selectedMessages;
      return filter(chats[contactId].messages, message => selectedMessagesId.includes(message.id));
    }
  );
};
