import styled, { css } from 'styled-components';

import { Icon } from 'components/_shared/Icon';

import { em } from 'styles';

export const StyledMessageReadMark = styled(Icon).attrs({ size: 'sx' })`
  ${({ theme }) => css`
    flex: 0 0 auto;
    color: ${theme.colors.dark.tint};
    padding-top: ${em(2)};
    margin-left: ${em(theme.spacing.xs - 2)};
  `}
`;
