import { lighten } from 'polished';

import { cloneTheme } from 'styles/utils';
import { themeDefault } from './theme.default';

export const themeBlueDark = cloneTheme(themeDefault, {
  colors: {
    primary: {
      hue: '#1205A1',
      tone: '#110594'
    },

    message: {
      card: {
        active: lighten(0.6, '#1205A1')
      }
    },

    indicator: {
      count: {
        foreground: '#000000',
        background: '#00D95F'
      }
    }
  }
});
