import React, { useState, useEffect } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

import {
  StyledPreloader,
  PreloaderBody,
  PreloaderIcon,
  PreloaderTitle,
  PrelaoderOverlay
} from './Preloader.styles';

export const Preloader = ({
  title,
  delay = 1000,
  positionVariant = 'absolute',
  icon = <AiOutlineLoading3Quarters />,
  iconSize = 'sm',
  titleSize = 'xs',
  isTransparent = false,
  ...props
}) => {
  const [isDisplayed, setIsDisplayed] = useState(false);

  useEffect(() => {
    let timeout = setTimeout(() => setIsDisplayed(true), delay);
    return () => clearTimeout(timeout);
  }, [delay]);

  return (
    <StyledPreloader {...props} positionVariant={positionVariant} icon={icon}>
      <PrelaoderOverlay isTransparent={isTransparent} />

      <PreloaderBody isDisplayed={isDisplayed}>
        {icon && <PreloaderIcon size={iconSize}>{icon}</PreloaderIcon>}
        {title && <PreloaderTitle size={titleSize}>{title}</PreloaderTitle>}
      </PreloaderBody>
    </StyledPreloader>
  );
};
