import React, { createElement } from 'react';
import { useSelector } from 'react-redux';

import { StyledModalController } from './ModalController.styles';

import { MODAL_KEYS } from '_constants';

import { selectModalOpened, useModal } from 'state';

import { Modal } from '../Modal';
import { ModalConfirm, StyledModalConfirm } from '../Confirm';
import { GroupUsersModalAdd, GroupUsersModalShow } from 'components/Group';
import { getStyledComponentClassName } from 'utils';

export const ModalController = ({ ...props }) => {
  const { closeModal } = useModal();
  const openedModals = useSelector(selectModalOpened);

  const MODAL_COMPONENTS = {
    [MODAL_KEYS.CONFIRM]: ModalConfirm,
    [MODAL_KEYS.GROUP_USER_ADD]: GroupUsersModalAdd,
    [MODAL_KEYS.GROUP_USER_SHOW]: GroupUsersModalShow
  };

  const handleModalClose = modal => closeModal(modal.name);

  const renderModals = () => {
    return openedModals.map((modal, i) => {
      return createElement(MODAL_COMPONENTS[modal.name] || Modal, {
        ...modal.props,
        key: i,
        clickOutsideParams: modal.name !== MODAL_KEYS.CONFIRM && {
          ignoredClassNames: [getStyledComponentClassName(StyledModalConfirm)]
        },
        onClose: () => handleModalClose(modal)
      });
    });
  };

  return <StyledModalController {...props}>{renderModals()}</StyledModalController>;
};
