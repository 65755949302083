import { CONTACT_TYPES } from './contact';

export const GROUP_KEY = 'group';

export const GROUP_KEYS = {
  ID: 'id',
  NAME: 'name',
  AVATAR: 'avatar',
  EMAIL: 'email',
  EMAIL_BUSINESS: 'emailBusiness',
  EMAIL_PERSONAL: 'emailPersonal',
  USERS: 'users',
  WITH_CURRENT_USER: 'withCurrentUser',
  CONTACT_TYPE: 'contactType'
};

export const GROUP_PROFILE_KEYS = [
  GROUP_KEYS.ID,
  GROUP_KEYS.AVATAR,
  GROUP_KEYS.NAME,
  GROUP_KEYS.EMAIL,
  GROUP_KEYS.EMAIL_BUSINESS,
  GROUP_KEYS.EMAIL_PERSONAL
];

export const GROUP_KEYS_MAP = {
  [GROUP_KEYS.ID]: ['ChatID', 'chatId'],
  [GROUP_KEYS.NAME]: ['Name'],
  [GROUP_KEYS.AVATAR]: ['Avatar'],
  [GROUP_KEYS.EMAIL]: ['Email'],
  [GROUP_KEYS.EMAIL_BUSINESS]: ['Business', 'Buisness'],
  [GROUP_KEYS.EMAIL_PERSONAL]: ['Personal']
};

export const GROUP_TEMPLATE = {
  [GROUP_KEYS.ID]: '',
  [GROUP_KEYS.NAME]: '',
  [GROUP_KEYS.AVATAR]: '',
  [GROUP_KEYS.EMAIL]: '',
  [GROUP_KEYS.EMAIL_BUSINESS]: '',
  [GROUP_KEYS.EMAIL_PERSONAL]: '',
  [GROUP_KEYS.USERS]: [],
  [GROUP_KEYS.WITH_CURRENT_USER]: false,
  [GROUP_KEYS.CONTACT_TYPE]: CONTACT_TYPES.GROUP
};
