import { isArray, isBoolean, isEmpty, isFunction, isNumber, isObject, mergeWith } from 'lodash-es';
import fromEntries from 'object.fromentries';

export const withFunction = (value, props) => {
  return isFunction(value) ? value(props) : value;
};

export const mergeObjects = (initialObject, source, customizer) => {
  let _source = source;

  if (isArray(source)) {
    _source = source.reduce((initialObject, source) => {
      return !source ? initialObject : mergeObjects(initialObject, source);
    }, {});
  }

  if (isFunction(source)) _source = source(initialObject);

  const defaultCustomizer = (value, sourceValue) => {
    if (isArray(value) && !isEmpty(sourceValue)) return sourceValue;
    if (isFunction(value) && sourceValue) return sourceValue;
  };

  return mergeWith({}, initialObject, _source, customizer || defaultCustomizer);
};

export const withMergeObjects = (initialObject, customizer) => {
  return [
    () => initialObject,
    (object, params = {}) => {
      const { hard = false } = params;
      return (initialObject = hard ? object : mergeObjects(initialObject, object, customizer));
    }
  ];
};

export const findKeyByValue = (object = {}, value) => {
  return Object.entries(object).find(([, entryValue]) => {
    if (isArray(entryValue)) {
      return entryValue.some(entryValue => entryValue === value);
    }

    return entryValue === value;
  })?.[0];
};

export const normalizeKeysByMap = (object = {}, keysMap = {}) => {
  return Object.entries(object).reduce((object, [key, value]) => {
    const normalizedKey = findKeyByValue(keysMap, key) || key;
    return { ...object, [normalizedKey]: value };
  }, {});
};

export const objectToURI = object => {
  return encodeURI(
    Object.entries(object).reduce((uri, [key, value]) => {
      return value ? `${uri}&${key}=${value}` : uri;
    }, '')
  );
};

export const URItoObject = uri => {
  return fromEntries(new URLSearchParams(uri).entries());
};

export const stripHTML = string => string.replace(/(<([^>]+)>)/gi, '');

export const handleResponseError = error => console.error(error);

export const goBackWithHistory = history => {
  if (history.location.state?.prevLocation) {
    return history.push(history.location.state.prevLocation);
  }

  if (history && history.entries?.length) {
    const regexp = new RegExp(`\\/(.*?)\\/`);

    const entry = history.entries
      .reverse()
      .map(({ pathname }, i) => [pathname.match(regexp)?.[0] || pathname, i])
      .reduce((prevEntry, entry) => {
        if (isNumber(prevEntry)) return prevEntry;
        return prevEntry[0] === entry[0] ? prevEntry : entry[1];
      });

    return history.push(history.entries[entry]);
  }

  return history.goBack();
};

export const submitForm = form => {
  if (!form) return;

  let submitEvent;

  try {
    submitEvent = new Event('submit', true, true);
  } catch (error) {
    submitEvent = document.createEvent('Event');
    submitEvent.initEvent('submit', true, true);
  }

  form.dispatchEvent(submitEvent);
};

export const removeFalsyValues = (object, validValues = []) => {
  return Object.entries(object).reduce((object, [key, value]) => {
    if (isObject(value)) {
      value = removeFalsyValues(value, validValues);
    }

    const isValidValue = validValues.includes(value) || isBoolean(value) || !isEmpty(value);

    if (!isValidValue) return object;

    return { ...object, [key]: value };
  }, {});
};

export const isMatchQuery = (query = '', keyword = '', flags = '') => {
  if (!query || !keyword) return false;
  return query.split(' ').every(word => keyword.match(new RegExp(word, `g${flags}`)));
};

export const readFileAsync = (file, method = 'readAsDataURL') => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = () => {
      resolve({
        name: file.name,
        size: file.size,
        type: file.type,
        base64: reader.result
      });
    };

    reader.onerror = reject;

    reader[method](file);
  });
};

export const getFileType = file => {
  return file?.match(/(^data:)(.*?)(?=\/)/)?.[0].replace('data:', '') || 'file';
};

export const downloadFile = (file, text) => {
  const link = document.createElement('a');
  link.setAttribute('href', file);
  link.setAttribute('download', text);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const isJSON = value => {
  try {
    JSON.parse(value);
    return true;
  } catch (error) {
    return false;
  }
};

export const parseJSON = value => {
  try {
    return JSON.parse(value);
  } catch (error) {
    return undefined;
  }
};
