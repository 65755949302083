import React, { useRef } from 'react';
import { useHistory } from 'react-router';

import {
  PasswordChangeModalFormFooterAction,
  PasswordChangeModalFormFooterActions,
  StyledPasswordChangeModal
} from './PasswordChangeModal.styles';

import { ROUTES } from '_constants';

import { PasswordChangeForm } from '../Form';

import { useAuthActions } from 'state';
import { useRequest } from 'hooks';
import { API } from 'api';

export const PasswordChangeModal = ({ isSafe = true, onSubmit, ...props }) => {
  const rootRef = useRef();

  const history = useHistory();
  const { updatePassword, signOut } = useAuthActions();
  const { error, isLoading, makeRequest, updateRequestState } = useRequest();

  const handleFormSubmit = async values => {
    try {
      await makeRequest(API.changePassword(values));
      updatePassword(values.newPassword);
      onSubmit?.(values);
    } catch (error) {
      updateRequestState({ error });
    }
  };

  return (
    <StyledPasswordChangeModal
      {...props}
      ref={rootRef}
      heading='Change password'
      closeOnClickOutside={isSafe}
      withToggle={isSafe}
    >
      <PasswordChangeForm
        error={error}
        isLoading={isLoading}
        isPreloaderTransparent
        onCancel={isSafe && (() => rootRef.current?.closeModal?.())}
        onSubmit={handleFormSubmit}
        footerChildren={
          <PasswordChangeModalFormFooterActions>
            {!isSafe && (
              <PasswordChangeModalFormFooterAction onClick={signOut}>
                Log out
              </PasswordChangeModalFormFooterAction>
            )}

            <PasswordChangeModalFormFooterAction
              onClick={() => history.push(ROUTES.PASSWORD_RESET)}
            >
              Forgot password
            </PasswordChangeModalFormFooterAction>
          </PasswordChangeModalFormFooterActions>
        }
      />
    </StyledPasswordChangeModal>
  );
};
