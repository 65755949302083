import React, { useEffect, useRef, useState } from 'react';
import { FiImage } from 'react-icons/fi';

import {
  InputImagePreview,
  InputImagePreviewIcon,
  InputImagePreviewImage,
  InputImageUploader,
  InputImageUploaderActionRemove,
  InputImageUploaderActionUpload,
  InputImageUploaderError,
  InputImageUploaderInput,
  StyledInputImage
} from './InputImage.styles';

const INITIAL_IMAGE = { src: '', file: null };

export const InputImage = ({
  value: initialImage = INITIAL_IMAGE,
  withUploader = false,
  onChange,
  ...props
}) => {
  const inputRef = useRef(null);
  const [image, setImage] = useState(initialImage);
  const [error, setError] = useState('');

  useEffect(() => initialImage ?? setImage(initialImage), [initialImage]);

  const handleImageChange = image => {
    setImage(image);
    onChange?.(image);
  };

  const handleUploaderInputChange = event => {
    const [file] = event.target.files;
    if (!file) return;

    if (file.size > 500000) {
      inputRef.current.value = '';
      setError('Please upload a picture smaller than 500 KB.');
      return setImage(INITIAL_IMAGE);
    }

    if (error) setError('');

    const reader = new FileReader();

    reader.onload = () => {
      const newImage = { src: reader.result, file };
      setImage(newImage);
      handleImageChange(newImage);
    };

    reader.readAsDataURL(file);
  };

  const handleImageRemove = () => {
    inputRef.current.value = '';
    setError('');
    handleImageChange(INITIAL_IMAGE);
  };

  const renderUploader = () => {
    return (
      <InputImageUploader>
        {error && <InputImageUploaderError>{error}</InputImageUploaderError>}

        <InputImageUploaderActionUpload onClick={() => inputRef.current?.click()}>
          Upload an Image
        </InputImageUploaderActionUpload>

        <InputImageUploaderActionRemove onClick={handleImageRemove}>
          Remove photo
        </InputImageUploaderActionRemove>

        <InputImageUploaderInput
          ref={inputRef}
          type='file'
          accept='image/*'
          multiple={false}
          onChange={handleUploaderInputChange}
        />
      </InputImageUploader>
    );
  };

  return (
    <StyledInputImage {...props}>
      <InputImagePreview>
        {image.src ? (
          <InputImagePreviewImage src={image.src} />
        ) : (
          <InputImagePreviewIcon>
            <FiImage />
          </InputImagePreviewIcon>
        )}
      </InputImagePreview>

      {withUploader && renderUploader()}
    </StyledInputImage>
  );
};
