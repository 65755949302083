import React, { forwardRef } from 'react';

import { LinkBody, LinkIcon, StyledLink } from './Link.styles';

export const Link = forwardRef(
  ({ children, icon, iconSize = 'sx', iconPosition = 'left', ...props }, ref) => {
    const hasChildren = !!children;

    return (
      <StyledLink
        {...props}
        ref={ref}
        icon={icon}
        iconSize={iconSize}
        iconPosition={iconPosition}
        hasChildren={hasChildren}
      >
        {icon && <LinkIcon position={iconPosition}>{icon}</LinkIcon>}
        {children && <LinkBody>{children}</LinkBody>}
      </StyledLink>
    );
  }
);
