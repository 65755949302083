import React from 'react';
import { startCase } from 'lodash-es';

import { StyledUserInfo } from './UserInfo.styles';

import { USER_KEYS } from '_constants';

import { Field } from 'components/_shared/Field';

import { formatUserProfileLabel, pickUserProfileFields } from 'utils';

export const UserInfo = ({ children, user = {}, ...props }) => {
  const fields = Object.entries(pickUserProfileFields(user));

  const renderFields = () => {
    return fields.map(([key, value]) => {
      const keyValue = `${key}${value}`;

      if (key === USER_KEYS.AVATAR) {
        return (
          <Field
            key={keyValue}
            type='image'
            label='Profile photo'
            control={{ value: { src: value } }}
          />
        );
      }

      if (!value || key === USER_KEYS.ID) return null;

      return (
        <Field
          key={keyValue}
          type='read'
          label={startCase(formatUserProfileLabel(key))}
          control={{ value }}
        />
      );
    });
  };

  return (
    <StyledUserInfo {...props}>
      {renderFields()}
      {children}
    </StyledUserInfo>
  );
};
