import styled, { css } from 'styled-components';

import { em } from 'styles';

export const StyledGroupInfo = styled.div`
  ${({ theme }) => css`
    position: relative;
    flex: 1;

    > * {
      &:not(:first-child) {
        margin-top: ${em(theme.spacing.ml)};
      }
    }
  `}
`;
