import { useCallback, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { isArray, pick } from 'lodash-es';
import deepEqual from 'fast-deep-equal';

import { MESSAGE_ACTION_TYPES, SOCKET_EVENT_TYPES } from '_constants';

import { notify } from 'components/Notification';

import { filterTag, getCookieAuth, getMatchedTags, URItoObject } from 'utils';
import { makeSelectGroupsMeta, makeSelectUsersMeta } from 'state';

export const useNotification = () => {
  const auth = useMemo(() => getCookieAuth(), []);

  const checkIsCurrentUser = useCallback(
    (ids = []) => {
      return (isArray(ids) ? ids : [ids]).some(id => id === auth.userId);
    },
    [auth.userId]
  );

  const selectUsersMeta = useMemo(makeSelectUsersMeta, []);
  const usersMeta = useSelector(selectUsersMeta, deepEqual);

  const selectGroupsMeta = useMemo(makeSelectGroupsMeta, []);
  const groupsMeta = useSelector(selectGroupsMeta, deepEqual);

  const notifySocketEvent = useRef(({ data, body }) => {
    const isCurrentUser = checkIsCurrentUser(
      Object.values(pick(body, ['ReceiverID', 'NewUserID', 'RemovedUserID', 'ContactUserID']))
    );

    const user = usersMeta[body.UserID];
    const group = groupsMeta[body.ChatID];

    const eventHandlers = {
      [SOCKET_EVENT_TYPES.USER_NEW]: () => {},

      [SOCKET_EVENT_TYPES.USER_REMOVE]: () => {
        if (isCurrentUser) {
          notify(`${user.username} removed you from contacts.`);
        }
      },

      [SOCKET_EVENT_TYPES.USER_STATUS]: () => {},

      [SOCKET_EVENT_TYPES.USER_REQUEST_SEND]: () => {},

      [SOCKET_EVENT_TYPES.USER_REQUEST_RESPONSE]: () => {
        if (isCurrentUser) {
          if (body.Accept) {
            notify(`${user.username} has accepted your contact request.`);
          } else {
            notify(`${user.username} declined your contact request.`);
          }
        }
      },

      [SOCKET_EVENT_TYPES.GROUP_USER_NEW]: () => {
        if (isCurrentUser) {
          if (group?.name) {
            notify(`You have been added to the "${group.name}" group.`);
          } else {
            notify(`You have been added to a new group.`);
          }
        }
      },

      [SOCKET_EVENT_TYPES.GROUP_USER_REMOVE]: () => {
        if (isCurrentUser) {
          if (group?.name) {
            notify(`You have been removed from the "${group.name}" group.`);
          } else {
            notify(`You have been removed from the group.`);
          }
        }
      },

      [SOCKET_EVENT_TYPES.MESSAGE_NEW]: () => {
        const encodedBody = URItoObject(body.MessageBody || body.Body);
        const sender = usersMeta[body.SenderID];

        if (checkIsCurrentUser([encodedBody.prevMessageAuthorId])) {
          if (encodedBody.actionType === MESSAGE_ACTION_TYPES.REPLY) {
            notify(`${sender ? `${sender.username} ` : ''}replied to your message.`);
          }
        }

        const isMatchCurrentUser = !!getMatchedTags(encodedBody.text)?.some(tag => {
          return filterTag(tag) === auth.username;
        });

        if (!isMatchCurrentUser) return;

        notify(`You have been mentioned${sender ? ` by ${sender.username}` : ''}.`);
      },

      [SOCKET_EVENT_TYPES.MESSAGE_EDIT]: () => {},

      [SOCKET_EVENT_TYPES.MESSAGE_DELETE]: () => {}
    };

    eventHandlers[data.EventID]?.();
  });

  return { notifySocketEvent: notifySocketEvent.current };
};
