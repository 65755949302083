import { TAG_MATCH_REGEXP, TAG_TRIGGERS, TAG_TRIGGERS_VALUES } from '_constants';
import { getCookieAuth } from './auth';

import { findKeyByValue } from './helpers';

export const isMatchTag = text => TAG_MATCH_REGEXP.test(text);

export const createTag = (text, type) => `${TAG_TRIGGERS[type]}${text}`.trim();

export const getMatchedTags = text => text?.match(TAG_MATCH_REGEXP)?.map(tag => tag.trim());

export const getTagTrigger = tag => tag[0];

export const getTagKeyword = tag => tag.slice(1);

export const getTagEntries = tag => [getTagTrigger(tag), getTagKeyword(tag)];

export const filterTag = tag => getTagKeyword(tag.replace('>', ''));

export const getLastTriggerEntries = text => {
  return TAG_TRIGGERS_VALUES.reduce(
    (prevTrigger, trigger) => {
      const index = text.lastIndexOf(trigger);
      return index > prevTrigger[0] ? [index, trigger] : prevTrigger;
    },
    [0, '']
  );
};

export const getLastTagEntries = (text = '') => {
  const textLength = text.length;
  const [lastTriggerIndex] = getLastTriggerEntries(text);

  const isStart = textLength === textLength - lastTriggerIndex;
  const slicedText = text.slice(lastTriggerIndex - (isStart ? 0 : 1));

  const tag = getMatchedTags(slicedText);
  return tag ? [text.lastIndexOf(tag), tag[0], findKeyByValue(TAG_TRIGGERS, tag[0][0])] : [];
};

export const highlightTags = text => {
  try {
    const currentUser = getCookieAuth();

    const classNames = {
      user: 'tag-user-mention',
      currentUser: 'tag-user-mention-current'
    };

    const usernames = (getMatchedTags(text) || []).map(text => text.replace('>', ''));

    return usernames.reduce((text, username) => {
      const isCurrentUser = username.slice(1) === currentUser.username;
      const className = classNames[isCurrentUser ? 'currentUser' : 'user'];

      return text.replace(
        new RegExp(username, 'g'),
        `<span class='${className}'>${username}</span>`
      );
    }, text);
  } catch (_error) {
    return text;
  }
};

export const withTagsHighlight = (text = '') => highlightTags(text);
