import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import deepEqual from 'fast-deep-equal';
import { debounce } from 'lodash-es';

import { StyledChatMessageList } from './ChatMessageList.styles';

import { MESSAGE_ACTION_TYPES, MODAL_KEYS, ROUTES } from '_constants';

import { selectCurrentUser, useChatActions, useModal } from 'state';
import { mergeProps } from 'utils';

export const ChatMessageList = ({ contactId, messageProps, ...props }) => {
  const currentUser = useSelector(selectCurrentUser, deepEqual);

  const {
    setEditorAction,
    resetEditorAction,
    removeMessage,
    pinMessage,
    unpinMessage,
    setSelectedMessages: chatSetSelectedMessages,
    resendMessage,
    deleteMessage
  } = useChatActions();

  const history = useHistory();
  const { openModal } = useModal();
  const [selectedMessages, setSelectedMessages] = useState([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSelectedMessagesChange = useCallback(
    debounce(selectedMessages => {
      chatSetSelectedMessages(contactId, selectedMessages);
    }, 600),
    [contactId, chatSetSelectedMessages]
  );

  useEffect(() => {
    handleSelectedMessagesChange(selectedMessages);
  }, [selectedMessages, handleSelectedMessagesChange]);

  const handleMessageSelect = (_, message) => {
    if (message.isModified) return;
    setSelectedMessages(prev => [...prev, message.id]);
  };

  const handleMessageUnSelect = (_, message) => {
    setSelectedMessages(prev => prev.filter(id => id !== message.id));
  };

  const getMessageProps = message => {
    const isMessageAuthor = message?.userId === currentUser.id;
    message.isMessageAuthor = isMessageAuthor;

    const props = messageProps?.(message) || {};

    const mergedProps = mergeProps(
      {
        onCancelClick: deleteMessage,
        onResendClick: () => resendMessage(contactId, message),
        onSelect: target => handleMessageSelect(target, message),
        onUnSelect: target => handleMessageUnSelect(target, message),
        onAuthorClick: user => {
          history.push({
            pathname: ROUTES.USER_BY_ID(user.id),
            state: { prevLocation: history.location }
          });
        },
        actions: {
          onPinClick: !message.isPinned && (() => pinMessage(contactId, message.id)),
          onUnpinClick: message.isPinned && (() => unpinMessage(contactId, message.id)),
          onEditClick:
            isMessageAuthor &&
            (() => {
              setEditorAction(contactId, {
                editor: {
                  value: message.text,
                  preview: {
                    heading: 'Edit message',
                    isOpen: true,
                    onClose: () => resetEditorAction(contactId)
                  }
                },
                action: {
                  message,
                  type: MESSAGE_ACTION_TYPES.EDIT
                }
              });
            }),
          onReplyClick: () => {
            setEditorAction(contactId, {
              editor: {
                value: '',
                preview: {
                  heading: `Reply to the ${message.user.username} message`,
                  text: message.text,
                  isOpen: true,
                  onClose: () => resetEditorAction(contactId)
                }
              },
              action: {
                message,
                type: MESSAGE_ACTION_TYPES.REPLY
              }
            });
          },
          onDeleteClick:
            isMessageAuthor &&
            (() => {
              openModal(MODAL_KEYS.CONFIRM, {
                heading: `Are you sure you want to remove a message?`,
                actions: {
                  submit: {
                    children: 'Remove',
                    onClick: async () => {
                      await removeMessage(contactId, message);
                    }
                  }
                }
              });
            })
        }
      },
      props
    );

    return mergedProps;
  };

  return <StyledChatMessageList {...props} messageProps={getMessageProps} />;
};
