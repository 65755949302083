import styled, { css } from 'styled-components';
import ScrollableFeed from 'react-scrollable-feed';

import { MessageCard } from '../Card';

import { rem } from 'styles';

export const MessageListItem = styled(MessageCard)`
  ${({ theme }) => css`
    &:not(:first-of-type) {
      margin-top: ${rem(theme.spacing.xs)};
    }
  `}
`;

export const StyledMessageList = styled(ScrollableFeed)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
