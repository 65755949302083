import React from 'react';
import { useSelector } from 'react-redux';

import { StyledSidebarMenu } from './SidebarMenu.styles';

import { ROUTES, USER_KEYS } from '_constants';

import { Button } from 'components/_shared/Button';
import { Link } from 'components/_shared/Link';

import { selectCurrentUser, useAuthActions } from 'state';

export const SidebarMenu = () => {
  const { signOut } = useAuthActions();

  const currentUser = useSelector(selectCurrentUser);

  return (
    <StyledSidebarMenu
      items={[
        <Link key='userProfile' to={ROUTES.USER_BY_ID(currentUser[USER_KEYS.ID])}>
          User profile
        </Link>,

        <Link key='groupAdd' to={ROUTES.GROUP_ADD}>
          Add group
        </Link>,

        <Link key='settings' to={ROUTES.SETTINGS}>
          Settings
        </Link>,

        <Button key='signOut' onClick={signOut}>
          Log out
        </Button>
      ]}
    />
  );
};
