import styled, { css } from 'styled-components';

import {
  Form,
  FormActions,
  FormBody,
  FormGroupItem,
  StyledFormGroup
} from 'components/_shared/Form';

import { em, rem } from 'styles';

export const StyledUserFormEdit = styled(Form)`
  ${({ theme }) => css`
    ${FormBody} > ${StyledFormGroup} {
      > ${FormGroupItem} {
        &:first-of-type {
          max-width: ${rem(320)};
        }

        &:nth-of-type(2) {
          padding-left: ${em(theme.spacing.sm)};
        }
      }
    }

    ${FormActions} {
      margin-top: ${em(theme.spacing.lm)};
    }
  `}
`;
