import { cloneElement, forwardRef, memo } from 'react';
import styled, { css } from 'styled-components';
import cn from 'classnames';

import { StyledEditorActionSubmit } from '../Action';

import { rem } from 'styles';

export const EditorToolbarAction = styled(
  memo(
    forwardRef(({ children: child, ...props }, ref) => {
      const className = cn(child.props.className, props.className);
      return cloneElement(child, { ...props, ref, className });
    })
  )
)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;

    &${StyledEditorActionSubmit} {
      height: 100%;
      width: ${rem(48)};
      margin-left: ${rem(theme.spacing.xs)};
    }
  `}
`;

export const StyledEditorToolbar = styled.div`
  ${({ theme }) => {
    const childSpacing = theme.spacing.x / 2;

    return css`
      &.ql-toolbar {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        height: ${rem(40)};
        min-height: ${rem(40)};
        background: ${theme.colors.light.shade};
        padding: ${rem([0, theme.spacing.sm])};
        padding-left: ${rem(childSpacing * 4)};
      }

      ${EditorToolbarAction}:not(${StyledEditorActionSubmit}) {
        margin: ${rem(childSpacing)};
      }
    `;
  }}
`;
