import React, { useCallback, useRef, useState } from 'react';
import { Picker } from 'emoji-mart';
import { FiSmile } from 'react-icons/fi';

import { StyledEditorActionEmoji, EditorActionEmojiButton } from './EditorActionEmoji.styles';

import { useClickOutside } from 'hooks';

export const EditorActionEmoji = ({ icon = <FiSmile />, editorRef, ...props }) => {
  const pickerRef = useRef(null);
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);

  const handleEmojiSelect = useCallback(
    emoji => {
      if (!editorRef) return;
      const editor = editorRef.getEditor();
      const range = editor.getSelection();
      const position = range ? range.index : 0;
      editor.insertText(position, emoji.native);
    },
    [editorRef]
  );

  useClickOutside(pickerRef, () => setIsEmojiPickerOpen(false));

  return (
    <StyledEditorActionEmoji ref={pickerRef} isEmojiPickerOpen={isEmojiPickerOpen}>
      <EditorActionEmojiButton
        {...props}
        icon={icon}
        onClick={() => setIsEmojiPickerOpen(isOpen => !isOpen)}
      />

      <Picker title='' emoji='' native perLine={8} emojiSize={18} onSelect={handleEmojiSelect} />
    </StyledEditorActionEmoji>
  );
};
