import { css } from 'styled-components';
import './quill.css';

export const getQuillIndentStyle = ({ tag = '', initial = 0, step = 1.5 } = {}) => {
  const indentLevels = Array.from(Array(8), (_, i) => ++i);

  return indentLevels.reduce(
    ([prevPadding, prevStyle], indentLevel) => {
      const padding = prevPadding + step;
      const style = `${tag}.ql-indent-${indentLevel} { padding-left: ${padding}em; }`;

      return [padding, `${prevStyle}; ${style}`];
    },
    [initial, '']
  )[1];
};

export const quill = css`
  ${({ theme }) => css`
    .ql {
      &-container,
      &-editor,
      &-toolbar {
        font: inherit;
        color: inherit;
        border: none;
        padding: 0;
        margin: 0;
      }

      &-editor {
        strong {
          font-weight: bold;
        }

        em {
          font-style: italic;
        }

        ul,
        ol {
          padding: 0;
          margin: 0;
        }

        &.ql-blank {
          &::before {
            font: inherit;
            color: ${theme.colors.dark.tone};
          }
        }

        ${getQuillIndentStyle()};
        ${getQuillIndentStyle({ tag: 'li', initial: 1.5 })};
      }
    }
  `}
`;
