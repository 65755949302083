import styled, { css } from 'styled-components';
import { lighten } from 'polished';

import { Typography } from 'components/_shared/Typography';
import { Button } from 'components/_shared/Button';

import { em, rem } from 'styles';

export const MessagePreviewText = styled(Typography).attrs({ element: 'p', size: 'xs' })`
  ${({ theme, size }) => css`
    position: relative;
    padding-left: ${em(theme.spacing.xs, theme.fontSizes[size])};

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: ${em(1.6, theme.fontSizes[size])};
      height: 100%;
      background: ${theme.colors.dark.tint};
    }
  `}
`;

export const MessagePreviewHeading = styled(Typography).attrs({ element: 'h5', size: 'xs' })`
  ${({ theme }) => css`
    font-weight: ${theme.fontWeights.medium};
    color: ${theme.colors.dark.tone};

    + * {
      margin-top: ${em(theme.spacing.xs)};
    }
  `}
`;

export const MessagePreviewToggle = styled(Button).attrs({ iconSize: 'sm' })`
  ${({ theme }) => css`
    position: absolute;
    top: ${rem(theme.spacing.xs)};
    right: ${rem(theme.spacing.xs)};
    color: ${theme.colors.dark.tone};
  `}
`;

export const StyledMessagePreview = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 100%;
    max-width: 100%;
    background: ${theme.colors.light.hue};
    padding: ${rem([theme.spacing.sx])};
    transition: ${theme.transitions.fast};

    &.onClick {
      &:hover {
        background: ${lighten(0.53, theme.colors.primary.hue)};
        cursor: pointer;
      }
    }
  `}
`;
