import { lighten } from 'polished';

import { cloneTheme } from 'styles/utils';
import { themeDefault } from './theme.default';

export const themeGray = cloneTheme(themeDefault, {
  colors: {
    primary: {
      hue: '#283553',
      tone: '#222D46'
    },

    message: {
      card: {
        active: lighten(0.6, '#283553')
      }
    },

    indicator: {
      count: {
        foreground: '#ffffff',
        background: '#FD3368'
      }
    }
  }
});
