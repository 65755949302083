import styled, { css } from 'styled-components';

import { Icon } from 'components/_shared/Icon';
import { Typography } from 'components/_shared/Typography';

import { em } from 'styles';

export const LogoTitle = styled(Typography).attrs({ element: 'h1', size: 'sx' })`
  ${({ theme, size }) => css`
    color: ${theme.colors.light.hue};
    margin-left: ${em(theme.spacing.xs, theme.fontSizes[size])};
  `}
`;

export const LogoIcon = styled(Icon)`
  ${({ theme }) => css`
    color: ${theme.colors.secondary.hue};
    transform: scaleX(-1);
  `}
`;

export const StyledLogo = styled.div`
  display: flex;
  align-items: center;
`;
