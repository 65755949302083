import React, { useEffect, useState } from 'react';
import { HiDotsVertical } from 'react-icons/hi';
import { isUndefined } from 'lodash-es';

import { MenuDropdown, MenuList, MenuListItem, MenuToggle, StyledMenu } from './Menu.styles';

import { getStyledComponentClassName } from 'utils';

export const Menu = React.forwardRef(
  ({ items = [], isOpen: initialIsOpen, onClose, onItemClick, onClickOutside, ...props }, ref) => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => !isUndefined() && setIsOpen(initialIsOpen), [initialIsOpen]);

    const handleMenuToggleClick = event => {
      event.stopPropagation();
      setIsOpen(isOpen => !isOpen);
    };

    const handleChildClick = event => {
      event.stopPropagation();
      onItemClick?.(event);
    };

    const handleClose = () => {
      setIsOpen(false);
      onClose?.();
    };

    const renderMenuList = () => {
      return (
        <MenuList>
          {items.map((item, i) => (
            <MenuListItem key={item.key || i}>{item}</MenuListItem>
          ))}
        </MenuList>
      );
    };

    return (
      <StyledMenu {...props} ref={ref}>
        <MenuToggle icon={<HiDotsVertical />} onClick={handleMenuToggleClick} />

        <MenuDropdown
          isOpen={isOpen}
          onChildClick={handleChildClick}
          onClickOutside={onClickOutside}
          onClose={handleClose}
          clickOutsideParams={{
            ignoredClassNames: [getStyledComponentClassName(MenuToggle)]
          }}
        >
          {renderMenuList()}
        </MenuDropdown>
      </StyledMenu>
    );
  }
);
