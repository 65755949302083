import styled, { css } from 'styled-components';

import { Page } from 'components/_shared/Page';
import { LayoutMain } from 'components/Layout';
import { StyledAuthForm } from 'components/Auth';
import { FieldMessageError } from 'components/_shared/Field';
import { FormMessageError } from 'components/_shared/Form';

import { rem } from 'styles';

export const StyledPageAuth = styled(Page)`
  ${({ theme }) => css`
    background: ${theme.colors.primary.hue};

    ${LayoutMain} {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: ${rem(theme.spacing['2xl'])};
      padding-bottom: ${rem(theme.spacing['2xl'] * 2)};
    }

    ${StyledAuthForm} {
      color: ${theme.colors.light.hue};
      max-width: ${rem(266)};
      margin: auto;
    }

    ${FormMessageError},
    ${FieldMessageError} {
      color: ${theme.colors.warning.hue};
    }
  `}
`;
