import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react';
import { useSelector } from 'react-redux';

import { StyledChatMessageEditor } from './ChatMessageEditor.styles';

import { MESSAGE_ACTION_TYPES, TAG_TRIGGERS } from '_constants';

import { TagContact } from 'components/Tag';

import { makeSelectChatEditorAction, makeSelectChatUsers, useChatActions } from 'state';
import { getTagEntries, stripHTML } from 'utils';

const getMentionTriggerPositionLeft = editor => {
  if (!editor) return 0;
  const lastIndexOfTriggerChar = editor.getText().lastIndexOf(TAG_TRIGGERS.MENTION);
  if (lastIndexOfTriggerChar < 0) return 0;
  return editor.getBounds(lastIndexOfTriggerChar).left;
};

export const ChatMessageEditor = forwardRef(({ contactId, ...props }, ref) => {
  const rootRef = useRef(null);
  useImperativeHandle(ref, () => rootRef.current);

  const editorText = rootRef.current?.editor.getText();
  const tagsOffset = { left: getMentionTriggerPositionLeft(rootRef.current?.editor) };

  const { sendMessage, editMessage, resetEditorAction } = useChatActions();

  const selectChatUsers = useMemo(makeSelectChatUsers, []);
  const chatUsers = useSelector(state => selectChatUsers(state, contactId));

  const selectChatEditorAction = useMemo(makeSelectChatEditorAction, []);
  const chatEditorAction = useSelector(state => selectChatEditorAction(state, contactId));

  const chatEditorValue = chatEditorAction.editor.value;
  const chatEditorFiles = chatEditorAction.editor.files;

  const [value, setValue] = useState(chatEditorValue);
  useEffect(() => setValue(chatEditorValue), [contactId, chatEditorValue]);

  const [files, setFiles] = useState([...chatEditorFiles]);
  useEffect(() => setFiles([...chatEditorFiles]), [contactId, chatEditorFiles]);

  const handleSubmit = text => {
    if ((!text || !stripHTML(text)?.length) && !files?.length) return;

    if (chatEditorAction.action.type === MESSAGE_ACTION_TYPES.SEND) {
      sendMessage(contactId, { text, files });
    }

    if (chatEditorAction.action.type === MESSAGE_ACTION_TYPES.EDIT) {
      editMessage(contactId, { text }, chatEditorAction.action.message);
    }

    if (chatEditorAction.action.type === MESSAGE_ACTION_TYPES.REPLY) {
      sendMessage(contactId, {
        text,
        files,
        actionType: MESSAGE_ACTION_TYPES.REPLY,
        prevMessageId: chatEditorAction.action.message.id,
        prevMessageAuthorId: chatEditorAction.action.message.userId
      });
    }

    setValue('');
    resetEditorAction(contactId);
  };

  const handleTag = tag => {
    const [tagTrigger] = getTagEntries(tag);
    const { editor } = rootRef.current;
    const selection = editor.getSelection(true);
    const lastTriggerIndex = editor.getText().lastIndexOf(tagTrigger);
    editor.deleteText(lastTriggerIndex, selection.index);
    editor.insertText(editor.getSelection(true), tag);
  };

  return (
    <StyledChatMessageEditor
      {...props}
      ref={rootRef}
      value={value}
      files={files}
      actionsProps={{ submit: { actionType: chatEditorAction.action.type } }}
      preview={chatEditorAction.editor.preview}
      onChange={setValue}
      onFilesChange={setFiles}
      onSubmit={handleSubmit}
    >
      <TagContact
        contacts={chatUsers}
        value={editorText}
        positionOffset={tagsOffset}
        onTag={handleTag}
      />
    </StyledChatMessageEditor>
  );
});
