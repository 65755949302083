import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { StyledChatIndicatorUpdates } from './ChatIndicatorUpdates.styles';

import { selectSomeChatHasUpdates } from 'state';

export const ChatIndicatorUpdates = ({ contactType, ...props }) => {
  const someChatHasUpdates = useMemo(selectSomeChatHasUpdates, []);
  const hasUpdates = useSelector(state => someChatHasUpdates(state, contactType));

  return <StyledChatIndicatorUpdates {...props} hasUpdates={hasUpdates} />;
};
