import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { map, pick } from 'lodash-es';

import { groupActions } from './group.slice';

import { API } from 'api';
import { AUTH_KEYS, CONTACT_TYPES, GROUP_KEYS } from '_constants';

import { chatActions, useChatActions } from 'state/chat';
import { useRequest } from 'hooks';
import { getCookieAuth, handleResponseError, removeFalsyValues } from 'utils';

export const useGroupActions = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { isLoading: isRequestLoading, makeRequest } = useRequest();

  const { addChat } = useChatActions();

  const auth = useMemo(() => pick(getCookieAuth(), [AUTH_KEYS.USER_ID, AUTH_KEYS.USERNAME]), []);

  const getGroupData = useCallback(
    async (id, group = {}) => {
      try {
        const withCurrentUser = !!group[GROUP_KEYS.WITH_CURRENT_USER];

        const [profile = {}, users = [], messages = []] = await Promise.all([
          API.getGroupProfile(id),
          withCurrentUser ? API.getGroupUsers(id) : [],
          withCurrentUser ? API.getGroupMessages(id) : []
        ]).then(responses => responses.map(({ data }) => data));

        const _group = {
          ...profile,
          ...group,
          messages,
          [GROUP_KEYS.ID]: id,
          [GROUP_KEYS.WITH_CURRENT_USER]: withCurrentUser,
          [GROUP_KEYS.USERS]: map(users, 'UserID').concat(
            withCurrentUser ? auth[AUTH_KEYS.USER_ID] : []
          )
        };

        return _group;
      } catch (error) {
        handleResponseError(error);
      }
    },
    [auth]
  );

  const getGroupProfile = useCallback(
    async id => {
      try {
        const response = await makeRequest(API.getGroupProfile(id));
        const groupProfile = { ...response.data, [GROUP_KEYS.ID]: id };
        dispatch(groupActions.updateEntity({ entity: groupProfile }));
        return groupProfile;
      } catch (error) {
        handleResponseError(error);
      }
    },
    [dispatch, makeRequest]
  );

  const addGroup = useCallback(
    async group => {
      try {
        const response = await makeRequest(API.addGroup(group));
        const newGroup = { ...group, ...response.data, [GROUP_KEYS.WITH_CURRENT_USER]: true };

        dispatch(groupActions.addEntity(newGroup));

        addChat({
          id: newGroup.ChatID,
          messages: [],
          users: newGroup.users,
          contact: {
            id: newGroup.ChatID,
            type: CONTACT_TYPES.GROUP,
            chatId: newGroup.ChatID
          }
        });

        return newGroup;
      } catch (error) {
        handleResponseError(error);
      }
    },
    [dispatch, makeRequest, addChat]
  );

  const updateGroup = useCallback(
    async group => {
      try {
        await makeRequest(API.updateGroupProfile(group));
        dispatch(groupActions.updateEntity({ entity: group }));
      } catch (error) {
        handleResponseError(error);
      }
    },
    [dispatch, makeRequest]
  );

  const addGroupUsers = useCallback(
    async (id, usersId) => {
      try {
        setIsLoading(true);
        await Promise.all(usersId.map(userId => API.addGroupUser({ [GROUP_KEYS.ID]: id, userId })));
        dispatch(groupActions.addEntityUsers({ [GROUP_KEYS.ID]: id, [GROUP_KEYS.USERS]: usersId }));
      } catch (error) {
        handleResponseError(error);
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch]
  );

  const removeGroupUser = useCallback(
    async (id, userId) => {
      try {
        await makeRequest(API.removeGroupUser({ [GROUP_KEYS.ID]: id, userId }));
        dispatch(groupActions.removeEntityUser({ id, userId }));
      } catch (error) {
        handleResponseError(error);
      }
    },
    [dispatch, makeRequest]
  );

  const getGroups = useCallback(async () => {
    setIsLoading(true);

    try {
      const [groups, userGroups] = await Promise.all([
        API.getGroupList({ withFullList: true }),
        API.getGroupList()
      ]).then(responses => responses.map(({ data }) => data));

      const chats = [];

      const entities = (
        await Promise.all(
          groups.map(async group => {
            try {
              const withCurrentUser = !!userGroups.find(({ ChatID }) => ChatID === group.ChatID);

              if (!withCurrentUser) return undefined;

              const [users = [], messages = []] = await Promise.all([
                API.getGroupUsers(group.ChatID),
                API.getGroupMessages(group.ChatID)
              ]).then(responses => responses.map(({ data }) => data));

              const _users = users.map(({ UserID }) => UserID);

              const _group = {
                ...group,
                [GROUP_KEYS.USERS]: _users,
                [GROUP_KEYS.CONTACT_TYPE]: CONTACT_TYPES.GROUP
              };

              if (withCurrentUser) {
                _group[GROUP_KEYS.USERS] = [auth[AUTH_KEYS.USER_ID], ..._group[GROUP_KEYS.USERS]];
                _group[GROUP_KEYS.WITH_CURRENT_USER] = true;

                chats.push({
                  id: _group.ChatID,
                  messages,
                  users: _group.users,
                  contact: {
                    id: _group.ChatID,
                    type: CONTACT_TYPES.GROUP,
                    chatId: _group.ChatID
                  }
                });
              }

              return _group;
            } catch (error) {
              return group;
            }
          })
        )
      ).filter(Boolean);

      dispatch(groupActions.setEntities(entities));
      dispatch(chatActions.setEntities(chats));

      return async () => {
        if (!entities || !entities.length) return;

        const profiles = await Promise.all(
          entities.map(entity => API.getGroupProfile(entity.ChatID))
        ).then(profiles =>
          profiles.map(({ data }, i) => {
            data.id = entities[i].ChatID;
            return removeFalsyValues(data);
          })
        );

        if (!profiles.length) return;

        dispatch(groupActions.updateEntities(profiles));
      };
    } catch (error) {
      handleResponseError(error);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, auth]);

  return {
    isLoading: isLoading || isRequestLoading,
    getGroupData,
    getGroupProfile,
    addGroup,
    updateGroup,
    addGroupUsers,
    removeGroupUser,
    getGroups
  };
};
