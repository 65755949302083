import styled, { css } from 'styled-components';

import { EditorAction } from '../EditorAction';

export const EMOJI_KEY = 'emoji-mart';

export const editorActionEmojiPickerStyle = css`
  ${({ theme }) => css`
    .${EMOJI_KEY} {
      position: absolute;
      right: 0;
      bottom: 100%;
      transition: 0s;
      display: none;

      * {
        &:focus {
          outline: none;
        }
      }

      button,
      button[class*='emoji'] > span {
        &:hover {
          cursor: pointer;
        }
      }

      &-anchor {
        &-selected {
          color: ${theme.colors.primary.hue} !important;
        }

        &-bar {
          background-color: ${theme.colors.primary.hue} !important;
        }
      }
    }
  `}
`;

export const EditorActionEmojiButton = styled(EditorAction)``;

export const StyledEditorActionEmoji = styled.div`
  ${({ isEmojiPickerOpen }) => [
    css`
      position: relative;
    `,
    editorActionEmojiPickerStyle,
    isEmojiPickerOpen &&
      css`
        .${EMOJI_KEY} {
          display: inline-block;
        }
      `
  ]}
`;
