export const MESSAGE_KEY = 'message';

export const MESSAGE_KEYS = {
  ID: 'id',
  CHAT_ID: 'chatId',
  CONTACT_ID: 'contactId',
  USER_ID: 'userId',
  TEXT: 'text',
  BODY: 'body',
  DATE: 'date',
  FILE_ID: 'fileId',
  FILE: 'file',
  ACTION: 'action',
  STATUS: 'status',
  IS_MODIFIED: 'isModified',
  IS_PINNED: 'isPinned'
};

export const MESSAGE_ACTION_KEYS = {
  TEXT: 'text',
  ACTION_TYPE: 'type',
  ACTION_DATE: 'date',
  ACTION_AUTHOR_ID: 'authorId',
  AUTHOR_USERNAME: 'authorUsername',
  PREV_MESSAGE_ID: 'prevMessageId',
  PREV_MESSAGE_AUTHOR_ID: 'prevMessageAuthordId'
};

export const MESSAGE_ACTION_TYPES = {
  SEND: 'SEND',
  EDIT: 'EDIT',
  REPLY: 'REPLY',
  DELETE: 'DELETE'
};

export const MESSAGE_STATUSES = {
  SENDING: 'SENDING',
  SENDED: 'SENDED',
  ERROR: 'ERROR'
};

export const MESSAGE_KEYS_MAP = {
  [MESSAGE_KEYS.ID]: ['MessageGUID', 'MessageID', 'messageId'],
  [MESSAGE_KEYS.CHAT_ID]: ['ChatID', 'chatId'],
  [MESSAGE_KEYS.USER_ID]: ['UserGUID', 'UserID', 'SenderID'],
  [MESSAGE_KEYS.TEXT]: ['Text'],
  [MESSAGE_KEYS.BODY]: ['MessageBody', 'Body'],
  [MESSAGE_KEYS.FILE_ID]: ['AttachmentID', 'AttachmentGUID'],
  [MESSAGE_KEYS.DATE]: ['DateTime', 'Date']
};

export const MESSAGE_ACTIONS_KEYS_MAP = {
  [MESSAGE_ACTION_KEYS.ACTION_TYPE]: ['actionType'],
  [MESSAGE_ACTION_KEYS.ACTION_DATE]: ['actionDate'],
  [MESSAGE_ACTION_KEYS.ACTION_AUTHOR_ID]: ['actionAuthorId'],
  [MESSAGE_ACTION_KEYS.AUTHOR_USERNAME]: ['authorUsername'],
  [MESSAGE_ACTION_KEYS.PREV_MESSAGE_ID]: ['prevMessageId']
};

export const MESSAGE_ACTION_TEMPLATE = {
  [MESSAGE_ACTION_KEYS.ACTION_TYPE]: MESSAGE_ACTION_TYPES.SEND,
  [MESSAGE_ACTION_KEYS.ACTION_DATE]: '',
  [MESSAGE_ACTION_KEYS.ACTION_AUTHOR_ID]: '',
  [MESSAGE_ACTION_KEYS.AUTHOR_USERNAME]: '',
  [MESSAGE_ACTION_KEYS.PREV_MESSAGE_ID]: '',
  [MESSAGE_ACTION_KEYS.PREV_MESSAGE_AUTHOR_ID]: ''
};

export const MESSAGE_TEMPLATE = {
  [MESSAGE_KEYS.ID]: '',
  [MESSAGE_KEYS.CHAT_ID]: '',
  [MESSAGE_KEYS.USER_ID]: '',
  [MESSAGE_KEYS.TEXT]: '',
  [MESSAGE_KEYS.DATE]: '',
  [MESSAGE_KEYS.FILE_ID]: '',
  [MESSAGE_KEYS.FILE]: '',
  [MESSAGE_KEYS.ACTION]: MESSAGE_ACTION_TEMPLATE,
  [MESSAGE_KEYS.STATUS]: MESSAGE_STATUSES.SENDED,
  [MESSAGE_KEYS.IS_MODIFIED]: false,
  [MESSAGE_KEYS.IS_PINNED]: false
};

export const MESSAGE_ACTION_TYPES_MODIFIED = [
  MESSAGE_ACTION_TYPES.EDIT,
  MESSAGE_ACTION_TYPES.DELETE
];

export const MESSAGE_ACTION_TYPE_LABLES = {
  [MESSAGE_ACTION_TYPES.DELETE]: `Deleted at`,
  [MESSAGE_ACTION_TYPES.EDIT]: `Edited at`
};
