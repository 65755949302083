import React from 'react';
import { BsFillChatFill } from 'react-icons/bs';

import { StyledLogo, LogoIcon, LogoTitle } from './Logo.styles';

export const Logo = ({ size, withTitle = true, ...props }) => {
  return (
    <StyledLogo>
      <LogoIcon {...props} size={size}>
        <BsFillChatFill />
      </LogoIcon>

      {withTitle && <LogoTitle>BChat</LogoTitle>}
    </StyledLogo>
  );
};
