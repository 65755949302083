import styled from 'styled-components';

import { FilePreview } from '../Preview';
import { FileViewer } from '../Viewer';

export const FileViewViewer = styled(FileViewer)``;

export const FileViewPreview = styled(FilePreview)``;

export const StyledFileView = styled.div`
  position: relative;
`;
