import React from 'react';
import { pick } from 'lodash-es';

import { StyledUserMenu } from './UserMenu.styles';

import { CONTACT_TYPES, MODAL_KEYS, ROUTES, USER_KEYS, USER_STATUSES } from '_constants';

import { useChatActions, useModal, useUserActions } from 'state';

import { Link } from 'components/_shared/Link';
import { Button } from 'components/_shared/Button';

export const UserMenu = ({ user = {}, items, ...props }) => {
  const { requestUser, removeUser, acceptUserRequest, declineUserRequest } = useUserActions();
  const { auth, addChat, removeHistory } = useChatActions();
  const { openModal } = useModal();

  const userId = user[USER_KEYS.ID];
  const userStatus = user[USER_KEYS.STATUS];

  const handleContactRemove = () => {
    openModal(MODAL_KEYS.CONFIRM, {
      heading: `Are you sure you want to remove the ${user[USER_KEYS.USERNAME]} user?`,
      actions: {
        submit: {
          children: 'Remove',
          onClick: async () => {
            await removeUser(userId);
          }
        }
      }
    });
  };

  const handleContactRequestCancel = () => {
    openModal(MODAL_KEYS.CONFIRM, {
      heading: `Are you sure you want to cancel the request?`,
      actions: {
        submit: {
          children: 'Confirm',
          onClick: async () => {
            await removeUser(userId);
          }
        }
      }
    });
  };

  const handleHistoryRemove = () => {
    openModal(MODAL_KEYS.CONFIRM, {
      heading: `Delete history on your device only?`,
      actions: {
        submit: {
          children: 'Delete',
          onClick: async () => {
            await removeHistory(userId);
          }
        }
      }
    });
  };

  const menuItems = {
    requestSend: <Button onClick={() => requestUser(userId)}>Send request</Button>,
    requestAccpet: (
      <Button
        onClick={() => {
          acceptUserRequest(userId);
          addChat({
            id: user.id,
            messages: [],
            users: [auth.userId, user.id],
            contact: {
              id: user.id,
              type: CONTACT_TYPES.USER,
              chatId: user.chatId
            }
          });
        }}
      >
        Accept
      </Button>
    ),
    requestDecline: <Button onClick={() => declineUserRequest(userId)}>Decline</Button>,
    requestCancel: <Button onClick={handleContactRequestCancel}>Cancel request</Button>,
    profile: <Link to={ROUTES.USER_BY_ID(userId)}>User profile</Link>,
    remove: <Button onClick={handleContactRemove}>Remove user</Button>,
    historyDeleteLocally: (
      <Button onClick={handleHistoryRemove}>Delete history on your device only</Button>
    )
  };

  const getDefaultMenuItems = () => {
    if (userStatus === USER_STATUSES.AVAILABLE) {
      return ['requestSend'];
    }

    if (userStatus === USER_STATUSES.WAITING) {
      return ['profile', 'requestAccpet', 'requestDecline'];
    }

    if (userStatus === USER_STATUSES.REQUESTED) {
      return ['requestCancel'];
    }

    return ['profile', 'remove', 'historyDeleteLocally'];
  };

  const selectedItems = Object.values(pick(menuItems, items || getDefaultMenuItems()));

  if (!selectedItems.length) return null;

  return <StyledUserMenu {...props} items={selectedItems} />;
};
