import React from 'react';
import { isNumber } from 'lodash-es';
import { useLocation } from 'react-router';
import cn from 'classnames';
import { BsChatFill } from 'react-icons/bs';

import {
  StyledContactCard,
  ContactCardAvatar,
  ContactCardName,
  ContactCardStatusIndicator,
  ContactCardActions,
  ContactCardAction
} from './ContactCard.styles';

const getIsActiveRegExp = keys => {
  const keywords = keys.filter(Boolean).reduce((reg, value) => {
    return !value ? reg : `${reg}|${value}`;
  });

  return new RegExp(`\\b(${keywords})\\b`, 'g');
};

export const ContactCard = ({
  className,
  children,
  id,
  avatar,
  name,
  username,
  status,
  actions = [],
  ...props
}) => {
  const _name = username || name;
  const location = useLocation();
  const isActive = !!location.pathname.match(getIsActiveRegExp([id]));

  const renderActions = () => {
    if (!actions?.length) return null;

    return (
      <ContactCardActions>
        {actions.filter(Boolean).map((action, i) => (
          <ContactCardAction key={action.key || i}>{action}</ContactCardAction>
        ))}
      </ContactCardActions>
    );
  };

  return (
    <StyledContactCard {...props} className={cn(className, { isActive })}>
      <ContactCardAvatar src={avatar} alt={`${_name}'s avatar`}>
        {isNumber(status) && (
          <ContactCardStatusIndicator status={status}>
            <BsChatFill />
          </ContactCardStatusIndicator>
        )}
      </ContactCardAvatar>

      {_name && <ContactCardName>{_name}</ContactCardName>}

      {children}

      {renderActions()}
    </StyledContactCard>
  );
};
