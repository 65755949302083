import React, {
  useRef,
  useState,
  useImperativeHandle,
  useEffect,
  Children,
  forwardRef
} from 'react';

import { DropdownChild, StyledDropdown } from './Dropdown.styles';

import { UseClickOutsideConditional } from 'hooks';
import { mergeProps } from 'utils';

export const Dropdown = forwardRef(
  (
    {
      children = [],
      isOpen: initialIsOpen = true,
      clickOutsideParams = {},
      closeOnChildClick = true,
      closeOnClickOutside = true,
      position = 'bottom',
      positionOffset,
      onClose,
      onChildClick,
      onClickOutside,
      ...props
    },
    ref
  ) => {
    const rootRef = useRef(null);
    const [isOpen, setIsOpen] = useState(initialIsOpen);

    useImperativeHandle(ref, () => rootRef.current, []);

    useEffect(() => setIsOpen(initialIsOpen), [initialIsOpen]);

    const handleClose = () => {
      setIsOpen(false);
      onClose?.();
    };

    const handleChildClick = event => {
      closeOnChildClick && handleClose();
      onChildClick?.(event);
    };

    const handleClickOutside = () => {
      closeOnClickOutside && handleClose();
      onClickOutside?.();
    };

    if (!isOpen) return null;

    const renderChildren = Children.map(children, (child, i) => {
      if (!child) return null;

      const mergedProps = mergeProps(child.props, { onClick: handleChildClick });

      return (
        <DropdownChild key={child.props.key || i} {...mergedProps}>
          {child}
        </DropdownChild>
      );
    });

    return (
      <>
        {isOpen && (
          <UseClickOutsideConditional
            elementRef={rootRef}
            callback={handleClickOutside}
            params={clickOutsideParams}
          />
        )}

        <StyledDropdown
          {...props}
          ref={rootRef}
          position={position}
          positionOffset={positionOffset}
        >
          {renderChildren}
        </StyledDropdown>
      </>
    );
  }
);
