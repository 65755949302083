import React, { useMemo } from 'react';
import { FiChevronDown } from 'react-icons/fi';

import { StyledSelect, SelectWrapper, SelectArrow } from './Select.styles';

let selectPrevId = 0;

export const Select = ({ id: initialId, options = [], ...props }) => {
  const id = useMemo(() => initialId || `select-${++selectPrevId}`, [initialId]);

  return (
    <SelectWrapper>
      <StyledSelect {...props} id={id}>
        {options.map(({ key, value }) => (
          <option key={key} value={value}>
            {key}
          </option>
        ))}
      </StyledSelect>

      <SelectArrow>
        <FiChevronDown />
      </SelectArrow>
    </SelectWrapper>
  );
};
