import { lighten } from 'polished';

import { cloneTheme } from 'styles/utils';
import { themeDefault } from './theme.default';

export const themeBrown = cloneTheme(themeDefault, {
  colors: {
    primary: {
      hue: '#510F00',
      tone: '#450D00'
    },

    secondary: {
      hue: '#FFD247',
      tint: lighten(0.05, '#FFD247')
    },

    message: {
      card: {
        active: lighten(0.75, '#510F00')
      }
    },

    indicator: {
      count: {
        foreground: '#510F00',
        background: '#FFD247'
      }
    }
  }
});
