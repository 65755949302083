import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { Icon } from '../Icon';

import { shouldForwardProp } from 'utils';
import { em } from 'styles';

export const LinkBody = styled.span``;

export const LinkIcon = styled(Icon)``;

export const linkStyle = ({ theme, iconSize }) => [
  css`
    appearance: none;
    color: inherit;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    cursor: pointer;

    ${LinkIcon} {
      font-size: ${em(theme.setFontSize(iconSize))};
    }
  `
];

export const StyledLink = styled(Link).withConfig({ shouldForwardProp })`
  ${linkStyle};
`;
