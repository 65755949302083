import React from 'react';
import { BsCheck, BsCheckAll } from 'react-icons/bs';

import { StyledMessageReadMark } from './MessageReadMark.styles';

import { MESSAGE_STATUSES } from '_constants';

export const MessageReadMark = ({ status, ...props }) => {
  return (
    <StyledMessageReadMark {...props}>
      {status === MESSAGE_STATUSES.ERROR ? <BsCheck /> : <BsCheckAll />}
    </StyledMessageReadMark>
  );
};
