import styled, { css } from 'styled-components';
import { lighten } from 'polished';

import { ContactCard, ContactCardAvatar } from '../Card';

import { Typography } from 'components/_shared/Typography';

import {
  Search,
  SearchInput,
  SearchAction,
  SearchActionSubmit,
  SearchActionClear
} from 'components/_shared/Search';

import { em, eventsStyle, overlapContainer, rem } from 'styles';

export const ContactListItem = styled(ContactCard)`
  ${({ theme, onClick }) => [
    css`
      padding: ${em([theme.spacing.xs, theme.spacing.sm])};
    `,
    onClick &&
      css`
        ${eventsStyle()};

        &:hover {
          color: inherit;
        }
      `
  ]};
`;

export const ContactListLabel = styled(Typography).attrs({ element: 'h4', size: 'x' })`
  ${({ theme, size }) => {
    const fontSize = theme.fontSizes[size];

    return css`
      font-weight: ${theme.fontWeights.medium};
      padding-left: ${em(theme.spacing.sm, fontSize)};
      opacity: 0.6;
    `;
  }}
`;

export const ContactListBody = styled.div``;

export const ContactListSearch = styled(Search)`
  ${({ theme }) => css`
    padding: ${em([0, theme.spacing.sm])};

    ${SearchInput} {
      font-size: ${em(theme.fontSizes.sx)};
      border: none;
      background: none;
      padding-left: ${em(theme.spacing.xs + 20)};
      padding-right: ${em(theme.spacing.xs + 20)};

      &::placeholder {
        color: ${lighten(0.3, theme.colors.primary.hue)};
      }

      &:hover,
      &:focus {
        &::placeholder {
          color: ${theme.colors.primary.hue};
        }
      }

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    ${SearchAction} {
      color: ${lighten(0.3, theme.colors.primary.hue)};

      &:hover,
      &:focus {
        color: ${theme.colors.primary.hue};
        background: none;
      }
    }

    ${SearchActionSubmit} {
      left: ${em(theme.spacing.sm)};
    }

    ${SearchActionClear} {
      right: ${em(theme.spacing.sm)};
    }
  `}
`;

export const ContactListHeader = styled.div`
  ${({ theme }) => css`
    position: relative;
    padding-bottom: ${em(theme.spacing.x)};

    &::before {
      content: '';
      ${overlapContainer('absolute')};
      bottom: 0;
      max-width: 100%;
      height: ${em(1)};
      background: ${theme.colors.primary.tint};
    }

    + ${ContactListBody} {
      padding: ${rem([theme.spacing.xs, 0])};
    }
  `}
`;

export const StyledContactList = styled.div`
  ${({ theme }) => css`
    ${ContactCardAvatar} {
      font-size: ${em(theme.fontSizes.xs)};
    }
  `}
`;
