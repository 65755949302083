import React from 'react';
import { FiBookmark, FiEdit2, FiTrash2 } from 'react-icons/fi';
import { GoQuote } from 'react-icons/go';

import {
  StyledMessageCardActions,
  MessageCardActionsWrapper,
  MessageCardAction,
  MessageCardActionUnpin
} from './MessageCardActions.styles';

export const MessageCardActions = ({
  onPinClick,
  onUnpinClick,
  onEditClick,
  onReplyClick,
  onDeleteClick,
  ...props
}) => {
  return (
    <StyledMessageCardActions {...props}>
      <MessageCardActionsWrapper>
        {onPinClick && <MessageCardAction icon={<FiBookmark />} onClick={onPinClick} />}
        {onUnpinClick && <MessageCardActionUnpin icon={<FiBookmark />} onClick={onUnpinClick} />}
        {onEditClick && <MessageCardAction icon={<FiEdit2 />} onClick={onEditClick} />}
        {onReplyClick && <MessageCardAction icon={<GoQuote />} onClick={onReplyClick} />}
        {onDeleteClick && (
          <MessageCardAction isDanger icon={<FiTrash2 />} onClick={onDeleteClick} />
        )}
      </MessageCardActionsWrapper>
    </StyledMessageCardActions>
  );
};
