import { useState, useCallback } from 'react';

import { mergeObjects, withFunction } from 'utils';

const INITIAL_STATE = { error: null, isLoading: false };

export const useRequest = () => {
  const [{ error, isLoading }, setState] = useState(INITIAL_STATE);

  const updateState = useCallback(newState => {
    return setState(state => mergeObjects(state, newState));
  }, []);

  const makeRequest = useCallback(
    async request => {
      updateState({ isLoading: true });

      try {
        const response = await withFunction(request);
        updateState(INITIAL_STATE);
        return response;
      } catch (error) {
        updateState({ error, isLoading: false });
        return Promise.reject(error);
      }
    },
    [updateState]
  );

  return { error, isLoading, updateRequestState: updateState, makeRequest };
};
