import React from 'react';

import { LayoutMain, LayoutSidebar, StyledLayout } from './Layout.styles';

export const Layout = ({ children, sidebar, ...props }) => {
  return (
    <StyledLayout {...props}>
      {sidebar && <LayoutSidebar as={sidebar} />}
      {children && <LayoutMain>{children}</LayoutMain>}
    </StyledLayout>
  );
};
