import React, { useRef, useState } from 'react';
import { FiSearch, FiX } from 'react-icons/fi';

import { SearchActionSubmit, SearchActionClear, SearchInput, StyledSearch } from './Search.styles';

export const Search = ({ value = '', onChange, variant = 'primary', ...props }) => {
  const inputRef = useRef(null);
  const [canRenderActionClear, setCanRenderActionClear] = useState(false);

  const initialValues = { query: value };

  const handleInputChange = event => {
    const { value } = event.target;
    setCanRenderActionClear(!!value.length);
    onChange?.(value);
  };

  return (
    <StyledSearch
      {...props}
      initialValues={initialValues}
      defaultActions={false}
      variant={variant}
      actions={[
        <SearchActionSubmit key='actionSubmit' type='submit' icon={<FiSearch />} />,
        canRenderActionClear &&
          (({ setFieldValue }) => (
            <SearchActionClear
              icon={<FiX />}
              onClick={() => {
                setCanRenderActionClear(false);
                setFieldValue('query', '');
                onChange?.('');
                inputRef.current.focus();
              }}
            />
          ))
      ]}
    >
      {form => (
        <SearchInput
          ref={inputRef}
          name='query'
          type='text'
          value={form.values.query}
          placeholder='Search...'
          autoComplete='off'
          variant={variant}
          onChange={event => {
            form.handleChange(event);
            handleInputChange(event);
          }}
        />
      )}
    </StyledSearch>
  );
};
