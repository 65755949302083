import React, { useMemo } from 'react';
import { FiCheck } from 'react-icons/fi';

import {
  StyledCheckbox,
  CheckboxLabel,
  CheckboxCheck,
  CheckboxCheckIcon,
  CheckboxLabelBody
} from './Checkbox.styles';

let checkboxPrevId = 0;

export const Checkbox = ({ id: initialId, label, className, value, onClick, ...props }) => {
  const id = useMemo(() => initialId || `checkbox-${++checkboxPrevId}`, [initialId]);

  return (
    <CheckboxLabel className={className} htmlFor={id} onClick={onClick}>
      <StyledCheckbox {...props} id={id} checked={value} />

      <CheckboxCheck>
        <CheckboxCheckIcon>
          <FiCheck />
        </CheckboxCheckIcon>
      </CheckboxCheck>

      {label && <CheckboxLabelBody>{label}</CheckboxLabelBody>}
    </CheckboxLabel>
  );
};
