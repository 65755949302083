import React, { createElement, forwardRef, useImperativeHandle, useRef } from 'react';
import { FormikProvider, useFormik } from 'formik';

import {
  FormAction,
  FormActions,
  FormBody,
  FormFooter,
  FormHeader,
  FormHeading,
  FormMessage,
  FormMessageError,
  FormMessages,
  FormPreloader,
  StyledForm
} from './Form.styles';

import { Button } from '../Button';

import { mergeProps, withFunction } from 'utils';

export const Form = forwardRef(
  (
    {
      children,
      footerChildren,
      heading,
      error,
      message,
      initialValues = {},
      validationSchema,
      params = {},
      actions: customActions = [],
      defaultActions,
      isDisabled: initialIsDisabled = false,
      isLoading = false,
      isPreloaderTransparent = false,
      onReset,
      onSubmit = () => {},
      ...props
    },
    ref
  ) => {
    const rootRef = useRef(null);

    const formik = useFormik({
      validateOnBlur: false,
      ...params,
      initialValues,
      validationSchema,
      onReset,
      onSubmit
    });

    const isDisabled = initialIsDisabled || isLoading;
    const canRenderHeader = heading;
    const canRenderMessages = error || message;
    const canRenderFooter = footerChildren || canRenderMessages;

    useImperativeHandle(ref, () => ({ element: rootRef.current, ...formik }), [formik]);

    const DEFAULT_ACTIONS_PROPS = {
      cancel: {
        type: 'button',
        children: 'Cancel',
        variant: 'secondary'
      },
      reset: {
        type: 'reset',
        children: 'Reset',
        variant: 'secondary'
      },
      submit: {
        type: 'submit',
        children: 'Submit',
        variant: 'primary',
        disabled: isDisabled
      }
    };

    const renderActions = () => {
      const actions = [];

      if (customActions.length) {
        customActions
          .map(action => withFunction(action, formik))
          .filter(Boolean)
          .forEach(action => actions.push(action));
      }

      if (defaultActions !== false) {
        const mergedProps = mergeProps(DEFAULT_ACTIONS_PROPS, {
          cancel: false,
          reset: false,
          ...defaultActions
        });

        Object.entries(mergedProps)
          .filter(([, props]) => Boolean(props))
          .forEach(([key, props]) => actions.push(createElement(Button, { key, ...props })));
      }

      if (!actions.length) return null;

      return (
        <FormActions>
          {actions.map((action, i) => (
            <FormAction key={action.key || i}>{action}</FormAction>
          ))}
        </FormActions>
      );
    };

    return (
      <StyledForm
        {...props}
        ref={rootRef}
        onReset={formik.handleReset}
        onSubmit={formik.handleSubmit}
      >
        <FormikProvider value={formik}>
          {canRenderHeader && (
            <FormHeader>{heading && <FormHeading>{heading}</FormHeading>}</FormHeader>
          )}

          {children && <FormBody>{withFunction(children, formik)}</FormBody>}

          {renderActions()}

          {canRenderFooter && (
            <FormFooter>
              {canRenderMessages && (
                <FormMessages>
                  {error && <FormMessageError>{error}</FormMessageError>}
                  {message && <FormMessage>{message}</FormMessage>}
                </FormMessages>
              )}

              {footerChildren}
            </FormFooter>
          )}

          {isLoading && <FormPreloader isTransparent={isPreloaderTransparent} />}
        </FormikProvider>
      </StyledForm>
    );
  }
);
