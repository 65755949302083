import styled, { css } from 'styled-components';

import { Tabs, TabsBody, TabsHeader, TabsLabel } from 'components/_shared/Tabs';
import { StyledChatIndicatorUpdates } from 'components/Chat';
import { ButtonBody } from 'components/_shared/Button';

import { em, rem } from 'styles';

export const StyledSidebarContactTabs = styled(Tabs)`
  ${({ theme, isSearchResultsOpen }) => [
    css`
      display: flex;
      flex-direction: column;
      height: 100%;

      ${TabsHeader} {
        flex-shrink: 0;
        background: ${theme.colors.primary.tone};
      }

      ${TabsLabel} {
        ${ButtonBody} {
          position: relative;
        }

        ${StyledChatIndicatorUpdates} {
          position: absolute;
          top: 0;
          right: -${em(8)};
        }
      }

      ${TabsBody} {
        flex: 1;
        padding: ${rem([theme.spacing.sm, 0])};
        overflow-x: hidden;
        overflow-y: auto;
      }
    `,
    isSearchResultsOpen &&
      css`
        background: ${theme.colors.primary.tone};

        ${TabsLabel} {
          opacity: 0;
          visibility: hidden;
          pointer-events: none;
        }

        ${TabsBody} {
          overflow: hidden;
        }
      `
  ]}
`;
