import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { pick } from 'lodash-es';

import { userActions } from './user.slice';

import { API } from 'api';
import { AUTH_KEYS, CONTACT_TYPES, USER_KEYS, USER_STATUSES } from '_constants';

import { useRequest } from 'hooks';
import { chatActions } from 'state/chat';

import { getCookieAuth, handleResponseError, removeFalsyValues } from 'utils';

export const useUserActions = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { isLoading: isRequestLoading, makeRequest } = useRequest();

  const auth = useMemo(() => pick(getCookieAuth(), [AUTH_KEYS.USER_ID, AUTH_KEYS.USERNAME]), []);

  const getUserData = useCallback(async (id, user = {}) => {
    try {
      const contacts = (await API.getContactList()).data;
      const contact = contacts.find(({ UserID }) => UserID === id) || {};

      const [
        profile = {},
        { ChatID = null, MessageHistroy: messages = [] } = {}
      ] = await Promise.all([
        API.getUserProfile(id),
        API.getUserDirectMessages(id)
      ]).then(responses => responses.map(({ data }) => data));

      return {
        ...contact,
        ...profile,
        ChatID,
        messages,
        contactType: CONTACT_TYPES.USER,
        ...user
      };
    } catch (error) {
      handleResponseError(error);
    }
  }, []);

  const getUserProfile = useCallback(
    async (id, params = {}) => {
      try {
        const response = await makeRequest(API.getUserProfile(id));
        const userProfile = { ...response.data, [USER_KEYS.ID]: id };
        dispatch(userActions.updateEntity({ entity: userProfile, ...params }));
        return userProfile;
      } catch (error) {
        handleResponseError(error);
      }
    },
    [dispatch, makeRequest]
  );

  const addUser = useCallback(user => dispatch(userActions.addEntity(user)), [dispatch]);

  const updateUser = useCallback(
    async (user, params = {}) => {
      try {
        await makeRequest(API.updateUserProfile(user));
        dispatch(userActions.updateEntity({ entity: user, ...params }));
      } catch (error) {
        handleResponseError(error);
      }
    },
    [dispatch, makeRequest]
  );

  const removeUser = useCallback(
    async id => {
      try {
        await makeRequest(API.removeContact(id));
        dispatch(userActions.removeEntity(id));
      } catch (error) {
        handleResponseError(error);
      }
    },
    [dispatch, makeRequest]
  );

  const requestUser = useCallback(
    async id => {
      try {
        await makeRequest(API.requestContact(id));
        dispatch(
          userActions.updateEntityStatus({
            [USER_KEYS.ID]: id,
            [USER_KEYS.STATUS]: USER_STATUSES.REQUESTED,
            [USER_KEYS.IS_ACCEPTED]: false
          })
        );
      } catch (error) {
        handleResponseError(error);
      }
    },
    [dispatch, makeRequest]
  );

  const acceptUserRequest = useCallback(
    async id => {
      try {
        await makeRequest(API.updateContactRequest(id, true));
        dispatch(
          userActions.updateEntityStatus({
            [USER_KEYS.ID]: id,
            [USER_KEYS.STATUS]: USER_STATUSES.ONLINE,
            [USER_KEYS.IS_ACCEPTED]: true
          })
        );
      } catch (error) {
        handleResponseError(error);
      }
    },
    [dispatch, makeRequest]
  );

  const declineUserRequest = useCallback(
    async id => {
      try {
        await makeRequest(API.updateContactRequest(id, false));
        dispatch(
          userActions.updateEntityStatus({
            [USER_KEYS.ID]: id,
            [USER_KEYS.STATUS]: USER_STATUSES.AVAILABLE,
            [USER_KEYS.IS_ACCEPTED]: null
          })
        );
      } catch (error) {
        handleResponseError(error);
      }
    },
    [dispatch, makeRequest]
  );

  const getUsers = useCallback(async () => {
    setIsLoading(true);

    try {
      const [users, contacts] = await Promise.all([
        API.getUserList(),
        API.getContactList()
      ]).then(responses => responses.map(({ data }) => data));

      const chats = [];

      const entities = await Promise.all(
        users.map(async user => {
          try {
            const userId = user.userId || user.UserID;
            const isCurrentUser = userId === auth[AUTH_KEYS.USER_ID];
            const contact = contacts.find(({ UserID }) => UserID === userId) || {};

            const [{ ChatID = null, MessageHistroy: messages = [] } = {}] = await Promise.all([
              contact ? API.getUserDirectMessages(userId) : []
            ]).then(responses => responses.map(({ data }) => data));

            const _user = {
              ...user,
              ...contact,
              [USER_KEYS.CHAT_ID]: ChatID,
              [USER_KEYS.CONTACT_TYPE]: CONTACT_TYPES.USER
            };

            if (contact) {
              chats.push({
                id: userId,
                messages,
                users: [auth.userId, userId],
                contact: {
                  id: userId,
                  type: CONTACT_TYPES.USER,
                  chatId: ChatID
                }
              });
            }

            if (isCurrentUser) {
              _user[USER_KEYS.IS_CURRENT_USER] = true;
            }

            return _user;
          } catch (error) {
            return user;
          }
        })
      );

      dispatch(userActions.setEntities(entities));
      dispatch(chatActions.setEntities(chats));

      return async () => {
        if (!entities || !entities.length) return;

        const profiles = await Promise.all(
          entities.filter(Boolean).map(entity => API.getUserProfile(entity.userId || entity.UserID))
        ).then(profiles =>
          profiles.map(({ data }, i) => {
            data.id = entities[i].userId || entities[i].UserID;
            return removeFalsyValues(data);
          })
        );

        if (!profiles.length) return;

        dispatch(userActions.updateEntities(profiles));
      };
    } catch (error) {
      handleResponseError(error);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, auth]);

  return {
    isLoading: isLoading || isRequestLoading,
    getUserData,
    getUserProfile,
    addUser,
    updateUser,
    removeUser,
    requestUser,
    declineUserRequest,
    acceptUserRequest,
    getUsers
  };
};
