import { css } from 'styled-components';
import { darken, em, rgba } from 'polished';
import { isNumber } from 'lodash-es';

import { createBreakpoints } from 'styles/utils';
import { breathe, fadeInSlideDown, rotationWithScale, slideUp } from 'styles/utils/animations';

const SHADOW_COLOR = rgba('#1564D1', 0.2);

const colors = {
  primary: {
    hue: '#1564D1',
    tint: '#DBE5F4',
    tone: '#135CC2',
    shade: '#1254AE'
  },

  secondary: {
    hue: '#00D95F',
    tint: '#00E865'
  },

  danger: {
    hue: '#ff0870',
    tint: '#fef0f0'
  },

  warning: {
    hue: '#FECF38'
  },

  info: '#0079f2',

  light: {
    hue: '#ffffff',
    tint: '#F4F4F4',
    tone: '#F9F9F9',
    shade: '#EFEFEF'
  },

  dark: {
    hue: '#1D1D1D',
    tint: '#99a3af',
    tone: '#808080',
    shade: '#666666'
  },

  sidebar: {
    contactList: {
      active: rgba('#ffffff', 0.17)
    }
  },

  view: {
    background: '#f1f1f1',
    aside: '#f0f0f0'
  },

  message: {
    card: {
      active: '#e8f1fd'
    }
  },

  input: {
    secondary: {
      background: rgba('#ffffff', 0.17)
    }
  },

  indicator: {
    count: {
      foreground: '#1564D1',
      background: '#ffffff'
    }
  }
};

const fonts = {
  primary: 'Inter'
};

const fontSizes = {
  x: 12,
  xs: 14,
  sx: 16,
  s: 18,
  sm: 20,
  ms: 24,
  m: 32,
  ml: 40,
  lm: 48
};

const fontWeights = {
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700
};

const lineHeight = {
  x: 1.4,
  xs: 1.6
};

const spacing = {
  x: 4,
  xs: 8,
  sx: 12,
  s: 14,
  sm: 16,
  ms: 20,
  m: 22,
  ml: 24,
  lm: 32,
  l: 40,
  lx: 48,
  xl: 64,
  '2xl': 96,
  '3xl': 128
};

const radius = {
  x: 4,
  xs: 6,
  sx: 8,
  s: 100
};

const borders = {
  default: ({ fontSize, color = colors.light.shade, inset = false, asShadow = false } = {}) => {
    const _inset = inset ? 'inset' : '';

    if (asShadow) {
      return `box-shadow: ${_inset} 0px 0px 0px ${em(1.6, fontSize)} ${color};`;
    }

    return `${_inset} ${em(1.6, fontSize)} solid ${color}`;
  },

  secondary: function () {
    return this.default({ color: darken(0.05, colors.light.shade) });
  }
};

const shadows = {
  default: ({ fontSize, color = SHADOW_COLOR, inset } = {}) => {
    return `0 ${em(4, fontSize)} ${em(8, fontSize)} 0 ${color} ${inset || ''}`;
  },

  focus: ({ fontSize, color = colors.primary.shade, inset } = {}) => {
    return `0 0 0 ${em(3, fontSize)} ${rgba(color, 0.4)} ${inset || ''}`;
  }
};

const duration = {
  fast: '0.2s',
  medium: '0.4s',
  slow: '0.8s'
};

const transitions = {
  fast: `${duration.fast} ease`,
  medium: `${duration.medium} ease`,
  slow: `${duration.slow} ease`
};

const zIndexes = {
  x: 1,
  xs: 10,
  sx: 50,
  s: 100
};

const breakpoints = createBreakpoints({
  x: 320,
  xs: 576,
  sx: 768,
  s: 992,
  sm: 1200,
  ms: 1440
});

const animations = {
  slideUp: css`
    animation: ${slideUp} ${transitions.medium} forwards;
  `,
  rotationWithScale: css`
    animation: ${rotationWithScale} 1s linear infinite;
  `,
  breathe: css`
    animation: ${breathe} 3s ease infinite;
  `,
  fadeInSlideDown: css`
    animation: ${fadeInSlideDown} ${transitions.medium} forwards;
  `
};

export const themeDefault = {
  colors,
  fonts,
  fontSizes,
  fontWeights,
  lineHeight,
  spacing,
  radius,
  borders,
  shadows,
  duration,
  transitions,
  zIndexes,
  breakpoints,
  animations,
  setFontSize: size => (isNumber(size) ? size : fontSizes[size])
};
