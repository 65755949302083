import styled, { css } from 'styled-components';

export const SidebarBody = styled.div`
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const StyledSidebar = styled.aside`
  ${({ theme }) => css`
    color: ${theme.colors.light.hue};
    display: flex;
    flex-direction: column;
    height: 100vh;
    max-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    background: ${theme.colors.primary.hue};
  `}
`;
