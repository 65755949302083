import { keyframes } from 'styled-components';

export const slideUp = keyframes`
  from {
    visibility: hidden;
    transform: translate3d(0, 100%, 0);
  }

  to {
    visibility: visible;
    transform: translate3d(0, 0, 0);
  }
`;

export const rotationWithScale = keyframes`
  0% {
    transform: rotate(0) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(1.1);

  }
  100% {
    transform: rotate(360deg) scale(1);
  }
`;

export const breathe = keyframes`
  0% {
    opacity: 0.8;
    transform: scale(0.8);
  }
  50% {
    opacity: 1;
    transform: scale(1);

  }
  100% {
    opacity: 0.8;
    transform:scale(0.8);
  }
`;

export const fadeInSlideDown = keyframes`
  from {
    transform: translateY(-100%);
    opacity: 0;
  }

  to {
    transform: translateY(-50%);
    opacity: 1;
  }
`;
