import styled, { css } from 'styled-components';

import { Image } from 'components/_shared/Image';
import { Icon } from 'components/_shared/Icon';

import { shouldForwardProp } from 'utils';
import { em, rem, setAspectRatio } from 'styles';

export const FilePreviewIcon = styled(Icon)`
  ${({ theme }) => css`
    color: ${theme.colors.dark.tone};
    width: 15%;
  `}
`;

export const FilePreviewImage = styled(Image)`
  width: 100%;
  height: 100%;
  border-radius: inherit;
`;

export const StyledFilePreview = styled.div.withConfig({ shouldForwardProp })`
  ${({ theme, onClick }) => [
    css`
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: ${em(theme.radius.xs)};
      background: ${theme.colors.light.tint};
      overflow: hidden;
      ${setAspectRatio('16:9')};

      &:not(.application) {
        ${FilePreviewIcon} {
          svg {
            stroke-width: ${rem(1.6)};
          }
        }
      }
    `,
    onClick &&
      css`
        transition: ${theme.transitions.medium};
        cursor: pointer;

        ${FilePreviewImage},
        ${FilePreviewIcon} {
          transition: inherit;
        }

        &:hover,
        &:focus {
          ${FilePreviewImage},
          ${FilePreviewIcon} {
            transform: scale(1.1);
          }
        }
      `
  ]}
`;
