import React from 'react';
import { MemoryRouter } from 'react-router-dom';

import { ROUTES } from '_constants';

import { ThemeProvider } from 'styles';
import { Routes } from 'routes';

import { ModalController } from 'components/_shared/Modal';
import { Notification } from 'components/Notification';

export const App = () => {
  return (
    <ThemeProvider>
      <MemoryRouter initialEntries={Object.values(ROUTES)} initialIndex={0}>
        <Routes />
        <ModalController />
        <Notification />
      </MemoryRouter>
    </ThemeProvider>
  );
};
