import { pick } from 'lodash-es';
import { findKeyByValue } from 'utils';

import { CONTACT_TYPES } from './contact';

export const USER_KEY = 'user';

export const USER_KEYS = {
  ID: 'id',
  CHAT_ID: 'chatId',
  REQUESTOR_ID: 'requestorId',
  NAME: 'name',
  LASTNAME: 'lastname',
  USERNAME: 'username',
  AVATAR: 'avatar',
  EMAIL: 'email',
  EMAIL_BUSINESS: 'emailBusiness',
  EMAIL_PERSONAL: 'emailPersonal',
  STATUS: 'status',
  IS_ACCEPTED: 'isAccepted',
  IS_CURRENT_USER: 'isCurrentUser',
  CONTACT_TYPE: 'contactType',
  ORGANIZATION: 'organization',
  ORGANIZATION_TYPE: 'organizationType'
  // PERSONAL: 'personal'
};

export const USER_PROFILE_KEYS = [
  USER_KEYS.ID,
  USER_KEYS.AVATAR,
  USER_KEYS.NAME,
  USER_KEYS.LASTNAME,
  USER_KEYS.EMAIL,
  USER_KEYS.EMAIL_PERSONAL,
  USER_KEYS.ORGANIZATION
];

export const USER_STATUSES = {
  ONLINE: 1,
  OFFLINE: 2,
  AWAY: 3,
  AVAILABLE: 4,
  REQUESTED: 5,
  WAITING: 6
};

export const USER_STATUSES_ACCEPTED_KEYS = [1, 2, 3].map(value => {
  return findKeyByValue(USER_STATUSES, value);
});

export const USER_STATUSES_ACCEPTED_VALUES = Object.values(
  pick(USER_STATUSES, USER_STATUSES_ACCEPTED_KEYS)
);

export const USER_KEYS_MAP = {
  [USER_KEYS.ID]: ['UserID', 'userId', 'SenderID'],
  [USER_KEYS.CHAT_ID]: ['ChatID'],
  [USER_KEYS.REQUESTOR_ID]: ['RequestorID'],
  [USER_KEYS.NAME]: ['Name'],
  [USER_KEYS.LASTNAME]: ['LastName'],
  [USER_KEYS.USERNAME]: ['UserName', 'Username'],
  [USER_KEYS.AVATAR]: ['Avatar'],
  [USER_KEYS.EMAIL]: ['Email'],
  [USER_KEYS.EMAIL_BUSINESS]: ['Business', 'Buisness'],
  [USER_KEYS.EMAIL_PERSONAL]: ['Personal'],
  [USER_KEYS.STATUS]: ['Status'],
  [USER_KEYS.IS_ACCEPTED]: ['Accepted'],
  [USER_KEYS.ORGANIZATION]: ['Organization'],
  [USER_KEYS.ORGANIZATION_TYPE]: ['OrganizationType']
  // [USER_KEYS.PERSONAL]: ['Personal']
};

export const USER_TEMPLATE = {
  [USER_KEYS.ID]: '',
  [USER_KEYS.CHAT_ID]: '',
  [USER_KEYS.NAME]: '',
  [USER_KEYS.LASTNAME]: '',
  [USER_KEYS.USERNAME]: '',
  [USER_KEYS.AVATAR]: '',
  [USER_KEYS.EMAIL]: '',
  [USER_KEYS.EMAIL_BUSINESS]: '',
  [USER_KEYS.EMAIL_PERSONAL]: '',
  [USER_KEYS.STATUS]: USER_STATUSES.AVAILABLE,
  [USER_KEYS.IS_ACCEPTED]: null,
  [USER_KEYS.IS_CURRENT_USER]: false,
  [USER_KEYS.CONTACT_TYPE]: CONTACT_TYPES.USER,
  [USER_KEYS.ORGANIZATION]: '',
  [USER_KEYS.ORGANIZATION_TYPE]: ''
  // [USER_KEYS.PERSONAL]: ''
};
