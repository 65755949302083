import { Typography } from 'components/_shared/Typography';
import styled from 'styled-components';

import { Modal } from '../Modal';
import { ModalActions, ModalContainer, ModalHeaderHeading } from '../Modal.styles';

import { rem } from 'styles';

export const ModalConfirmMessage = styled(Typography).attrs({ element: 'p', size: 'xs' })`
  text-align: center;
`;

export const StyledModalConfirm = styled(Modal)`
  ${ModalContainer} {
    width: ${rem(458)};
  }

  ${ModalHeaderHeading} {
    text-align: center;
    margin: 0 auto;
  }

  ${ModalActions} {
    justify-content: center;
  }
`;
