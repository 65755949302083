import { CONTACT_TYPES } from './contact';

export const SOCKET_KEY = 'socket';

export const SOCKET_EVENT_TYPES = {
  CLIENT_HANDSHAKE: 1,
  USER_NEW: 6,
  USER_REMOVE: 9,
  USER_STATUS: 5,
  USER_REQUEST_SEND: 7,
  USER_REQUEST_RESPONSE: 8,
  USER_PROFILE_UPDATE: 19,
  MESSAGE_NEW: 2,
  MESSAGE_EDIT: 3,
  MESSAGE_DELETE: 4,
  GROUP_NEW: 12,
  GROUP_USER_NEW: 10,
  GROUP_USER_REMOVE: 11,
  GROUP_PROFILE_UPDATE: 20,
  GROUP_HISTORY_DELETE: 13,
  ATTACHMENT_SEND: 14,
  ATTACHMENT_RECEIVE: 18,
  ATTACHMENT_SEND_RESPONSE: 16,
  ATTACHMENT_GET_RESPONSE: 15
};

export const SOCKET_RECEIVER_TYPES = CONTACT_TYPES;
