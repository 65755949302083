import styled, { css } from 'styled-components';

import { StyledContactList } from '../ContactList.styles';

import { em } from 'styles';

export const StyledContactListGrouped = styled.div`
  ${({ theme }) => css`
    ${StyledContactList} {
      &:not(:first-of-type) {
        margin-top: ${em(theme.spacing.sm)};
      }
    }
  `}
`;
