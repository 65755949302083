import React, { useRef } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

import { StyledSidebarContactList } from './SidebarContactList.styles';

import { CONTACT_TYPES, CONTACT_TYPE_KEY, ROUTES } from '_constants';

import { selectChats } from 'state';
import { isGroup, isUserAccepted } from 'utils';

import { UserMenu } from 'components/User';
import { GroupMenu } from 'components/Group';
import { IndicatorCount } from 'components/_shared/Indicator';

export const SidebarContactList = ({ contacts = [], ...props }) => {
  const rootRef = useRef(null);
  const history = useHistory();

  const chats = useSelector(selectChats);

  const getContactProps = contact => {
    const contactTypeProps = {
      [CONTACT_TYPES.USER]: {
        actions: [<UserMenu key='menu' user={contact} />]
      },
      [CONTACT_TYPES.GROUP]: {
        actions: [<GroupMenu key='menu' group={contact} />]
      }
    }[contact[CONTACT_TYPE_KEY]];

    const contactProps = {
      ...contactTypeProps,
      chat: chats[contact.id],
      onMouseLeave: () => rootRef.current?.click()
    };

    if (isGroup(contact) || isUserAccepted(contact)) {
      if (contactProps.chat?.updates?.messages) {
        Object.entries(contactProps.chat.updates.messages).forEach(([key, value]) => {
          const variants = { edited: 'warning', deleted: 'danger' };
          contactProps.actions.push(
            <IndicatorCount variant={variants[key]}>{value}</IndicatorCount>
          );
        });
      }

      contactProps.onClick = () => {
        history.push(ROUTES.CHAT_BY_CONTACT_ID(contact.id));
      };
    }

    return contactProps;
  };

  return (
    <StyledSidebarContactList
      {...props}
      ref={rootRef}
      contacts={contacts}
      contactProps={getContactProps}
    />
  );
};
