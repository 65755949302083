import styled, { css } from 'styled-components';
import { rgba } from 'polished';

import { shouldForwardProp } from 'utils';

export const StyledOverlay = styled.div.withConfig({ shouldForwardProp })`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  ${({ theme, isTransparent }) => css`
    background: ${isTransparent ? 'transparent' : rgba(theme.colors.primary.hue, 0.4)};
    z-index: ${theme.zIndexes.xs};
  `}
`;
