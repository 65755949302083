import styled from 'styled-components';

import { MessageEditor } from 'components/Message';

import { rem } from 'styles';
import { StyledTagContact } from 'components/Tag';

export const StyledChatMessageEditor = styled(MessageEditor)`
  min-height: ${rem(136)};

  .ql {
    &-editor {
      height: ${rem(94)};
    }
  }

  ${StyledTagContact} {
    top: auto;
    bottom: 100%;
  }
`;
