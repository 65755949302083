import React, { useRef, useState, useImperativeHandle, useEffect, forwardRef } from 'react';
import { isUndefined } from 'lodash-es';
import { FiX } from 'react-icons/fi';

import {
  ModalAction,
  ModalActions,
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalHeaderHeading,
  ModalOverlay,
  ModalToggle,
  StyledModal
} from './Modal.styles';

import { UseClickOutsideConditional } from 'hooks';
import { withFunction } from 'utils';

export const Modal = forwardRef(
  (
    {
      children,
      heading,
      actions: customActions,
      isOpen: initialIsOpen = true,
      clickOutsideParams = {},
      closeOnClickOutside = true,
      withToggle = true,
      onClose,
      onClickOutside,
      ...props
    },
    ref
  ) => {
    const rootRef = useRef(null);
    const [isOpen, setIsOpen] = useState(initialIsOpen);
    const canRenderHeader = heading;
    const canRenderFooter = customActions?.length;

    const handleClose = () => {
      setIsOpen(false);
      onClose?.();
    };

    useImperativeHandle(ref, () => ({
      element: rootRef.current,
      closeModal: handleClose
    }));

    useEffect(() => !isUndefined(initialIsOpen) && setIsOpen(initialIsOpen), [initialIsOpen]);

    if (!isOpen) return null;

    const handleClickOutside = () => {
      closeOnClickOutside && handleClose();
      onClickOutside?.();
    };

    const renderActions = () => {
      if (!customActions?.length) return null;

      const actions = [];

      customActions
        .map(action => withFunction(action, { ref: rootRef, closeModal: handleClose }))
        .filter(Boolean)
        .forEach(action => actions.push(action));

      if (!actions.length) return null;

      return (
        <ModalActions>
          {actions.map((action, i) => (
            <ModalAction key={action.key || i}>{action}</ModalAction>
          ))}
        </ModalActions>
      );
    };

    return (
      <>
        {isOpen && (
          <UseClickOutsideConditional
            elementRef={rootRef}
            callback={handleClickOutside}
            params={clickOutsideParams}
          />
        )}

        <StyledModal {...props}>
          <ModalOverlay />

          <ModalContainer ref={rootRef}>
            {withToggle && <ModalToggle icon={<FiX />} onClick={handleClose} />}

            {canRenderHeader && (
              <ModalHeader>
                {heading && <ModalHeaderHeading>{heading}</ModalHeaderHeading>}
              </ModalHeader>
            )}

            {children && <ModalBody>{children}</ModalBody>}

            {canRenderFooter && <ModalFooter>{renderActions()}</ModalFooter>}
          </ModalContainer>
        </StyledModal>
      </>
    );
  }
);
