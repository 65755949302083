import { isUndefined, isFunction } from 'lodash-es';
import cn from 'classnames';

import { NOT_FORWARD_PROPS } from '_constants';

import { mergeObjects } from './helpers';

export const getHeadingLevel = headingElement => {
  return Number(headingElement.match(/\d+/g));
};

export const checkIsHeadingElement = element => {
  return ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(element);
};

export const mergeProps = (initialProps, newProps) => {
  return mergeObjects(initialProps, newProps, (value, newValue, key) => {
    if (key.match(/children|ref|component|render/)) {
      return !isUndefined(newValue) ? newValue : value;
    }

    if (key.match('className')) return cn(value, newValue);

    if (isFunction(value)) {
      if (newValue === false) return undefined;
      return (...args) => {
        value?.(...args);
        newValue?.(...args);
      };
    }
  });
};

export const mergePropsWithDefault = (initialProps, newProps) => {
  return [newProps, initialProps].reduce(mergeProps, {});
};

export const getStyledComponentClassName = component => {
  return component.toString().replace(/^\./, '');
};

export const shouldForwardProp = prop => {
  return !NOT_FORWARD_PROPS.includes(prop);
};
