import React from 'react';

import {
  SidebarSearchResults404,
  SidebarSearchResultsHeading,
  StyledSidebarSearchResults
} from './SidebarSearchResults.styles';

import { SidebarContactList } from 'components/Sidebar/Contact';

import { isUser } from 'utils';

export const SidebarSearchResults = ({ results = [], ...props }) => {
  return (
    <StyledSidebarSearchResults {...props}>
      <SidebarSearchResultsHeading>Search results</SidebarSearchResultsHeading>

      {!results.length && <SidebarSearchResults404>Nothing found</SidebarSearchResults404>}

      <SidebarContactList
        contacts={results}
        groupKeys={['users', 'groups']}
        condition={contact => (isUser(contact) ? 'users' : 'groups')}
      />
    </StyledSidebarSearchResults>
  );
};
