import { createSlice } from '@reduxjs/toolkit';
import Cookie from 'js-cookie';

import { AUTH_KEY, AUTH_KEYS } from '_constants';

import { createAuth, mergeObjects, normalizeAuth } from 'utils';

const AUTH_DEFAULT_STATE = createAuth();

export const AUTH_INITIAL_STATE = mergeObjects(AUTH_DEFAULT_STATE, Cookie.getJSON(AUTH_KEY));

const authSlice = createSlice({
  name: AUTH_KEY,
  initialState: AUTH_INITIAL_STATE,
  reducers: {
    signIn: {
      reducer: (_, action) => {
        const newState = mergeObjects(action.payload, {
          [AUTH_KEYS.IS_AUTHORIZED]: true,
          [AUTH_KEYS.SHOULD_REPEAT_SIGN_IN]: false
        });

        Cookie.set(AUTH_KEY, newState);
        delete newState[AUTH_KEYS.PASSWORD];

        return newState;
      },
      prepare: payload => {
        return { payload: normalizeAuth(payload) };
      }
    },

    updatePassword: (state, action) => {
      Cookie.set(AUTH_KEY, {
        ...Cookie.getJSON(AUTH_KEY),
        password: window.btoa(action.payload),
        isTokenDefault: false
      });

      state.isTokenDefault = false;
    },

    signOut: () => {
      Cookie.remove(AUTH_KEY);
      return AUTH_DEFAULT_STATE;
    }
  }
});

export const { reducer: authReducer, actions: authActions } = authSlice;

export const selectAuth = state => state.auth;
