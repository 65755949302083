import React from 'react';
import * as Yup from 'yup';

import { StyledPasswordResetForm } from './PasswordResetForm.styles';

import { FormField, FormGroup } from 'components/_shared/Form';

const validationSchema = Yup.object().shape({
  username: Yup.string().required(),
  email: Yup.string().email().required()
});

export const PasswordResetForm = ({
  heading = 'Reset password',
  message = 'A new password will be sent to your email address.',
  onCancel,
  ...props
}) => {
  const initialValues = { username: '', email: '' };

  return (
    <StyledPasswordResetForm
      {...props}
      heading={heading}
      initialValues={initialValues}
      validationSchema={validationSchema}
      message={message}
      defaultActions={{
        cancel: { children: 'Cancel', onClick: onCancel },
        submit: { children: 'Reset', variant: 'tertiary' }
      }}
    >
      <FormGroup>
        <FormField
          name='username'
          type='text'
          control={{ placeholder: 'Username', variant: 'secondary' }}
        />

        <FormField
          name='email'
          type='email'
          control={{ placeholder: 'Email', variant: 'secondary' }}
        />
      </FormGroup>
    </StyledPasswordResetForm>
  );
};
