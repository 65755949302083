import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectSettings, settingsActions } from './settings.slice';

import { THEMES } from '_constants';

export const useSettings = () => {
  const dispatch = useDispatch();

  const settings = useSelector(selectSettings);

  const setTheme = useCallback(
    name => {
      dispatch(settingsActions.setTheme(name));
    },
    [dispatch]
  );

  const setNotificationSound = useCallback(
    withSound => {
      dispatch(settingsActions.setNotification(withSound));
    },
    [dispatch]
  );

  return { ...settings, themes: THEMES, setTheme, setNotificationSound };
};
