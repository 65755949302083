import styled from 'styled-components';

import { shouldForwardProp } from 'utils';

export const StyledImage = styled.img.withConfig({ shouldForwardProp })`
  width: 100%;
  max-width: 100%;
  height: auto;
  object-fit: cover;
`;
