import styled, { css } from 'styled-components';

import { Image } from 'components/_shared/Image';
import { Icon } from 'components/_shared/Icon';
import { Typography } from 'components/_shared/Typography';

import { em, rem } from 'styles';

export const FileCardMeta = styled(Typography).attrs({ element: 'p', size: 'x' })`
  ${({ theme }) => css`
    text-transform: uppercase;
    color: ${theme.colors.dark.shade};
  `}
`;

export const FileCardMetaWrapper = styled.div`
  ${({ theme }) => {
    const childSpacing = theme.spacing.x / 2;

    return css`
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
      margin: -${em(childSpacing)};

      > * {
        margin: ${em(childSpacing, theme.fontSizes.x)};
      }
    `;
  }}
`;

export const FileCardFooter = styled.footer``;

export const FileCardTitle = styled(Typography).attrs({ element: 'h6', size: 'x' })`
  ${({ theme }) => css`
    font-weight: ${theme.fontWeights.semiBold};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

export const FileCardHeader = styled.header`
  display: flex;
  align-items: center;
`;

export const FileCardBody = styled.div`
  ${({ theme }) => css`
    flex: 1;
    min-width: 0;
    padding: ${em([theme.spacing.x, theme.spacing.xs])};
    padding-left: 0;
    z-index: ${theme.zIndexes.xs};
  `}
`;

export const FileCardPreviewIcon = styled(Icon)`
  ${({ theme }) => css`
    font-size: ${em(24)};
    color: ${theme.colors.dark.tint};

    svg {
      stroke-width: ${em(1.6, 24)};
    }
  `}
`;

export const FileCardPreviewImage = styled(Image)`
  width: 100%;
  height: 100%;
  border-radius: inherit;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
`;

export const FileCardPreview = styled.div`
  ${({ theme, withImage }) => [
    css`
      flex: 0 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      width: ${em(48)};
      height: 100%;
      border-radius: inherit;
    `,
    withImage &&
      css`
        margin-right: ${em(theme.spacing.xs)};
      `
  ]}
`;

export const StyledFileCard = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    align-items: center;
    height: ${rem(48)};
    max-width: ${rem(176)};
    border-radius: ${rem(theme.radius.x)};
    background: ${theme.colors.light.tint};
    transition: ${theme.transitions.fast};

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: none;
      border-radius: inherit;
      ${theme.borders.default({ inset: true, asShadow: true })};
      z-index: ${theme.zIndexes.x};
    }
  `}
`;
