import { createSelector, createSlice } from '@reduxjs/toolkit';
import { filter, find, pick } from 'lodash-es';

import { GROUP_KEY, GROUP_KEYS } from '_constants';

import { mergeObjects, normalizeGroup, removeFalsyValues } from 'utils';
import { selectUsers } from 'state/user';

export const GROUP_INITIAL_STATE = {
  entities: {}
};

const groupSlice = createSlice({
  name: GROUP_KEY,
  initialState: GROUP_INITIAL_STATE,
  reducers: {
    setEntities: {
      reducer: (state, action) => {
        state.entities = action.payload;
      },
      prepare: entities => {
        return {
          payload: entities.reduce((entities, entity) => {
            const normalizedEntity = normalizeGroup(entity);
            return { ...entities, [normalizedEntity[GROUP_KEYS.ID]]: normalizedEntity };
          }, {})
        };
      }
    },

    updateEntities: {
      reducer: (state, action) => {
        const entities = action.payload || [];
        entities.forEach(entity => {
          state.entities[entity.id] = mergeObjects(state.entities[entity.id], entity);
        });
      },
      prepare: entities => {
        return {
          payload: entities.map(entity => {
            return normalizeGroup(entity, { normalizeKeysOnly: true });
          })
        };
      }
    },

    addEntity: {
      reducer: (state, action) => {
        state.entities[action.payload.id] = mergeObjects(
          state.entities[action.payload.id],
          action.payload
        );
      },
      prepare: entity => {
        const normalizedEntity = normalizeGroup(entity);
        return { payload: normalizedEntity };
      }
    },

    updateEntity: {
      reducer: (state, action) => {
        const entity = action.payload;
        const prevEntity = state.entities[entity[GROUP_KEYS.ID]];
        state.entities[entity[GROUP_KEYS.ID]] = mergeObjects(prevEntity, entity);
      },
      prepare: payload => {
        const { entity } = payload;
        return { payload: removeFalsyValues(normalizeGroup(entity, { pickKeys: true }), ['']) };
      }
    },

    removeEntity: (state, action) => {
      const id = action.payload;
      const entity = state.entities[id];
      entity[GROUP_KEYS.USERS] = [];
      entity[GROUP_KEYS.WITH_CURRENT_USER] = false;
    },

    addEntityUsers: {
      reducer: (state, action) => {
        const { [GROUP_KEYS.ID]: id, [GROUP_KEYS.USERS]: users } = action.payload;
        const entity = state.entities[id];

        if (!entity) return;

        entity[GROUP_KEYS.USERS] = entity[GROUP_KEYS.USERS].concat(users);
      }
    },

    removeEntityUser: (state, action) => {
      const entity = state.entities[action.payload[GROUP_KEYS.ID]];
      const entityUserIndex = entity[GROUP_KEYS.USERS].findIndex(id => {
        return id === action.payload.userId;
      });
      if (entityUserIndex > -1) entity[GROUP_KEYS.USERS].splice(entityUserIndex, 1);
    }
  }
});

export const { reducer: groupReducer, actions: groupActions } = groupSlice;

export const selectGroups = state => state.group.entities;

export const selectGroupsWithUsers = createSelector(selectGroups, selectUsers, (groups, users) => {
  return Object.values(groups).map(group => {
    return { ...group, [GROUP_KEYS.USERS]: group[GROUP_KEYS.USERS].map(user => users[user]) };
  });
});

export const selectGroupsWithCurrentUser = createSelector(selectGroups, groups => {
  return filter(groups, GROUP_KEYS.WITH_CURRENT_USER);
});

export const makeSelectGroupByPredicate = () => {
  return createSelector(
    selectGroupsWithUsers,
    (_, predicate) => predicate,
    (groups, predicate) => find(groups, predicate)
  );
};

export const makeSelectGroupsMeta = () => {
  return createSelector(selectGroups, groups => {
    return Object.entries(groups).reduce((groups, [groupId, group]) => {
      return { ...groups, [groupId]: pick(group, ['id', 'name']) };
    }, {});
  });
};
