import React, { useCallback } from 'react';
import { FiX } from 'react-icons/fi';

import { EditorFilesActionRemove, StyledEditorFiles } from './EditorFiles.styles';

export const EditorFiles = ({ onRemove, ...props }) => {
  const handleRemove = useCallback(file => onRemove?.(file), [onRemove]);

  const getFileProps = useCallback(
    file => ({
      children: (
        <EditorFilesActionRemove icon={<FiX />} iconSize='x' onClick={() => handleRemove(file)} />
      )
    }),
    [handleRemove]
  );

  return <StyledEditorFiles {...props} fileProps={getFileProps} />;
};
