export const CONTACT_KEY = 'contact';

export const CONTACT_TYPE_KEY = 'contactType';

export const CONTACT_KEYS = {
  ID: 'id',
  TYPE: 'type',
  STATUS: 'status'
};

export const CONTACT_TYPES = {
  USER: 1,
  GROUP: 2
};
