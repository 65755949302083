import styled, { css } from 'styled-components';

import { Typography } from '../Typography';
import { Icon } from '../Icon';

import { em, rem } from 'styles';

export const CheckboxLabelBody = styled(Typography).attrs({ element: 'span', size: 'xs' })``;

export const CheckboxCheckIcon = styled(Icon)`
  ${({ theme }) => css`
    color: ${theme.colors.light.hue};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scale(0);
    transition: inherit;
  `}
`;

export const CheckboxCheck = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 50%;
    left: 0;
    width: ${em(16 + 2)};
    height: ${em(16 + 2)};
    border: ${theme.borders.default()};
    border-color: ${theme.colors.primary.tint};
    background: ${theme.colors.light.hue};
    border-radius: ${em(theme.radius.x)};
    transform: translateY(-50%);
    overflow: hidden;
    transition: all ${theme.transitions.fast}, background 0s;

    + ${CheckboxLabelBody} {
      padding-left: ${rem(16 + theme.spacing.x, theme.fontSizes.xs)};
    }
  `};
`;

export const CheckboxLabel = styled.label`
  ${({ theme }) => css`
    font-weight: ${theme.fontWeights.medium};
    position: relative;
    display: inline-flex;
    align-items: center;
    min-width: ${rem(16 + 2)};
    min-height: ${rem(16 + 2)};

    &:hover {
      cursor: pointer;
    }
  `}
`;

export const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: -1;

    &:checked {
      ~ ${CheckboxCheck} {
        background: ${theme.colors.primary.hue};

        ${CheckboxCheckIcon} {
          transform: scale(1);
        }
      }
    }

    &:hover {
      ~ ${CheckboxCheck} {
        border-color: ${theme.colors.primary.hue};
      }
    }

    &:focus {
      ~ ${CheckboxCheck} {
        box-shadow: ${theme.shadows.focus({ color: theme.colors.primary.hue })};
      }
    }
  `}
`;
