import React, { useEffect, useState } from 'react';
import { isUndefined, upperFirst } from 'lodash-es';

import { TabsLabel, TabsHeader, StyledTabs, TabsBody, TabsItem } from './Tabs.styles';

export const Tabs = ({
  children,
  tabs = [],
  activeTab: initialActiveTab,
  onTabChange,
  ...props
}) => {
  const [activeTab, setActiveTab] = useState(tabs[0]?.label?.name || '');

  useEffect(() => {
    !isUndefined(initialActiveTab) && setActiveTab(initialActiveTab);
  }, [initialActiveTab]);

  const handleTabChange = tab => {
    setActiveTab(tab.label.name);
    onTabChange?.(tab);
  };

  const renderTabsLabels = () => {
    return tabs.map(tab => (
      <TabsLabel
        key={tab.label.name}
        isActive={tab.label.name.match(activeTab)}
        onClick={() => handleTabChange(tab)}
      >
        {upperFirst(tab.label.name)}
        {tab.label.children}
      </TabsLabel>
    ));
  };

  const renderTabsItems = () => {
    return tabs.map(tab => (
      <TabsItem key={tab.label.name} isVisible={tab.label.name.match(activeTab)}>
        {tab.children}
      </TabsItem>
    ));
  };

  return (
    <StyledTabs {...props}>
      <TabsHeader>{renderTabsLabels()}</TabsHeader>
      <TabsBody>
        {renderTabsItems()}
        {children}
      </TabsBody>
    </StyledTabs>
  );
};
