import styled, { css } from 'styled-components';

import { StyledModal } from 'components/_shared/Modal';

import { em } from 'styles';

export const StyledUserInfo = styled.div`
  ${({ theme }) => css`
    position: relative;
    flex: 1;

    > * {
      &:not(:first-child):not(${StyledModal}) {
        margin-top: ${em(theme.spacing.ml)};
      }
    }
  `}
`;
