import React from 'react';
import * as Yup from 'yup';

import { StyledGroupForm } from './GroupForm.styles';

import { GROUP_KEYS } from '_constants';

import { FormField, FormGroup } from 'components/_shared/Form';
import { Field } from 'components/_shared/Field';

import { formatGroupProfileLabel } from 'utils';

const validationSchema = Yup.object().shape({
  [GROUP_KEYS.NAME]: Yup.string().required(),
  [GROUP_KEYS.EMAIL]: Yup.string().email(),
  [GROUP_KEYS.EMAIL_PERSONAL]: Yup.string().email(),
  [GROUP_KEYS.EMAIL_BUSINESS]: Yup.string().email()
});

export const GroupForm = ({ initialValues = {}, onCancel, ...props }) => {
  const values = {
    [GROUP_KEYS.AVATAR]: '',
    [GROUP_KEYS.NAME]: '',
    [GROUP_KEYS.EMAIL]: '',
    [GROUP_KEYS.EMAIL_PERSONAL]: '',
    [GROUP_KEYS.EMAIL_BUSINESS]: '',
    ...initialValues
  };

  return (
    <StyledGroupForm
      {...props}
      isPreloaderTransparent
      initialValues={values}
      validationSchema={validationSchema}
      params={{ enableReinitialize: true }}
      defaultActions={{
        cancel: { onClick: () => onCancel?.() },
        submit: { children: 'Save' }
      }}
    >
      {form => (
        <FormGroup direction='row'>
          <FormGroup>
            <FormField
              type='text'
              label={GROUP_KEYS.NAME}
              name={GROUP_KEYS.NAME}
              control={{ placeholder: 'Enter group name' }}
            />

            <FormField
              type='email'
              label={GROUP_KEYS.EMAIL}
              name={GROUP_KEYS.EMAIL}
              control={{ placeholder: 'Enter email' }}
            />

            <FormField
              type='email'
              label={formatGroupProfileLabel(GROUP_KEYS.EMAIL_BUSINESS)}
              name={GROUP_KEYS.EMAIL_BUSINESS}
              control={{ placeholder: 'Enter business email' }}
            />

            <FormField
              type='email'
              label={formatGroupProfileLabel(GROUP_KEYS.EMAIL_PERSONAL)}
              name={GROUP_KEYS.EMAIL_PERSONAL}
              control={{ placeholder: 'Enter personal email' }}
            />
          </FormGroup>

          <FormGroup>
            <Field
              type='image'
              label='Profile image'
              control={{
                value: { src: form.values[GROUP_KEYS.AVATAR] },
                withUploader: true,
                onChange: value => form.setFieldValue(GROUP_KEYS.AVATAR, value.src)
              }}
            />
          </FormGroup>
        </FormGroup>
      )}
    </StyledGroupForm>
  );
};
