import styled, { css } from 'styled-components';

import { Page } from 'components/_shared/Page';
import { LayoutMain } from 'components/Layout';
import { FieldMessageError } from 'components/_shared/Field';

import {
  FormAction,
  FormHeading,
  FormMessage,
  FormMessageError,
  StyledForm
} from 'components/_shared/Form';

import { rem } from 'styles';

export const StyledPagePasswordReset = styled(Page)`
  ${({ theme }) => css`
    background: ${theme.colors.primary.hue};

    ${LayoutMain} {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: ${rem(theme.spacing['2xl'])};
      padding-bottom: ${rem(theme.spacing['2xl'] * 2)};
    }

    ${StyledForm} {
      color: ${theme.colors.light.hue};
      max-width: ${rem(266)};
      margin: auto;
    }

    ${FormHeading} {
      text-align: center;
    }

    ${FormMessage}:not(${FormMessageError}) {
      text-align: center;
    }

    ${FormMessageError},
    ${FieldMessageError} {
      color: ${theme.colors.warning.hue};
    }

    ${FormAction} {
      &.secondary {
        &:hover {
          background: ${theme.colors.light.hue};
        }
      }

      &:last-child {
        flex: 1;
      }
    }
  `}
`;
