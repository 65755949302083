import React from 'react';
import cn from 'classnames';
import { FiSend } from 'react-icons/fi';
import { AiTwotoneEdit } from 'react-icons/ai';

import { StyledEditorActionSubmit } from './EditorActionSubmit.styles';

import { MESSAGE_ACTION_TYPES } from '_constants';

export const EditorActionSubmit = ({
  className,
  icon,
  actionType,
  isEdit: initialIsEdit,
  ...props
}) => {
  const isEdit = initialIsEdit ?? actionType === MESSAGE_ACTION_TYPES.EDIT;

  return (
    <StyledEditorActionSubmit
      {...props}
      className={cn(className, { isEdit })}
      type='submit'
      variant='tertiary'
      icon={icon || (isEdit ? <AiTwotoneEdit /> : <FiSend />)}
      iconSize='s'
    />
  );
};
