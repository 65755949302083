import React from 'react';

import { FileViewerImageSource, StyledFileViewerImage } from './FileViewerImage.styles';

export const FileViewerImage = ({ file, ...props }) => {
  return (
    <StyledFileViewerImage {...props}>
      <FileViewerImageSource src={file} />
    </StyledFileViewerImage>
  );
};
