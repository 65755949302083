import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { find } from 'lodash-es';

import { ChatViewAside, ChatViewMain, StyledChatView } from './ChatView.styles';

import { CONTACT_TYPES, ROUTES } from '_constants';

import { ChatViewActions } from './Actions';
import { UserMenu } from 'components/User';
import { GroupMenu } from 'components/Group';
import { ChatMessageEditor, ChatMessageList, ChatMessagePinned } from '../Message';

import {
  makeSelectChatContact,
  makeSelectChatMessagesUI,
  makeSelectChatPinnedMessages,
  makeSelectIsChatAvailable,
  useChatActions
} from 'state';

export const ChatView = ({ history, match, ...props }) => {
  const contactId = match.params.contactId;

  const chatMessageEditorRef = useRef(null);
  const prevContactIdRef = useRef(contactId);

  const { switchActiveChat, setActiveContactId } = useChatActions();

  const selectIsChatAvailable = useMemo(makeSelectIsChatAvailable, []);
  const isChatAvailable = useSelector(state => selectIsChatAvailable(state, contactId));

  const selectChatContact = useMemo(makeSelectChatContact, []);
  const chatContact = useSelector(state => selectChatContact(state, contactId));

  const selectChatMessages = useMemo(makeSelectChatMessagesUI, []);
  const chatMessages = useSelector(state => selectChatMessages(state, contactId));

  const selectChatPinnedMessages = useMemo(makeSelectChatPinnedMessages, []);
  const chatPinnedMessages = useSelector(state => selectChatPinnedMessages(state, contactId));

  const pinnedMessages = useMemo(() => {
    return chatPinnedMessages.messages.map(id => find(chatMessages, ['id', id]));
  }, [chatMessages, chatPinnedMessages.messages]);

  const _isChatAsideOpen = chatPinnedMessages.isOpen || !!pinnedMessages.length;
  const [isChatAsideOpen, setIsChatAsideOpen] = useState(_isChatAsideOpen);
  useEffect(() => setIsChatAsideOpen(_isChatAsideOpen), [_isChatAsideOpen]);

  useEffect(() => {
    if (prevContactIdRef.current === contactId) {
      switchActiveChat(contactId);
    } else {
      const { value, files } = chatMessageEditorRef.current;
      switchActiveChat(contactId, {
        prevChat: {
          contactId: prevContactIdRef.current,
          editor: { value, files }
        }
      });
    }

    prevContactIdRef.current = contactId;
  }, [contactId, switchActiveChat]);

  const chatTypeProps = {
    [CONTACT_TYPES.USER]: {
      heading: chatContact.username,
      menu: <UserMenu user={chatContact} />,
      profileRoute: ROUTES.USER_BY_ID
    },
    [CONTACT_TYPES.GROUP]: {
      heading: chatContact.name,
      menu: <GroupMenu group={chatContact} />,
      profileRoute: ROUTES.GROUP_BY_ID
    }
  }[chatContact.contactType];

  if (!isChatAvailable) {
    setActiveContactId('');
    return <Redirect to={ROUTES.ROOT} />;
  }

  return (
    <StyledChatView
      {...props}
      avatar={chatContact.avatar}
      heading={chatTypeProps.heading}
      menu={chatTypeProps.menu}
      actions={<ChatViewActions contactId={contactId} />}
      onBackClick={false}
      onProfileClick={() => history.push(chatTypeProps.profileRoute(contactId))}
    >
      <ChatViewMain>
        <ChatMessageList contactId={contactId} messages={chatMessages} />
        <ChatMessageEditor ref={chatMessageEditorRef} contactId={contactId} />
      </ChatViewMain>

      {isChatAsideOpen && (
        <ChatViewAside>
          <ChatMessagePinned contactId={contactId} messages={pinnedMessages} />
        </ChatViewAside>
      )}
    </StyledChatView>
  );
};
