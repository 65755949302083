import React, { useState, useEffect } from 'react';

import { StyledPageAuth } from './PageAuth.styles';

import { AUTH_METHODS } from '_constants';

import { Logo } from 'components/Logo';
import { AuthForm } from 'components/Auth';

import { useAuthActions } from 'state';

export const PageAuth = () => {
  const [authMethod, setAuthMethod] = useState(AUTH_METHODS.SIGN_IN);
  const { error, isLoading, updateRequestState, authorize } = useAuthActions();

  useEffect(() => updateRequestState({ error: null }), [authMethod, updateRequestState]);

  const handleSubmit = values => authorize(authMethod, values);

  const toggleMethod = () => {
    return setAuthMethod(prevAuthMethod => {
      return prevAuthMethod.match(AUTH_METHODS.SIGN_IN)
        ? AUTH_METHODS.SIGN_UP
        : AUTH_METHODS.SIGN_IN;
    });
  };

  return (
    <StyledPageAuth sidebar={false}>
      <Logo />
      <AuthForm
        key={authMethod}
        authMethod={authMethod}
        error={error}
        isLoading={isLoading}
        onRedirectButtonClick={toggleMethod}
        onSubmit={handleSubmit}
      />
    </StyledPageAuth>
  );
};
