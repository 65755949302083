import styled, { css } from 'styled-components';

import { View, ViewBody } from 'components/_shared/View';
import { StyledChatMessageList } from '../Message';

import { rem } from 'styles';

export const ChatViewAside = styled.aside`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex: 0 1 ${rem(320)};
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    border-left: ${theme.borders.secondary()};
    background: ${theme.colors.view.aside};
    transition: ${theme.transitions.fast};
  `}
`;

export const ChatViewMain = styled.main`
  ${({ theme }) => css`
    flex: 1 0;
    display: flex;
    flex-direction: column;

    ${StyledChatMessageList} {
      flex: 1;
      padding: ${rem([theme.spacing.ml])};
    }
  `}
`;

export const StyledChatView = styled(View)`
  ${ViewBody} {
    flex-direction: row;
    padding: 0;
  }
`;
