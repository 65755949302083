import React, { useState } from 'react';
import { FiDownload } from 'react-icons/fi';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { downloadFile } from 'utils';

import {
  FileViewerPDFNav,
  FileViewerPDFNavButtonNext,
  FileViewerPDFNavButtonPrevious,
  FileViewerPDFNavButtonDownload,
  FileViewerPDFNavCount,
  StyledFileViewerPDF
} from './FileViewerPDF.styles';

export const FileViewerPDF = ({ file, ...props }) => {
  const [isLoaded, setIsLoaded] = useState(null);
  const [pageCount, setPageCount] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const isPreviousDisabled = pageNumber === 1;
  const isNextDisabled = pageNumber === pageCount;

  const handleDocumentLoadSuccess = ({ numPages }) => {
    setPageCount(numPages);
    setIsLoaded(true);
  };

  const handlePreviousClick = () => {
    setPageNumber(prevPageNumber => {
      const nextPageNumber = prevPageNumber - 1;
      return nextPageNumber < 1 ? 1 : nextPageNumber;
    });
  };

  const handleNextClick = () => {
    setPageNumber(prevPageNumber => prevPageNumber + 1);
  };

  const handleDownloadClick = () => {
    downloadFile(file, 'document');
  };

  return (
    <StyledFileViewerPDF {...props}>
      <Document file={file} onLoadSuccess={handleDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} width={640} scale={1.1} />
      </Document>

      {isLoaded && (
        <FileViewerPDFNav>
          <FileViewerPDFNavButtonPrevious
            disabled={isPreviousDisabled}
            onClick={handlePreviousClick}
          >
            Previous
          </FileViewerPDFNavButtonPrevious>

          <FileViewerPDFNavCount>
            {pageNumber} of {pageCount}
          </FileViewerPDFNavCount>

          <FileViewerPDFNavButtonNext disabled={isNextDisabled} onClick={handleNextClick}>
            Next
          </FileViewerPDFNavButtonNext>

          <FileViewerPDFNavButtonDownload icon={<FiDownload />} onClick={handleDownloadClick} />
        </FileViewerPDFNav>
      )}
    </StyledFileViewerPDF>
  );
};
