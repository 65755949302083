import styled, { css } from 'styled-components';

import { Button } from 'components/_shared/Button';
import { Dropdown } from 'components/_shared/Dropdown';

import { em } from 'styles';

export const MessageCardAction = styled(Button).attrs({ iconSize: 'sx' })`
  ${({ theme, isDanger }) => [
    css`
      color: ${theme.colors.primary.hue};
    `,
    isDanger &&
      css`
        color: ${theme.colors.danger.hue};
      `
  ]}
`;

export const MessageCardActionUnpin = styled(MessageCardAction)`
  ${({ theme }) => css`
    svg {
      color: ${theme.colors.primary.hue};
      fill: ${theme.colors.primary.hue};
    }
  `}
`;

export const MessageCardActionsWrapper = styled.div`
  ${({ theme }) => {
    const childSpacing = em(theme.spacing.xs - 4);

    return css`
      margin: -${childSpacing};

      ${MessageCardAction} {
        margin: ${childSpacing};
      }
    `;
  }}
`;

export const StyledMessageCardActions = styled(Dropdown)`
  ${({ theme }) => css`
    top: auto;
    right: 0;
    bottom: ${`calc(100% - ${em(theme.spacing.sx)})`};
    padding: ${em([theme.spacing.sx - 4, theme.spacing.ms - 4])};
  `}
`;
