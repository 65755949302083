import { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { pick } from 'lodash-es';

import { authActions } from './auth.slice';

import { API } from 'api';
import { AUTH_KEYS, AUTH_METHODS, ROUTES, USER_KEYS } from '_constants';

import { userActions } from 'state/user';
import { useRequest } from 'hooks';
import { getCookieAuth, handleResponseError } from 'utils';

export const useAuthActions = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { error, isLoading, updateRequestState, makeRequest } = useRequest();

  const authorize = useCallback(
    async (authMethod, user) => {
      try {
        const response = await makeRequest(API[authMethod](user));

        if (authMethod === AUTH_METHODS.SIGN_UP) {
          return authorize(AUTH_METHODS.SIGN_IN, user);
        }

        const newAuthState = {
          ...response.data,
          [AUTH_KEYS.USERNAME]: user[AUTH_KEYS.USERNAME],
          [AUTH_KEYS.PASSWORD]: window.btoa(user[AUTH_KEYS.PASSWORD])
        };

        const currentUser = {
          ...pick(newAuthState, ['UserID', AUTH_KEYS.USERNAME]),
          [USER_KEYS.IS_CURRENT_USER]: true
        };

        dispatch(authActions.signIn(newAuthState));
        dispatch(userActions.addEntity(currentUser));

        const nextLocation = history.location.state?.prevLocation || ROUTES.ROOT;
        history.replace(nextLocation);
      } catch (error) {
        handleResponseError(error);
        dispatch(authActions.signOut());
      }
    },
    [dispatch, history, makeRequest]
  );

  const signOut = useCallback(() => dispatch(authActions.signOut()), [dispatch]);

  const updatePassword = useCallback(
    password => {
      dispatch(authActions.updatePassword(password));
    },
    [dispatch]
  );

  const reAuthorize = useRef(async () => {
    const auth = getCookieAuth();

    if (!auth[AUTH_KEYS.USERNAME] || !auth[AUTH_KEYS.PASSWORD]) {
      return history.replace({
        pathname: ROUTES.AUTH,
        state: { prevLocation: history.location }
      });
    }

    await authorize(AUTH_METHODS.SIGN_IN, {
      [AUTH_KEYS.USERNAME]: auth[AUTH_KEYS.USERNAME],
      [AUTH_KEYS.PASSWORD]: window.atob(auth[AUTH_KEYS.PASSWORD])
    });
  });

  return {
    error,
    isLoading,
    updateRequestState,
    authorize,
    signOut,
    updatePassword,
    reAuthorize: reAuthorize.current
  };
};
