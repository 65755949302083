import Cookie from 'js-cookie';
import { pick } from 'lodash-es';

import { AUTH_KEY, AUTH_KEYS_MAP, AUTH_TEMPLATE } from '_constants';

import { mergeObjects, normalizeKeysByMap, withMergeObjects } from './helpers';

export const createAuth = (auth = {}) => {
  return pick(mergeObjects(AUTH_TEMPLATE, auth), Object.keys(AUTH_TEMPLATE));
};

export const normalizeAuth = (auth = {}) => {
  const [getAuth, setAuth] = withMergeObjects();
  setAuth(normalizeKeysByMap(auth, AUTH_KEYS_MAP));
  setAuth(createAuth(getAuth()));
  return getAuth();
};

export const getCookieAuth = () => Cookie.getJSON(AUTH_KEY) || {};
