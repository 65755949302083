import styled, { css } from 'styled-components';

import { FileView, FileViewPreview } from 'components/_shared/File';

import { em } from 'styles';

export const StyledMessageCardFileView = styled(FileView)`
  ${({ theme }) => css`
    ${FileViewPreview} {
      max-width: ${em(300)};
      max-height: ${em(168)};
      margin-top: ${em(theme.spacing.xs)};
    }
  `}
`;
