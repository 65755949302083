import styled, { css } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import { em } from 'styles';

export const StyledNotification = styled(ToastContainer)`
  ${({ theme }) => css`
    .Toastify {
      &__toast {
        border-radius: ${em(theme.radius.sx)};
        background: ${theme.colors.light.hue};
        padding: ${em(theme.spacing.xs)};
        box-shadow: ${theme.shadows.default()};

        &-body {
          font-family: ${theme.fonts.primary};
          font-size: ${em(theme.fontSizes.xs)};
          color: ${theme.colors.dark.hue};
          padding: ${em([theme.spacing.xs - 2, theme.spacing.xs, theme.spacing.xs])};
        }

        .hightlight {
          color: ${theme.colors.primary.hue};
        }
      }

      &__close {
        &-button {
          color: ${theme.colors.dark.tone};
          opacity: 1;

          &:hover,
          &:focus {
            color: ${theme.colors.primary.hue};
          }
        }
      }
    }
  `}
`;
