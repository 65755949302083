import React, { Children } from 'react';

import { StyledFormGroup, FormGroupItem } from './FormGroup.styles';

export const FormGroup = ({ children, ...props }) => {
  return (
    <StyledFormGroup direction='column' {...props}>
      {Children.map(children, child => {
        return child ? <FormGroupItem>{child}</FormGroupItem> : null;
      })}
    </StyledFormGroup>
  );
};
