import { themeBlack } from './theme.black';
import { themeBlue } from './theme.blue';
import { themeBlueDark } from './theme.blueDark';
import { themeBrown } from './theme.brown';
import { themeBrownDark } from './theme.brownDark';
import { themeGray } from './theme.gray';
import { themeGreen } from './theme.green';
import { themeOrange } from './theme.orange';
import { themePurple } from './theme.purple';

export const themes = {
  themeBlack,
  themeBlue,
  themeBlueDark,
  themeBrown,
  themeBrownDark,
  themeGray,
  themeGreen,
  themeOrange,
  themePurple
};
