import styled, { css } from 'styled-components';

import { Typography } from '../Typography';
import { Overlay } from '../Overlay';
import { Icon } from '../Icon';

import { shouldForwardProp } from 'utils';
import { em } from 'styles';

export const PrelaoderOverlay = styled(Overlay)``;

export const PreloaderBody = styled.div`
  ${({ theme, isDisplayed }) => [
    css`
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: ${theme.zIndexes.xs + 1};
      transition: ${theme.transitions.default};
      opacity: 0;

      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: ${em(theme.radius.xs)};
        background: ${theme.colors.light.hue};
        padding: ${em([theme.spacing.ml, theme.spacing.ml])};
        box-shadow: ${theme.shadows.default()};
      }
    `,
    isDisplayed &&
      css`
        opacity: 1;

        &::before {
          ${theme.animations.breathe};
        }
      `
  ]}
`;

export const PreloaderIcon = styled(Icon)`
  ${({ theme }) => css`
    color: ${theme.colors.primary.hue};
    ${theme.animations.rotationWithScale};
  `}
`;

export const PreloaderTitle = styled(Typography).attrs({ element: 'p' })`
  ${({ theme, size }) => css`
    color: ${theme.colors.dark.hue};
    margin-top: ${em(theme.spacing.xs, theme.fontSizes[size])};
  `}
`;

export const StyledPreloader = styled.div.withConfig({ shouldForwardProp })`
  ${({ positionVariant }) => css`
    position: ${positionVariant};
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  `};
`;
