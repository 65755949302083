import styled, { css } from 'styled-components';

import { Button } from 'components/_shared/Button';
import { Typography } from 'components/_shared/Typography';
import { MessageCardTooltip, StyledMessageCard, StyledMessageList } from 'components/Message';

import { em, rem } from 'styles';

export const ChatMessagePinnedSectionBody = styled.div`
  flex: 1;
`;

export const ChatMessagePinnedSectionAction = styled(Button)`
  ${({ theme, size }) => css`
    font-weight: ${theme.fontWeights.semiBold};
    color: ${theme.colors.primary.hue};
    border-radius: ${em(theme.radius.x, theme.fontSizes[size])};
    padding: ${em([1, theme.spacing.x, 0], theme.fontSizes[size])};
  `}
`;

export const ChatMessagePinnedSectionActions = styled.div`
  ${({ theme }) => {
    const childSpacing = theme.spacing.xs;

    return css`
      display: flex;
      align-items: center;
      margin: -${em(childSpacing)};
      margin-left: auto;

      ${ChatMessagePinnedSectionAction} {
        margin: ${em(childSpacing)};
      }
    `;
  }}
`;

export const ChatMessagePinnedSectionLabel = styled(Typography).attrs({
  element: 'h6',
  size: 'x'
})`
  ${({ theme }) => css`
    font-weight: ${theme.fontWeights.medium};
  `}
`;

export const ChatMessagePinnedSectionHeader = styled.header`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    + * {
      margin-top: ${rem(theme.spacing.sx)};
    }
  `}
`;

export const ChatMessagePinnedSection = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const StyledChatMessagePinned = styled.div`
  ${({ theme }) => css`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: ${rem([theme.spacing.sm, theme.spacing.ml])};

    ${StyledMessageList} {
      height: 100%;
      overflow: initial;
    }

    ${StyledMessageCard} {
      width: 100%;
      max-width: 100%;
    }

    ${MessageCardTooltip} {
      display: none;
    }
  `}
`;
