import styled, { css } from 'styled-components';

import { Icon } from '../Icon';
import { Image } from '../Image';

import { shouldForwardProp } from 'utils';
import { em } from 'styles';

export const AvatarIcon = styled(Icon)`
  ${({ theme }) => css`
    color: ${theme.colors.dark.tone};
  `}
`;

export const AvatarImage = styled(Image)`
  width: 100%;
  height: 100%;
  border-radius: inherit;
`;

export const StyledAvatar = styled.div.withConfig({ shouldForwardProp })`
  ${({ theme, hasImage, onClick }) => [
    css`
      display: flex;
      align-items: center;
      justify-content: center;
      width: ${em(32)};
      height: ${em(32)};
      border-radius: ${em(theme.radius.xs)};
      background: ${theme.colors.light.tint};
      overflow: hidden;
    `,
    !hasImage &&
      css`
        border: ${theme.borders.secondary()};
      `,
    onClick &&
      css`
        transition: ${theme.transitions.medium};
        cursor: pointer;

        ${AvatarImage} {
          transition: inherit;
        }

        &:hover,
        &:focus {
          ${AvatarImage} {
            transform: scale(1.1);
          }
        }
      `
  ]}
`;
