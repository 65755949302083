import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { StyledGroupUsersModalShow } from './GroupUsersModalShow.styles';

import { GROUP_KEYS, MODAL_KEYS, USER_KEYS } from '_constants';

import {
  makeSelectGroupByPredicate,
  selectUsersWithoutCurrentUser,
  useGroupActions,
  useModal
} from 'state';

import { ContactList } from 'components/Contact';
import { Button } from 'components/_shared/Button';
import { Preloader } from 'components/_shared/Preloader';

export const GroupUsersModalShow = ({ groupId, ...props }) => {
  const { openModal } = useModal();

  const { isLoading, removeGroupUser } = useGroupActions();

  const selectGroupByPredicate = useMemo(makeSelectGroupByPredicate, []);
  const group = useSelector(state => selectGroupByPredicate(state, [GROUP_KEYS.ID, groupId]));

  const users = useSelector(selectUsersWithoutCurrentUser);
  const groupUsers = users.filter(user => {
    return group[GROUP_KEYS.USERS].some(groupUser => {
      return groupUser[USER_KEYS.ID] === user[USER_KEYS.ID];
    });
  });

  const handleContactRemove = user => {
    openModal(MODAL_KEYS.CONFIRM, {
      heading: `Are you sure you want to remove the ${user[USER_KEYS.USERNAME]} user?`,
      actions: {
        submit: {
          children: 'Remove',
          onClick: async () => {
            await removeGroupUser(groupId, user[USER_KEYS.ID]);
          }
        }
      }
    });
  };

  return (
    <StyledGroupUsersModalShow
      {...props}
      heading={`${group[GROUP_KEYS.NAME]} group members`}
      actions={[
        ({ closeModal }) => (
          <Button type='button' variant='secondary' onClick={closeModal}>
            Close
          </Button>
        )
      ]}
    >
      <ContactList contacts={groupUsers} onContactRemove={handleContactRemove} />

      {isLoading && <Preloader isTransparent />}
    </StyledGroupUsersModalShow>
  );
};
