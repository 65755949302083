import styled, { css } from 'styled-components';

import { Typography } from 'components/_shared/Typography';

import { em } from 'styles';

export const WelcomeMessage = styled(Typography).attrs({ element: 'p', size: 'xs' })`
  ${({ theme, size }) => {
    const fontSize = theme.fontSizes[size];

    return css`
      font-weight: ${theme.fontWeights.medium};
      color: ${theme.colors.dark.shade};
      border-radius: ${em(theme.radius.x, fontSize)};
      background: ${theme.colors.light.hue};
      padding: ${em([theme.spacing.sm, theme.spacing.lm], fontSize)};
    `;
  }}
`;

export const StyledWelcome = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: ${theme.colors.view.background};
  `}
`;
