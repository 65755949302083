import styled from 'styled-components';

import { Image } from 'components/_shared/Image';

export const FileViewerImageSource = styled(Image)``;

export const StyledFileViewerImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
