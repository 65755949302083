import React from 'react';
import { upperFirst } from 'lodash-es';

import { StyledContactListGrouped } from './ContactListGrouped.styles';

import { ContactList } from '../ContactList';

export const ContactListGrouped = React.forwardRef(
  ({ contacts = [], groupKeys = [], condition, contactProps, ...props }, ref) => {
    const contactListGroups = contacts.reduce(
      (contacts, contact) => {
        const key = condition?.(contact) || 'other';
        return { ...contacts, [key]: [...contacts[key], contact] };
      },
      { ...groupKeys.reduce((keys, key) => ({ ...keys, [key]: [] }), {}), other: [] }
    );

    const renderContactListGroup = ([key, contacts]) => {
      if (!contacts?.length) return null;

      return (
        <ContactList
          key={key}
          label={`${upperFirst(key)} — ${contacts.length}`}
          contacts={contacts}
          contactProps={contactProps}
        />
      );
    };

    return (
      <StyledContactListGrouped {...props} ref={ref}>
        {Object.entries(contactListGroups).map(renderContactListGroup)}
      </StyledContactListGrouped>
    );
  }
);
