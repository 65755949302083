import React from 'react';
import cn from 'classnames';
import { FiFile, FiImage } from 'react-icons/fi';
import { VscFilePdf } from 'react-icons/vsc';

import { StyledFilePreview, FilePreviewImage, FilePreviewIcon } from './FilePreview.styles';

import { getFileType } from 'utils';

const fileTypeIcons = {
  file: FiFile,
  application: VscFilePdf,
  image: FiImage
};

export const FilePreview = ({ className, children, file, ...props }) => {
  const fileType = getFileType(file);
  const canRenderImage = !!file && fileType !== 'application';

  const renderIcon = () => {
    const Icon = fileTypeIcons[fileType || 'file'];

    return (
      <FilePreviewIcon size={22}>
        <Icon />
      </FilePreviewIcon>
    );
  };

  return (
    <StyledFilePreview {...props} fileType={fileType} className={cn(className, fileType)}>
      {canRenderImage ? <FilePreviewImage src={file} /> : renderIcon()}
      {children}
    </StyledFilePreview>
  );
};
