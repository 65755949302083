import React, { useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { StylesGroupProfileView, GroupProfileViewActionEdit } from './GroupProfileView.styles';

import { GroupInfo } from '../Info';
import { GroupForm } from '../Form';
import { Preloader } from 'components/_shared/Preloader';

import { GROUP_KEYS } from '_constants';

import { makeSelectGroupByPredicate, selectGroups, useGroupActions } from 'state';
import { pickGroupProfileFields } from 'utils';

export const GroupProfileView = ({ match, ...props }) => {
  const [isEditing, setIsEditing] = useState(false);
  const { isLoading, getGroupProfile, updateGroup } = useGroupActions();

  const requestedGroupId = match.params.id;

  const groups = useSelector(selectGroups);

  const selectGroupByPredicate = useMemo(makeSelectGroupByPredicate, []);
  const group = useSelector(state => {
    return selectGroupByPredicate(state, [GROUP_KEYS.ID, requestedGroupId]);
  });

  const isExistedName = name => {
    return Object.values(groups).some(group =>
      group[GROUP_KEYS.NAME].match(new RegExp(`\\b${name}\\b`, 'gi'))
    );
  };

  useEffect(() => {
    (async () => await getGroupProfile(requestedGroupId))();
  }, [requestedGroupId, getGroupProfile]);

  const handleFormSubmit = async (values, helpers) => {
    try {
      if (
        isExistedName(values[GROUP_KEYS.NAME]) &&
        group[GROUP_KEYS.NAME] !== values[GROUP_KEYS.NAME]
      ) {
        return helpers.setFieldError(GROUP_KEYS.NAME, 'This group name already exists.');
      }

      await updateGroup(values);
      setIsEditing(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <StylesGroupProfileView {...props} heading='Group profile'>
      {isEditing ? (
        <GroupForm
          initialValues={pickGroupProfileFields(group)}
          isLoading={isLoading}
          onSubmit={handleFormSubmit}
          onCancel={() => setIsEditing(false)}
        />
      ) : (
        <GroupInfo group={group}>
          {isLoading && <Preloader isTransparent />}
          <GroupProfileViewActionEdit onClick={() => setIsEditing(true)}>
            Edit
          </GroupProfileViewActionEdit>
        </GroupInfo>
      )}
    </StylesGroupProfileView>
  );
};
