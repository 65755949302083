import { MESSAGE_ACTION_TYPES } from './message';

export const CHAT_KEY = 'chat';

export const CHAT_KEYS = {
  ID: 'id',
  MESSAGES: 'messages',
  PINNED_MESAGES: 'pinnedMessages',
  SELECTED_MESAGES: 'selectedMessages',
  CONTACT: 'contact',
  USERS: 'users',
  ACTION: 'action',
  EDITOR: 'editor',
  UPDATES: 'updates'
};

export const CHAT_PINNED_MESSAGES_KEYS = {
  MESSAGES: 'messages',
  IS_OPEN: 'isOpen'
};

export const CHAT_CONTACT_KEYS = {
  ID: 'id',
  TYPE: 'type',
  CHAT_ID: 'chatId'
};

export const CHAT_ACTION_KEYS = {
  TYPE: 'type',
  MESSAGE: 'message'
};

export const CHAT_EDITOR_KEYS = {
  VALUE: 'value',
  FILES: 'files',
  PREVIEW: 'preview',
  IS_READY: 'isReady'
};

export const CHAT_EDITOR_PREVIEW_KEYS = {
  HEADING: 'heading',
  TEXT: 'text',
  ON_CLOSE: 'onClose',
  ON_CLICK: 'onClick'
};

export const CHAT_UPDATES_KEYS = {
  MESSAGES: 'messages',
  NEW: 'new',
  EDITED: 'edited',
  DELETED: 'deleted',
  HAS_UPDATES: 'hasUpdates'
};

export const CHAT_KEYS_MAP = {
  [CHAT_KEYS.ID]: ['ChatID', 'chatId', 'UserID', 'userId']
};

export const CHAT_PINNED_MESSAGES_TEMPLATE = {
  [CHAT_PINNED_MESSAGES_KEYS.MESSAGES]: [],
  [CHAT_PINNED_MESSAGES_KEYS.IS_OPEN]: false
};

export const CHAT_CONTACT_TEMPLATE = {
  [CHAT_CONTACT_KEYS.ID]: '',
  [CHAT_CONTACT_KEYS.TYPE]: '',
  [CHAT_CONTACT_KEYS.CHAT_ID]: ''
};

export const CHAT_ACTION_TEMPLATE = {
  [CHAT_ACTION_KEYS.TYPE]: MESSAGE_ACTION_TYPES.SEND,
  [CHAT_ACTION_KEYS.MESSAGE]: null
};

export const CHAT_EDITOR_PREVIEW_TEMPLATE = {
  [CHAT_EDITOR_PREVIEW_KEYS.HEADING]: '',
  [CHAT_EDITOR_PREVIEW_KEYS.TEXT]: '',
  [CHAT_EDITOR_PREVIEW_KEYS.ON_CLOSE]: undefined,
  [CHAT_EDITOR_PREVIEW_KEYS.ON_CLICK]: undefined
};

export const CHAT_EDITOR_TEMPLATE = {
  [CHAT_EDITOR_KEYS.VALUE]: '',
  [CHAT_EDITOR_KEYS.FILES]: [],
  [CHAT_EDITOR_KEYS.IS_READY]: false,
  [CHAT_EDITOR_KEYS.PREVIEW]: CHAT_EDITOR_PREVIEW_TEMPLATE
};

export const CHAT_UPDATES_TEMPLATE = {
  [CHAT_UPDATES_KEYS.MESSAGES]: {
    [CHAT_UPDATES_KEYS.NEW]: 0,
    [CHAT_UPDATES_KEYS.EDITED]: 0,
    [CHAT_UPDATES_KEYS.DELETED]: 0
  },
  [CHAT_UPDATES_KEYS.HAS_UPDATES]: false
};

export const CHAT_TEMPLATE = {
  [CHAT_KEYS.ID]: '',
  [CHAT_KEYS.MESSAGES]: {},
  [CHAT_KEYS.PINNED_MESAGES]: CHAT_PINNED_MESSAGES_TEMPLATE,
  [CHAT_KEYS.SELECTED_MESAGES]: [],
  [CHAT_KEYS.CONTACT]: CHAT_CONTACT_TEMPLATE,
  [CHAT_KEYS.USERS]: [],
  [CHAT_KEYS.ACTION]: CHAT_ACTION_TEMPLATE,
  [CHAT_KEYS.EDITOR]: CHAT_EDITOR_TEMPLATE,
  [CHAT_KEYS.UPDATES]: CHAT_UPDATES_TEMPLATE
};
