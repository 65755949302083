import React, { useState, useRef, useCallback, useEffect } from 'react';

import { StyledTagContact, TagContactList } from './TagContact.styles';

import { TAG_KEYS } from '_constants';

import { createTag, getLastTagEntries, isMatchQuery } from 'utils';

const filterContacts = (contacts, query) => {
  return contacts.filter(({ username }) => isMatchQuery(query, username));
};

export const TagContact = ({
  contacts: initialContacts = [],
  value: initialValue = '',
  onTag,
  ...props
}) => {
  const value = initialValue.trim();
  const lastValueRef = useRef(value);

  const [isOpen, setIsOpen] = useState(false);
  const [contacts, setContacts] = useState(initialContacts);

  const tagContact = useCallback(
    contact => {
      const tag = createTag(contact.username, TAG_KEYS.MENTION);
      onTag?.(tag, contact);
    },
    [onTag]
  );

  const close = useCallback(() => setIsOpen(false), []);

  const handleValue = useCallback(
    value => {
      if (!value) return close();

      const valuesEntries = [value, lastValueRef.current].map(getLastTagEntries);
      const [[, tagValue, tagKey], [, prevTagValue]] = valuesEntries;
      const isMentionTag = tagKey === TAG_KEYS.MENTION;

      if (!isMentionTag || tagValue === prevTagValue) return close();

      const tagTrigger = tagValue.slice(0);
      const tagText = tagValue.slice(1);
      const filteredContacts = filterContacts(initialContacts, tagText);

      lastValueRef.current = value;

      if (value === tagTrigger) {
        lastValueRef.current = '';
      }

      setContacts(filteredContacts?.length || tagText.length ? filteredContacts : initialContacts);
      setIsOpen(true);

      const matchedContact = filteredContacts.find(({ username }) => username === tagText);

      if (matchedContact) return close();
    },
    [close, initialContacts]
  );

  const getContactProps = useCallback(
    contact => ({
      onClick: () => tagContact(contact)
    }),
    [tagContact]
  );

  useEffect(() => handleValue(value), [value, handleValue]);

  return (
    <StyledTagContact {...props} isOpen={isOpen}>
      <TagContactList contacts={contacts} contactProps={getContactProps} />
    </StyledTagContact>
  );
};
