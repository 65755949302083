import styled, { css } from 'styled-components';

import { shouldForwardProp } from 'utils';

export const gridVariantContainerStyle = () => css`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
`;

export const gridVariantRowStyle = () => css`
  display: flex;
  flex-wrap: wrap;
`;

export const gridVariantColStyle = () => css``;

export const gridVariantStyles = {
  container: gridVariantContainerStyle,
  row: gridVariantRowStyle,
  col: gridVariantColStyle
};

export const StyledGrid = styled.div.withConfig({ shouldForwardProp })`
  ${({ variant }) => css`
    ${gridVariantStyles[variant]};
  `}
`;
