import React from 'react';
import * as Yup from 'yup';

import { StyledPasswordChangeForm } from './PasswordChangeForm.styles';

import { FormField, FormGroup } from 'components/_shared/Form';

const validationSchema = Yup.object().shape({
  oldPassword: Yup.string().label('Old password').required(),
  newPassword: Yup.string().label('New password').required()
});

export const PasswordChangeForm = ({ onCancel, ...props }) => {
  const initialValues = { oldPassword: '', newPassword: '', ...(props.initialValues || {}) };

  return (
    <StyledPasswordChangeForm
      {...props}
      initialValues={initialValues}
      validationSchema={validationSchema}
      defaultActions={{
        cancel: !!onCancel && { children: 'Cancel', onClick: onCancel },
        submit: { children: 'Change' }
      }}
    >
      <FormGroup>
        <FormField
          name='oldPassword'
          label='Old password'
          type='password'
          control={{ placeholder: 'Enter old password' }}
        />

        <FormField
          name='newPassword'
          label='New password'
          type='password'
          control={{ placeholder: 'Enter new password' }}
        />
      </FormGroup>
    </StyledPasswordChangeForm>
  );
};
