import React from 'react';

import { StyledWelcome, WelcomeMessage } from './Welcome.styles';

export const Welcome = ({ ...props }) => {
  return (
    <StyledWelcome {...props}>
      <WelcomeMessage>Please select a contact to start chatting.</WelcomeMessage>
    </StyledWelcome>
  );
};
