import { lighten } from 'polished';

import { cloneTheme } from 'styles/utils';
import { themeDefault } from './theme.default';

export const themeBrownDark = cloneTheme(themeDefault, {
  colors: {
    primary: {
      hue: '#37140C',
      tone: '#2F110A'
    },

    secondary: {
      hue: '#FFD247',
      tint: lighten(0.05, '#FFD247')
    },

    message: {
      card: {
        active: lighten(0.75, '#37140C')
      }
    },

    indicator: {
      count: {
        foreground: '#37140C',
        background: '#FFD247'
      }
    }
  }
});
