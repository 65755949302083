import React, { forwardRef } from 'react';
import cn from 'classnames';

import { ButtonBody, ButtonIcon, StyledButton } from './Button.styles';

export const Button = forwardRef(
  (
    {
      className,
      children,
      type = 'button',
      size = 'sx',
      icon,
      iconSize = 'sm',
      iconPosition = 'left',
      disabled,
      isDisabled: initialIsDisabled = false,
      isLoading = false,
      withLinkStyle = false,
      ...props
    },
    ref
  ) => {
    const hasChildren = !!children;
    const isDisabled = disabled || initialIsDisabled || isLoading;

    return (
      <StyledButton
        {...props}
        ref={ref}
        className={cn(className, { [`${props.variant}`]: props.variant })}
        type={type}
        size={size}
        icon={icon}
        iconSize={iconSize}
        iconPosition={iconPosition}
        disabled={isDisabled}
        isDisabled={isDisabled}
        isLoading={isLoading}
        hasChildren={hasChildren}
        withLinkStyle={withLinkStyle}
      >
        {icon && <ButtonIcon position={iconPosition}>{icon}</ButtonIcon>}
        {children && <ButtonBody>{children}</ButtonBody>}
      </StyledButton>
    );
  }
);
