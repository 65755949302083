import { Preloader } from 'components/_shared/Preloader';
import React, { useCallback, useEffect, useState } from 'react';

import { FileViewPreview, FileViewViewer, StyledFileView } from './FileView.styles';

export const FileView = ({
  children,
  file,
  isOpen: initialIsOpen = false,
  isLoading: initialIsLoading = false,
  onEmptyPreviewClick,
  onPreviewClick,
  onViewerOpen,
  onViewerClose,
  ...props
}) => {
  const hasFile = !!file;

  const [isLoading, setIsLoading] = useState(initialIsLoading);
  useEffect(() => setIsLoading(initialIsLoading), [initialIsLoading]);

  const [isViewerOpen, setIsViewerOpen] = useState(initialIsOpen);
  useEffect(() => setIsViewerOpen(initialIsOpen), [initialIsOpen]);

  const [shouldOpenViewer, setShouldOpenViewer] = useState(false);

  const handleViewerOpen = useCallback(() => {
    if (!hasFile) return;
    setIsViewerOpen(true);
    onViewerOpen?.();
  }, [hasFile, onViewerOpen]);

  const handleViewerClose = () => {
    setIsViewerOpen(false);
    onViewerClose?.();
  };
  const handlePreviewClick = event => {
    event.preventDefault();
    event.stopPropagation();

    if (!hasFile) {
      setShouldOpenViewer(true);
      return onEmptyPreviewClick?.();
    }

    handleViewerOpen();
    onPreviewClick?.();
  };

  useEffect(() => {
    hasFile && shouldOpenViewer && handleViewerOpen();
  }, [hasFile, shouldOpenViewer, handleViewerOpen]);

  return (
    <StyledFileView {...props}>
      <FileViewPreview file={file} onClick={handlePreviewClick} />
      <FileViewViewer file={file} isOpen={isViewerOpen} onClose={handleViewerClose} />
      {isLoading && <Preloader delay={100} isTransparent />}
      {children}
    </StyledFileView>
  );
};
