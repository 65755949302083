import React, { useCallback, useRef } from 'react';
import { AiOutlinePaperClip } from 'react-icons/ai';

import { InputFileButton, InputFileInput, StyledInputFile } from './InputFile.styles';

import { Button } from 'components/_shared/Button';

import { useControllableState } from 'hooks';
import { readFileAsync } from 'utils';

export const InputFile = ({
  icon = <AiOutlinePaperClip />,
  value: initialValue,
  max,
  button = Button,
  onChange,
  ...props
}) => {
  const inputRef = useRef(null);

  const [, setValue] = useControllableState({ defaultValue: [], initialValue, onChange });

  const handleButtonClick = () => inputRef.current.click();

  const handleInputChange = useCallback(
    async event => {
      const files = Array.from(event.target.files);
      const readedFiles = await Promise.all(files.map(file => readFileAsync(file)));
      setValue(prevValue => prevValue.concat(readedFiles).slice(-max));
    },
    [max, setValue]
  );

  return (
    <StyledInputFile>
      <InputFileButton {...props} icon={icon} onClick={handleButtonClick}>
        {button}
      </InputFileButton>

      <InputFileInput ref={inputRef} type='file' value='' multiple onChange={handleInputChange} />
    </StyledInputFile>
  );
};
