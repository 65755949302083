import React from 'react';

import { EditorActionEmoji } from './Emoji';
import { EditorActionFile } from './File';
import { EditorActionQuill } from './Quill';
import { EditorActionSubmit } from './Submit';

export const EDITOR_ACTION_KEYS = {
  'CODE-BLOCK': 'code-block',
  'INDENT-MINUS': 'indent-minus',
  'INDENT-PLUS': 'indent-plus',
  'LIST-BULLET': 'list-bullet',
  'LIST-ORDERED': 'list-ordered',
  BOLD: 'bold',
  CODE: 'code',
  EMOJI: 'emoji',
  FILE: 'file',
  ITALIC: 'italic',
  LINK: 'link',
  SUBMIT: 'submit'
};

export const EDITOR_ACTION_ELEMENTS = {
  [EDITOR_ACTION_KEYS['CODE-BLOCK']]: <EditorActionQuill className='ql-code-block' />,
  [EDITOR_ACTION_KEYS['LIST-ORDERED']]: <EditorActionQuill className='ql-list' value='ordered' />,
  [EDITOR_ACTION_KEYS['LIST-BULLET']]: <EditorActionQuill className='ql-list' value='bullet' />,
  [EDITOR_ACTION_KEYS['INDENT-PLUS']]: <EditorActionQuill className='ql-indent' value='+1' />,
  [EDITOR_ACTION_KEYS['INDENT-MINUS']]: <EditorActionQuill className='ql-indent' value='-1' />,
  [EDITOR_ACTION_KEYS.BOLD]: <EditorActionQuill className='ql-bold' />,
  [EDITOR_ACTION_KEYS.ITALIC]: <EditorActionQuill className='ql-italic' />,
  [EDITOR_ACTION_KEYS.LINK]: <EditorActionQuill className='ql-link' />,
  [EDITOR_ACTION_KEYS.CODE]: <EditorActionQuill className='ql-code' />,
  [EDITOR_ACTION_KEYS.FILE]: <EditorActionFile />,
  [EDITOR_ACTION_KEYS.EMOJI]: <EditorActionEmoji />,
  [EDITOR_ACTION_KEYS.SUBMIT]: <EditorActionSubmit />
};
