import styled, { css } from 'styled-components';

import { Button } from 'components/_shared/Button';

import { rem } from 'styles';

export const StyledEditorAction = styled(Button)`
  ${({ theme }) => css`
    width: ${rem(theme.fontSizes.sm + 4)};
    height: ${rem(theme.fontSizes.sm + 4)};
    padding: ${rem(2)};

    &:not(.tertiary) {
      color: ${theme.colors.dark.shade};

      &:hover {
        color: ${theme.colors.primary.hue};
      }
    }
  `}
`;
