import React, { createContext, useState, useContext, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash-es';

import { GROUP_KEYS, USER_KEYS } from '_constants';

import { selectGroupsWithCurrentUser, selectUsersWithoutCurrentUser } from 'state';
import { isMatchQuery } from 'utils';

const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
  const searchQuery = useRef('');
  const [searchResults, setSearchResults] = useState(null);

  const users = useSelector(selectUsersWithoutCurrentUser);
  const groups = useSelector(selectGroupsWithCurrentUser);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchResultsDebounced = useCallback(
    debounce(query => {
      const foundUsers = users.filter(user => isMatchQuery(query, user[USER_KEYS.USERNAME], 'i'));
      const foundGroups = groups.filter(group => isMatchQuery(query, group[GROUP_KEYS.NAME], 'i'));
      setSearchResults({ users: foundUsers, groups: foundGroups });
    }, 600),
    [users, groups]
  );

  const handleSearchQueryChange = useCallback(
    value => {
      if (!value) {
        searchQuery.current = value;
        return setSearchResults(null);
      }

      searchQuery.current = value;
      searchResultsDebounced(value);
    },
    [searchResultsDebounced]
  );

  const providerValue = {
    users,
    groups,
    searchResults,
    searchQuery: searchQuery.current,
    handleSearchQueryChange
  };

  return <SidebarContext.Provider value={providerValue}>{children}</SidebarContext.Provider>;
};

export const useSidebarContext = () => useContext(SidebarContext);
