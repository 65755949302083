import React from 'react';
import * as Yup from 'yup';

import { StyledUserFormEdit } from './UserFormEdit.styles';

import { USER_KEYS } from '_constants';

import { FormField, FormGroup } from 'components/_shared/Form';
import { Field } from 'components/_shared/Field';

import { formatUserProfileLabel } from 'utils';

const validationSchema = Yup.object().shape({
  [USER_KEYS.NAME]: Yup.string(),
  [USER_KEYS.LASTNAME]: Yup.string(),
  [USER_KEYS.EMAIL]: Yup.string().email(),
  [USER_KEYS.EMAIL_PERSONAL]: Yup.string().email(),
  [USER_KEYS.EMAIL_BUSINESS]: Yup.string().email(),
  [USER_KEYS.ORGANIZATION]: Yup.string()
});

export const UserFormEdit = ({ initialValues = {}, onCancel, ...props }) => {
  const values = {
    [USER_KEYS.AVATAR]: '',
    [USER_KEYS.NAME]: '',
    [USER_KEYS.LASTNAME]: '',
    [USER_KEYS.EMAIL]: '',
    [USER_KEYS.EMAIL_PERSONAL]: '',
    [USER_KEYS.ORGANIZATION]: '',
    ...initialValues
  };

  return (
    <StyledUserFormEdit
      {...props}
      isPreloaderTransparent
      initialValues={values}
      validationSchema={validationSchema}
      params={{ enableReinitialize: true }}
      defaultActions={{
        cancel: { onClick: () => onCancel?.() },
        submit: { children: 'Save' }
      }}
    >
      {form => (
        <FormGroup direction='row'>
          <FormGroup>
            <FormField
              type='text'
              label={USER_KEYS.NAME}
              name={USER_KEYS.NAME}
              control={{ placeholder: 'Enter full name' }}
            />

            <FormField
              type='text'
              label={USER_KEYS.LASTNAME}
              name={USER_KEYS.LASTNAME}
              control={{ placeholder: 'Enter lastname' }}
            />

            <FormField
              type='email'
              label={USER_KEYS.EMAIL}
              name={USER_KEYS.EMAIL}
              control={{ placeholder: 'Enter email' }}
            />

            <FormField
              type='email'
              label={formatUserProfileLabel(USER_KEYS.EMAIL_PERSONAL)}
              name={USER_KEYS.EMAIL_PERSONAL}
              control={{ placeholder: 'Enter personal email' }}
            />

            <FormField
              type='text'
              label={USER_KEYS.ORGANIZATION}
              name={USER_KEYS.ORGANIZATION}
              control={{ placeholder: 'Enter organization' }}
            />
          </FormGroup>
          <FormGroup>
            <Field
              type='image'
              label='Profile image'
              control={{
                value: { src: form.values[USER_KEYS.AVATAR] },
                withUploader: true,
                onChange: value => form.setFieldValue(USER_KEYS.AVATAR, value.src)
              }}
            />
          </FormGroup>
        </FormGroup>
      )}
    </StyledUserFormEdit>
  );
};
