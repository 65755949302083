import { isNumber } from 'lodash-es';
import React from 'react';

import { StyledIndicatorCount } from './IndicatorCount.styles';

export const IndicatorCount = ({ children, ...props }) => {
  if (!isNumber(children) || children === 0) return null;

  return <StyledIndicatorCount {...props}>{children}</StyledIndicatorCount>;
};
