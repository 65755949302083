export const NOT_FORWARD_PROPS = [
  'currentElement',
  'currentFontSize',
  'currentTag',
  'direction',
  'editorRef',
  'fontSize',
  'hasChildren',
  'hasError',
  'hasUpdates',
  'icon',
  'iconPosition',
  'iconSize',
  'initialFontSize',
  'isActive',
  'isDanger',
  'isDisabled',
  'isEdit',
  'isHeading',
  'isInvalid',
  'isLoading',
  'isSearchResultsOpen',
  'isTransparent',
  'onBackClick',
  'position',
  'positionOffset',
  'positionVariant',
  'relativeFontSize',
  'size',
  'staticContext',
  'titlePosition',
  'variant',
  'withIcon',
  'withLinkStyle',
  'containerOffsetRight',
  'fileType',
  'hasImage'
];
