import React from 'react';
import { flatten, isNull } from 'lodash-es';

import { StyledSidebarContactTabs } from './SidebarContactTabs.styles';

import { CONTACT_TYPES, USER_STATUSES } from '_constants';

import { useSidebarContext } from 'components/Sidebar/Sidebar.context';
import { SidebarSearchResults } from 'components/Sidebar/Search/Results';
import { SidebarContactList } from '../List';
import { ChatIndicatorUpdates } from 'components/Chat';

import { sortUsersByStatus } from 'utils';

export const SidebarContactTabs = ({ ...props }) => {
  const { users = [], groups = [], searchQuery, searchResults } = useSidebarContext();

  const isSearchResultsOpen = searchQuery && !isNull(searchResults);

  const tabs = Object.entries({ users, groups }).map(([label, contacts]) => {
    const contactType = CONTACT_TYPES[label === 'users' ? 'USER' : 'GROUP'];

    return {
      label: {
        name: label,
        children: <ChatIndicatorUpdates contactType={contactType} />
      },
      children: (
        <SidebarContactList
          key={label}
          contacts={sortUsersByStatus(contacts)}
          groupKeys={['waiting approve', 'requested', 'active', 'available']}
          condition={contact => {
            if (contact.status === USER_STATUSES.AVAILABLE) return 'available';
            if (contact.status === USER_STATUSES.WAITING) return 'waiting approve';
            if (contact.status === USER_STATUSES.REQUESTED) return 'requested';
            return 'active';
          }}
        />
      )
    };
  });

  return (
    <StyledSidebarContactTabs {...props} tabs={tabs} isSearchResultsOpen={isSearchResultsOpen}>
      {isSearchResultsOpen && (
        <SidebarSearchResults results={flatten(Object.values(searchResults))} />
      )}
    </StyledSidebarContactTabs>
  );
};
