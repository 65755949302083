import { createGlobalStyle, css } from 'styled-components';

import { quill } from './vendors';

export const GlobalStyle = createGlobalStyle`
  ${quill};

  * {
    box-sizing: border-box;
  }

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    ${({ theme: { fonts, fontSizes, fontWeights, lineHeight, colors } }) => css`
      font-family: ${fonts.primary}, sans-serif;
      font-size: ${`${fontSizes.sx}px`};
      font-weight: ${fontWeights.regular};
      line-height: ${lineHeight.x};
      color: ${colors.dark.hue};
      background: ${colors.light.hue};
    `}
  }

  code {
    font-family: monospace;
  }
`;
