import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isBoolean } from 'lodash-es';
import { nanoid } from 'nanoid';
import deepEqual from 'fast-deep-equal';

import { chatActions, selectChatsMeta } from './chat.slice';

import { API } from 'api';
import { WS } from 'socket';
import {
  CHAT_UPDATES_TEMPLATE,
  CONTACT_TYPES,
  MESSAGE_ACTION_TYPES,
  MESSAGE_STATUSES,
  SOCKET_EVENT_TYPES
} from '_constants';

import {
  createRequestMessage,
  createResponseMessage,
  getCookieAuth,
  handleResponseError,
  withMergeObjects
} from 'utils';
import { useRequest } from 'hooks';

export const useChatActions = () => {
  const dispatch = useDispatch();

  const auth = useMemo(() => getCookieAuth(), []);
  const chatsMeta = useSelector(selectChatsMeta, deepEqual);

  const addChat = useCallback((chat = {}) => dispatch(chatActions.addEntity(chat)), [dispatch]);

  const setActiveContactId = useCallback(
    contactId => dispatch(chatActions.setActiveContactId(contactId)),
    [dispatch]
  );

  const switchActiveChat = useCallback(
    (contactId, state) => {
      dispatch(chatActions.switchActiveChat({ contactId, ...state }));
    },
    [dispatch]
  );

  const setAction = useCallback(
    (contactId, action) => dispatch(chatActions.setAction({ contactId, action })),
    [dispatch]
  );

  const setEditor = useCallback(
    (contactId, editor) => dispatch(chatActions.setEditor({ contactId, editor })),
    [dispatch]
  );

  const setUpdates = useCallback(
    (contactId, updates) => {
      const [getUpdates, setUpdates] = withMergeObjects();

      if (isBoolean(updates)) {
        if (updates) {
          setUpdates({ hasUpdates: true });
        } else {
          setUpdates(CHAT_UPDATES_TEMPLATE, { hard: true });
        }
      }

      if (updates.hasOwnProperty('hasUpdates')) {
        setUpdates(updates);
      } else {
        const hasUpdates = Object.values(updates?.messages || {}).some(value => value > 0);
        setUpdates({ messages: updates.messages, hasUpdates });
      }

      dispatch(chatActions.setUpdates({ contactId, updates: getUpdates() }));
    },
    [dispatch]
  );

  const setEditorAction = useCallback(
    (contactId, { editor, action }) => {
      dispatch(chatActions.setEditorAction({ contactId, editor, action }));
    },
    [dispatch]
  );

  const resetEditorAction = contactId => {
    setEditorAction(contactId, {
      editor: {
        value: '',
        preview: {
          isOpen: false,
          onClick: undefined,
          onClose: undefined
        }
      },
      action: {
        type: MESSAGE_ACTION_TYPES.SEND,
        message: null
      }
    });
  };

  const removeHistory = useCallback(
    async (contactId, reason) => {
      try {
        API.removeHistory({ chatId: chatsMeta[contactId].contact.chatId, reason });
        dispatch(chatActions.removeHistory({ chatId: contactId, userId: contactId }));
      } catch (error) {
        handleResponseError(error);
      }
    },
    [chatsMeta, dispatch]
  );

  const sendMessage = useCallback(
    async (contactId, { files, ...message }) => {
      let sendingMessage = null;

      try {
        const isContactUser = chatsMeta[contactId].contact.type === CONTACT_TYPES.USER;

        const [requestMessage, requestMessageRaw] = createRequestMessage({
          chatId: chatsMeta[contactId].contact.chatId,
          contactId,
          contactType: chatsMeta[contactId].contact.type,
          ...message
        });

        requestMessage.userId = requestMessageRaw.actionAuthorId;
        sendingMessage = requestMessage;

        const response = await API.sendMessage(requestMessage);
        const responseMessage = createResponseMessage(response.data, requestMessage);

        if (files?.length) {
          const event = JSON.stringify({
            EventID: SOCKET_EVENT_TYPES.ATTACHMENT_SEND,
            UserID: auth.userId,
            SessionID: auth.sessionId,
            Body: JSON.stringify({
              AttachmentBody: files[0]?.base64,
              MessageID: responseMessage.MessageID,
              ReceiverID: isContactUser ? contactId : undefined,
              ChatID: chatsMeta[contactId].id,
              UserID: auth.userId,
              SessionID: auth.sessionId
            })
          });

          WS.send(event);
        }

        dispatch(chatActions.addMessage({ contactId, message: responseMessage }));
      } catch (error) {
        sendingMessage.id = nanoid();
        sendingMessage.status = MESSAGE_STATUSES.ERROR;
        dispatch(chatActions.addMessage({ contactId, message: sendingMessage }));
        handleResponseError(error);
      }
    },
    [auth, chatsMeta, dispatch]
  );

  const editMessage = useCallback(
    async (contactId, newMessage = {}, prevMessage = {}) => {
      try {
        const date = new Date().toJSON();

        const [requestEditMessage] = createRequestMessage({
          id: prevMessage.id,
          chatId: chatsMeta[contactId].contact.chatId,
          contactId,
          contactType: chatsMeta[contactId].contact.type,
          actionType: MESSAGE_ACTION_TYPES.EDIT,
          actionDate: date,
          text: prevMessage.text,
          ...prevMessage
        });

        await API.editMessage(requestEditMessage);

        dispatch(chatActions.editMessage({ contactId, message: requestEditMessage }));

        sendMessage(contactId, {
          actionDate: date,
          actionType: MESSAGE_ACTION_TYPES.EDIT,
          prevMessageId: prevMessage.id,
          prevMessageAuthorId: prevMessage.userId,
          ...newMessage
        });
      } catch (error) {
        handleResponseError(error);
      }
    },
    [dispatch, chatsMeta, sendMessage]
  );

  const deleteMessage = useCallback(
    messageId => {
      dispatch(chatActions.deleteMessage({ messageId }));
    },
    [dispatch]
  );

  const removeMessage = useCallback(
    async (contactId, message) => {
      try {
        await editMessage(
          contactId,
          { actionType: MESSAGE_ACTION_TYPES.DELETE },
          { id: message.id, text: message.text, actionType: MESSAGE_ACTION_TYPES.DELETE }
        );

        await API.removeMessage(message);

        dispatch(chatActions.removeMessage({ contactId }));
      } catch (error) {
        handleResponseError(error);
      }
    },
    [editMessage, dispatch]
  );

  const removeMessages = useCallback(
    async (contactId, messages) => {
      try {
        for (const message of messages) {
          await removeMessage(contactId, message);
        }
      } catch (error) {
        handleResponseError(error);
      }
    },
    [removeMessage]
  );

  const resendMessage = useCallback(
    async (contactId, message) => {
      try {
        const methods = {
          [MESSAGE_ACTION_TYPES.SEND]: () => sendMessage(contactId, message),
          [MESSAGE_ACTION_TYPES.REPLY]: () => {
            return sendMessage(contactId, {
              ...message,
              actionType: MESSAGE_ACTION_TYPES.REPLY,
              prevMessageId: message.action.prevMessageId,
              prevMessageAuthorId: message.userId
            });
          }
        };

        await methods[message.action.type]();
        dispatch(chatActions.deleteMessage({ messageId: message.id }));
      } catch (error) {
        handleResponseError(error);
      }
    },
    [dispatch, sendMessage]
  );

  const pinMessage = useCallback(
    (contactId, id) => dispatch(chatActions.pinMessage({ contactId, id })),
    [dispatch]
  );

  const unpinMessage = useCallback(
    (contactId, id) => dispatch(chatActions.unpinMessage({ contactId, id })),
    [dispatch]
  );

  const unpinMessages = useCallback(contactId => dispatch(chatActions.unpinMessages(contactId)), [
    dispatch
  ]);

  const togglePinnedMessages = useCallback(
    (contactId, isOpen) => dispatch(chatActions.togglePinnedMessages({ contactId, isOpen })),
    [dispatch]
  );

  const setSelectedMessages = useCallback(
    (contactId, messagesId) => {
      dispatch(chatActions.setSelectedMessages({ contactId, messagesId }));
    },
    [dispatch]
  );

  return {
    auth,
    addChat,
    setActiveContactId,
    switchActiveChat,
    setAction,
    setEditor,
    setUpdates,
    setEditorAction,
    resetEditorAction,
    removeHistory,
    sendMessage,
    resendMessage,
    editMessage,
    deleteMessage,
    removeMessage,
    removeMessages,
    pinMessage,
    unpinMessage,
    unpinMessages,
    togglePinnedMessages,
    setSelectedMessages
  };
};

export const useMessageFile = () => {
  const { isLoading, makeRequest } = useRequest();

  const getFile = useCallback(id => makeRequest(API.getAttachment(id)), [makeRequest]);

  return { isLoading, getFile };
};
