import React from 'react';
import { pick } from 'lodash-es';

import { StyledGroupMenu } from './GroupMenu.styles';

import { GROUP_KEYS, MODAL_KEYS, ROUTES } from '_constants';

import { useChatActions, useModal } from 'state';

import { Link } from 'components/_shared/Link';
import { Button } from 'components/_shared/Button';

const DEFAULT_MENU_ITEMS = ['profile', 'userAdd', 'userList', 'historyDeleteLocally'];

export const GroupMenu = ({ group = {}, items, ...props }) => {
  const { removeHistory } = useChatActions();
  const { openModal } = useModal();

  const groupId = group[GROUP_KEYS.ID];

  const handleHistoryRemove = () => {
    openModal(MODAL_KEYS.CONFIRM, {
      heading: `Delete history on your device only?`,
      actions: {
        submit: {
          children: 'Delete',
          onClick: async () => {
            await removeHistory(groupId);
          }
        }
      }
    });
  };

  const menuItems = {
    profile: <Link to={ROUTES.GROUP_BY_ID(groupId)}>Group profile</Link>,
    userAdd: (
      <Button
        onClick={() => {
          openModal(MODAL_KEYS.GROUP_USER_ADD, { groupId });
        }}
      >
        Add user
      </Button>
    ),
    userList: (
      <Button
        onClick={() => {
          openModal(MODAL_KEYS.GROUP_USER_SHOW, { groupId });
        }}
      >
        Members
      </Button>
    ),
    historyDeleteLocally: (
      <Button onClick={handleHistoryRemove}>Delete history on your device only</Button>
    )
  };

  return (
    <StyledGroupMenu
      {...props}
      items={Object.values(pick(menuItems, items || DEFAULT_MENU_ITEMS))}
    />
  );
};
