import React, { forwardRef, memo, useImperativeHandle, useRef, useState } from 'react';
import { isEmpty, omit } from 'lodash-es';
import cn from 'classnames';

import {
  MessageCardAuthorAvatar,
  MessageCardAuthorName,
  MessageCardBody,
  MessageCardHeader,
  MessageCardHeaderActionCancel,
  MessageCardHeaderActionResend,
  MessageCardHeaderActions,
  MessageCardMeta,
  MessageCardText,
  MessageCardTooltip,
  StyledMessageCard
} from './MessageCard.styles';

import { MESSAGE_STATUSES } from '_constants';

import { MessageDate } from './Date';
import { MessageReadMark } from './ReadMark';
import { MessageCardActions } from './Actions';
import { MessageCardFileView } from './FileView';

import { UseClickOutsideConditional } from 'hooks';
import { withTagsHighlight } from 'utils';

export const MessageCard = memo(
  forwardRef(
    (
      {
        className,
        user = {},
        date,
        action = {},
        text,
        prevMessage,
        fileId,
        file,
        actions = {},
        status,
        isModified: initialIsModified,
        isPrevMessage,
        onClick,
        onSelect,
        onUnSelect,
        onAuthorClick,
        onCancelClick,
        onResendClick,
        ...props
      },
      ref
    ) => {
      const rootRef = useRef(null);
      const prevMessageRef = useRef(null);

      const [isFocused, setIsFocused] = useState(false);
      const [isSelected, setIsSelected] = useState(false);
      const [isNavigated, setIsNavigated] = useState(false);
      const [isActionsOpen, setIsActionsOpen] = useState(false);

      const hasPrevMessage = prevMessage;
      const isModified = initialIsModified && !hasPrevMessage;
      const canRenderActions = !isPrevMessage && !hasPrevMessage && !isEmpty(actions);

      const select = () => {
        setIsSelected(true);
        onSelect?.(rootRef.current);
      };

      const unSelect = () => {
        setIsSelected(false);
        onUnSelect?.(rootRef.current);
      };

      const focus = () => {
        setIsFocused(true);
        setIsActionsOpen(true);
      };

      const blur = event => {
        setIsFocused(false);
        setIsNavigated(false);
        setIsActionsOpen(false);

        if (!event?.ctrlKey) unSelect();
      };

      const navigate = () => {
        rootRef.current?.scrollIntoView();
        setIsNavigated(true);
      };

      useImperativeHandle(ref, () => ({
        element: rootRef.current,
        focus,
        blur,
        select,
        unSelect,
        navigate
      }));

      const handleClick = event => {
        const skipEventConditions = [
          event.target.tagName === 'A',
          prevMessageRef.current?.element?.contains(event.target)
        ];

        if (event.ctrlKey) {
          return isSelected ? unSelect() : select();
        }

        if (skipEventConditions.some(Boolean)) return;

        focus();
        onClick?.(event);
      };

      const handleAuthorClick = () => onAuthorClick?.(user);

      const handleActionsClose = () => setIsActionsOpen(false);

      const handleActionClick = event => {
        event.preventDefault();
        event.stopPropagation();
        blur();
      };

      const handleHeaderActionClick = (event, method) => {
        event.preventDefault();
        event.stopPropagation();

        const methods = {
          cancel: () => onCancelClick?.(props.id),
          resend: () => onResendClick?.(props.id)
        };

        methods[method]();
      };

      const renderPrevMessage = () => {
        if (!hasPrevMessage) return null;
        return <MessageCard {...prevMessage} ref={prevMessageRef} isPrevMessage />;
      };

      const renderFiles = () => {
        if (!fileId && !file) return null;
        return <MessageCardFileView key={fileId} id={fileId} file={file} />;
      };

      return (
        <>
          <StyledMessageCard
            {...props}
            ref={rootRef}
            className={cn(className, {
              isModified,
              isPrevMessage,
              isFocused,
              isSelected,
              isNavigated
            })}
            isModified={isModified}
            isPrevMessage={isPrevMessage}
            isFocused={isFocused}
            isSelected={isSelected}
            isNavigated={isNavigated}
            onClick={handleClick}
          >
            <MessageCardHeader>
              <MessageCardAuthorAvatar
                src={user.avatar}
                alt={`${user.username}'s avatar`}
                onClick={handleAuthorClick}
              />

              <MessageCardMeta>
                {(user.username || user.name) && (
                  <MessageCardAuthorName onClick={handleAuthorClick}>
                    {user.name ? `${user.name} ${user.lastname}` : user.username}
                  </MessageCardAuthorName>
                )}

                <MessageDate
                  date={date}
                  action={hasPrevMessage ? omit(action, ['authorUserName']) : action}
                />

                {!isPrevMessage && status === MESSAGE_STATUSES.ERROR && (
                  <MessageCardHeaderActions>
                    <MessageCardHeaderActionCancel
                      onClick={event => handleHeaderActionClick(event, 'cancel')}
                    >
                      Cancel
                    </MessageCardHeaderActionCancel>

                    <MessageCardHeaderActionResend
                      onClick={event => handleHeaderActionClick(event, 'resend')}
                    >
                      Resend
                    </MessageCardHeaderActionResend>
                  </MessageCardHeaderActions>
                )}

                <MessageReadMark status={status} />
              </MessageCardMeta>
            </MessageCardHeader>

            <MessageCardBody>
              {renderPrevMessage()}

              {text && (
                <MessageCardText dangerouslySetInnerHTML={{ __html: withTagsHighlight(text) }} />
              )}

              {renderFiles()}
            </MessageCardBody>

            {canRenderActions && (
              <MessageCardActions
                {...actions}
                isOpen={isActionsOpen}
                clickOutsideParams={{ ignoredRefs: [rootRef] }}
                onClose={handleActionsClose}
                onChildClick={handleActionClick}
              />
            )}

            {!isPrevMessage && (
              <MessageCardTooltip>
                {isSelected
                  ? 'Hold Ctrl or Command to select more messages'
                  : 'Hold Ctrl or Command key to select a message'}
              </MessageCardTooltip>
            )}
          </StyledMessageCard>

          {(isFocused || isSelected || isNavigated) && (
            <UseClickOutsideConditional elementRef={rootRef} callback={blur} />
          )}
        </>
      );
    }
  )
);
