import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  forwardRef,
  useImperativeHandle,
  memo
} from 'react';
import { debounce, isUndefined } from 'lodash-es';

import {
  StyledContactList,
  ContactListHeader,
  ContactListBody,
  ContactListItem,
  ContactListSearch,
  ContactListLabel
} from './ContactList.styles';

import { CONTACT_KEYS, USER_KEYS } from '_constants';

import { Checkbox } from 'components/_shared/Checkbox';
import { Button } from 'components/_shared/Button';

import { mergeObjects, mergeProps, withFunction } from 'utils';

export const ContactList = memo(
  forwardRef(
    (
      {
        contacts: initialContacts,
        contactProps,
        label,
        actions,
        onContactSelect,
        onContactRemove,
        withContactSearch = false,
        ...props
      },
      ref
    ) => {
      const getSelectedContacts = useCallback(contacts => {
        return contacts.reduce((contacts, contact) => {
          const newContact = { ...contact, isSelected: contact.isSelected || false };
          return { ...contacts, [contact[CONTACT_KEYS.ID]]: newContact };
        }, {});
      }, []);

      const rootRef = useRef();
      const [contacts, setContacts] = useState(() => initialContacts || []);
      const [searchQuery, setSearchQuery] = useState('');
      const [selectedContacts, setSelectedContacts] = useState(() => {
        return getSelectedContacts(initialContacts);
      });

      const canRenderHeader = withContactSearch;
      const enforcedActions = mergeObjects({ contactSelect: false, contactRemvoe: false }, actions);

      useImperativeHandle(
        ref,
        () => ({
          element: rootRef.current,
          searchQuery,
          selectedContacts: Object.values(selectedContacts).filter(({ isSelected }) => isSelected)
        }),
        [searchQuery, selectedContacts]
      );

      useEffect(() => {
        if (isUndefined(initialContacts)) return;

        setContacts(initialContacts);
        setSelectedContacts(getSelectedContacts(initialContacts));
      }, [initialContacts, getSelectedContacts]);

      // eslint-disable-next-line react-hooks/exhaustive-deps
      const searchContactsDebounced = useCallback(
        debounce(query => {
          if (!initialContacts?.length) return;

          const isMatch = text =>
            query.split(' ').every(word => text.match(new RegExp(word, 'gi')));
          const foundContacts = initialContacts.filter(contact => {
            return isMatch(contact[USER_KEYS.USERNAME]);
          });
          setContacts(foundContacts.length ? foundContacts : initialContacts);
        }, 600),
        [initialContacts]
      );

      const handleSearchQueryChange = useCallback(
        value => {
          setSearchQuery(value);
          searchContactsDebounced(value);
        },
        [searchContactsDebounced]
      );

      const handleContactSelect = useCallback(
        contact => {
          setSelectedContacts(contacts => {
            const updatedContact = {
              ...contacts[contact[CONTACT_KEYS.ID]],
              isSelected: !contacts[contact[CONTACT_KEYS.ID]].isSelected
            };

            onContactSelect?.(updatedContact);

            return { ...contacts, [contact[CONTACT_KEYS.ID]]: updatedContact };
          });
        },
        [onContactSelect]
      );

      const renderContacts = useCallback(() => {
        return contacts.map(contact => {
          const props = withFunction(contactProps, contact) || {};
          const mergedProps = mergeProps(contact, [
            {
              actions: [
                (onContactSelect || enforcedActions.contactSelect) && (
                  <Checkbox
                    key='checkbox'
                    value={selectedContacts[contact[CONTACT_KEYS.ID]].isSelected}
                    onChange={() => handleContactSelect(contact)}
                    onClick={event => event.stopPropagation()}
                  />
                ),
                (onContactRemove || enforcedActions.contactRemove) && (
                  <Button
                    type='button'
                    variant='secondary'
                    size='x'
                    onClick={event => {
                      event.stopPropagation();
                      onContactRemove(contact);
                    }}
                  >
                    Remove
                  </Button>
                )
              ],
              onClick:
                onContactSelect || enforcedActions.contactSelect
                  ? () => handleContactSelect(contact)
                  : undefined
            },
            props
          ]);

          return <ContactListItem {...mergedProps} key={contact[CONTACT_KEYS.ID]} />;
        });
      }, [
        enforcedActions,
        contacts,
        selectedContacts,
        contactProps,
        onContactSelect,
        onContactRemove,
        handleContactSelect
      ]);

      return (
        <StyledContactList {...props} ref={rootRef}>
          {canRenderHeader && (
            <ContactListHeader>
              {withContactSearch && (
                <ContactListSearch value={searchQuery} onChange={handleSearchQueryChange} />
              )}
            </ContactListHeader>
          )}

          <ContactListBody>
            {label && <ContactListLabel>{label}</ContactListLabel>}
            {renderContacts()}
          </ContactListBody>
        </StyledContactList>
      );
    }
  )
);
