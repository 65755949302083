import { useEffect, useState } from 'react';
import { isBoolean } from 'lodash-es';

export const useToggle = (initialIsOpen = false) => {
  const [isOpen, setIsOpen] = useState(initialIsOpen);

  useEffect(() => isBoolean(initialIsOpen) && setIsOpen(initialIsOpen), [initialIsOpen]);

  return [isOpen, setIsOpen];
};
