import React from 'react';

import {
  ChatMessagePinnedSection,
  ChatMessagePinnedSectionAction,
  ChatMessagePinnedSectionActions,
  ChatMessagePinnedSectionBody,
  ChatMessagePinnedSectionHeader,
  ChatMessagePinnedSectionLabel,
  StyledChatMessagePinned
} from './ChatMessagePinned.styles';

import { ChatMessageList } from '../List';

import { useChatActions } from 'state';

export const ChatMessagePinned = ({ contactId, messages = [], ...props }) => {
  const { unpinMessages } = useChatActions();

  return (
    <StyledChatMessagePinned {...props}>
      <ChatMessagePinnedSection>
        <ChatMessagePinnedSectionHeader>
          <ChatMessagePinnedSectionLabel>Pinned</ChatMessagePinnedSectionLabel>

          <ChatMessagePinnedSectionActions>
            <ChatMessagePinnedSectionAction
              type='button'
              size='x'
              onClick={() => unpinMessages(contactId)}
            >
              Unpin all
            </ChatMessagePinnedSectionAction>
          </ChatMessagePinnedSectionActions>
        </ChatMessagePinnedSectionHeader>

        <ChatMessagePinnedSectionBody>
          <ChatMessageList contactId={contactId} messages={messages} />
        </ChatMessagePinnedSectionBody>
      </ChatMessagePinnedSection>
    </StyledChatMessagePinned>
  );
};
