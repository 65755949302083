import styled from 'styled-components';

import { Button } from 'components/_shared/Button';

export const ChatViewActionsButton = styled(Button)``;

export const StyledChatViewActions = styled.div`
  display: flex;
  align-items: center;
`;
