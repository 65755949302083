import { cloneElement, forwardRef } from 'react';
import styled, { css } from 'styled-components';
import cn from 'classnames';

import { Typography } from '../Typography';

import { shouldForwardProp } from 'utils';
import { em, upperFirst, spacing } from 'styles';

export const FieldMessage = styled(Typography).attrs({ element: 'p', size: 'x' })`
  ${({ theme, size }) => css`
    font-weight: ${theme.fontWeights.medium};
    ${upperFirst};
    ${spacing({ side: 'top', spacing: 'x', fontSize: theme.fontSizes[size] })};
  `}
`;

export const FieldMessageError = styled(FieldMessage)`
  ${({ theme }) => css`
    color: ${theme.colors.danger.hue};
  `}
`;

export const FieldMessages = styled.div`
  ${({ theme }) => css`
    margin-top: ${em(theme.spacing.x)};
  `}
`;

export const FieldFooter = styled.footer`
  ${({ theme }) => css`
    margin-top: ${em(theme.spacing.x)};
  `}
`;

export const FormFieldControl = styled(
  forwardRef(({ children: child, ...props }, ref) => {
    const className = cn(child.props.className, props.className);
    return cloneElement(child, { ...props, ref, className });
  })
)``;

export const FieldBody = styled.div``;

export const FieldLabel = styled.label`
  ${({ theme }) => css`
    font-size: ${em(theme.fontSizes.x)};
    font-weight: ${theme.fontWeights.medium};
  `}
`;

export const FieldHeader = styled.header`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin-bottom: ${em(theme.spacing.x)};
  `}
`;

export const StyledField = styled.div.withConfig({ shouldForwardProp })`
  position: relative;
`;
